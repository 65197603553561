import React, { useEffect, useLayoutEffect } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";

import navlogo from './media/main_logo.png'
import './App.css';

/* ICONS */
import phone_orange from './media/icons/icon_phone_orange.png'
import mail_orange from './media/icons/icon_mail_orange.png'
import download_orange from './media/icons/icon_download_orange.png'
import tick_orange from './media/icons/icon_tick_orange.png'

import linkedin_black from './media/icons/icon_linkedin_black.png'
import instagram_black from './media/icons/icon_instagram_black.png'
import facebook_black from './media/icons/icon_facebook_black.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes, faSignOutAlt, faUserCog, faFileAlt, faEdit, faBan, faUndo } from '@fortawesome/free-solid-svg-icons'

/* FONT */
import './fonts/ArgentumSans-Light.ttf'
import './fonts/ArgentumSans-LightItalic.ttf'
import './fonts/ArgentumSans-SemiBold.ttf'
import './fonts/ArgentumSans-SemiBoldItalic.ttf'

/* FORMS */
import {Formik, Form, Field} from "formik";
import {TextField, CheckboxWithLabel} from "formik-material-ui";
import * as Yup from "yup";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";

/* STYLING */
import { makeStyles } from "@material-ui/core/styles";

/* LOGOS */
import logo_durschei from './media/logos/durschei.png'
import logo_white_trans from './media/logos/white_trans.png'
import logo_svit from './media/logos/SVIT_black.png'

/* IMAGES */
import default_big from './media/fachgebiete/erstvermietung_cropped.jpg'

import homewerteimg from './media/home/ueber_uns_puzzle.png'

import bewirtschaftung_1000 from './media/fachgebiete/bewirtschaftung_1000.jpg'
import verkauf_1000 from './media/fachgebiete/verkauf_1000.jpg'
import erstvermietung_1000 from './media/fachgebiete/erstvermietung_1000.jpg'
import treuhand_1000 from './media/fachgebiete/treuhand_1000.jpg'
import bewirtschaftung from './media/fachgebiete/bewirtschaftung_cropped.jpg'
import erstvermietung from './media/fachgebiete/erstvermietung_cropped.jpg'
import verkauf from './media/fachgebiete/verkauf_cropped.jpg'
import treuhand from './media/fachgebiete/treuhand_cropped.jpg'

import ueber_uns_1000 from './media/unternehmen/ueber_uns_1000.jpg'
import unsere_werte_1000 from './media/unternehmen/unsere_werte_1000.jpg'
import geschichte_1000 from './media/unternehmen/geschichte_1000.jpg'
import referenzen_1000 from './media/unternehmen/referenzen_1000.jpg'
import partner_1000 from './media/unternehmen/partner_1000.jpg'
import ueber_uns from './media/unternehmen/ueber_uns_cropped.jpg'
import unsere_werte from './media/unternehmen/unsere_werte_cropped.jpg'
import puzzle_loyal from './media/unternehmen/puzzle_loyal.png'
import puzzle_organisiert from './media/unternehmen/puzzle_organisiert.png'
import puzzle_ueberzeugt from './media/unternehmen/puzzle_ueberzeugt.png'
import puzzle_quality from './media/unternehmen/puzzle_quality.png'
import puzzle_transparent from './media/unternehmen/puzzle_transparent.png'
import puzzle_bewegung from './media/unternehmen/puzzle_bewegung.png'
import geschichte from './media/unternehmen/geschichte_cropped.jpg'
import referenzen from './media/unternehmen/referenzen_cropped.jpg'
import partner from './media/unternehmen/partner_cropped.jpg'

import vermieten_1000 from './media/marktplatz/vermieten_1000.jpg'
import marktplatz_verkauf_1000 from './media/marktplatz/verkauf_1000.jpg'
import suchauftrag_1000 from './media/marktplatz/suchauftrag_1000.jpg'
import vermieten from './media/marktplatz/vermieten_cropped.jpg'
import marktplatz_verkauf from './media/marktplatz/verkauf_cropped.jpg'
import suchauftrag from './media/marktplatz/suchauftrag_cropped.jpg'

import portal_1000 from './media/schalter/portal_1000.jpg'
import datenraum_1000 from './media/schalter/datenraum_1000.jpg'
import downloads_1000 from './media/schalter/downloads_1000.jpg'
import wissen_1000 from './media/schalter/wissen_1000.jpg'
import portal from './media/schalter/portal_cropped.jpg'
import datenraum from './media/schalter/datenraum_cropped.jpg'
import downloads from './media/schalter/downloads_cropped.jpg'
import wissen from './media/schalter/wissen_cropped.jpg'

import honorarrechner from './media/home/slider_honorarrechner.jpg'

const PROJECTOBJECTS = {
  "example" : {
    "menu" : "Objektname, Typ",
    "link": "/project-examplepage",
    "name" : "Objektname, Ort.",
    "summary" : "Kurzbeschrieb.",
    "text" : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    "img_big" : [
      verkauf, erstvermietung, bewirtschaftung, ueber_uns,
    ],
    "img_small" : [
      verkauf_1000, erstvermietung_1000, bewirtschaftung_1000, ueber_uns_1000,
    ],
    "marktplatz_img" : erstvermietung_1000,
    "table" : [
      [],
      []
    ],
    "downloads": [
      {
        "name": "Verkaufsdokumentation",
        "location": "",
      },
      {
        "name": "Planunterlagen",
        "location": "",
      },
    ],
    "verkauf" : true,
  },
}

/* STYLING */

const main_theme = createTheme({
  typography: {
    fontFamily: 'ArgentumSans-Light'
  },
  palette: {
    primary: {
      main: "#e59300"
    }
  }, 
})


export class MainPage extends React.Component {
    render() {    
      return (
        <ThemeProvider theme={main_theme}>
          <App />
        </ThemeProvider>
      )
    }
  }

async function getActivePages(){
  let data = await fetch("/api/v1/utils/getSettings/1")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"][0]["content"]})
    .catch((err) => {console.log(err); return {"partner":0,"history":0,"reference":0,"wissen":0}})
  return data;
}

async function getProjectInfo(){
  const url = "/api/v1/projects/getInfo";
  let data = await fetch(url)
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {
      let active = []
      for(let project of data["data"]){
        if(project["active"]){
          active.push(project);
        }
      }
      return active;
    })
    .catch(error => {console.log(error); return []});
  return data;
}

function App(){
  const [width, setWidth] = React.useState(window.innerWidth)
  const [projectInfo, setProjectInfo] = React.useState([])
  const [activePages, setActivePages] = React.useState({})
  function handleWindowSizeChange(){
    setWidth(window.innerWidth)
  }
  useEffect(()=>{
    async function fetchData(){
      const result = await getProjectInfo();
      setProjectInfo(result);
      const active = await getActivePages();
      setActivePages(active);
    }
    window.addEventListener('resize', handleWindowSizeChange);
    fetchData();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  let projects = []
  for(let elem of projectInfo) {
    projects.push(
      <Route path={elem["link"]}>
        <ProjectPage object={elem}/>
      </Route>
    )
    if(window.location.href.includes(elem["url"])){
      window.location.href = "https://deflorin-ag.ch" + elem["link"];
    }
  }
  return(
        <div className="App">
            {!window.location.pathname.startsWith("/admin") &&
              <header>
              <Navigation location="header" width={width} projects={projectInfo} activePages={activePages}/>
              </header>
            }
        <main>
        <Router>
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>

            <Route path="/fachgebiete">
                <Fachgebiete />
            </Route>
            <Route path="/bewirtschaftung">
                <Bewirtschaftung />
            </Route>
            <Route path="/erstvermietung">
                <Erstvermietung />
            </Route>
            <Route path="/verkauf">
                <Verkauf />
            </Route>
            <Route path="/treuhand">
                <Treuhand />
            </Route>

            <Route path="/unternehmen">
                <Unternehmen activePages={activePages}/>
            </Route>
            <Route path="/ueber-uns">
                <UeberUns />
            </Route>
            <Route path="/unsere-werte">
                <UnsereWerte />
            </Route>
            {(activePages["partner"]  === 1) &&
              <Route path="/partner">
                <Partner />
              </Route>
            }
            {(activePages["history"] === 1) &&
            <Route path="/geschichte">
                <Geschichte />
            </Route>
            }
            {(activePages["reference"] === 1) &&
            <Route path="/referenzen">
                <Referenzen />
            </Route>
            }

            <Route path="/marktplatz">
                <Marktplatz projects={projectInfo}/>
            </Route>
            <Route path="/wir-vermieten">
                <WirVermieten />
            </Route>
            <Route path="/wir-verkaufen">
                <WirVerkaufen />
            </Route>
            <Route path="/suchauftrag">
                <Suchauftrag />
            </Route>
            {projects}
            <Route path="/project-examplepage">
              <ExampleProjectPage object={PROJECTOBJECTS["example"]}/>
            </Route>

            <Route path="/schalter">
                <Schalter activePages={activePages}/>
            </Route>
            <Route path="/eigentuemerportal">
                <Eigentuemerportal />
            </Route>
            <Route path="/user-eigentuemerportal">
              <EigentuemerLoggedIn/>
            </Route>
            <Route path="/user-change-password">
              <ChangePasswordLoggedIn/>
            </Route>
            <Route path="/datenraum">
                <Datenraum />
            </Route>
            <Route path="/user-datenraum">
                <DatenraumLoggedIn />
            </Route>
            <Route path="/downloads">
                <DownloadPage />
            </Route>
            {(activePages["wissen"] === 1) &&
              <Route path="/wissen">
                  <Wissen />
              </Route>
            }


            <Route path="/kontakt">
                <Kontakt />
            </Route>
            <Route path="/honorarrechner">
                <Honorarrechner />
            </Route>

            <Route path="/impressum">
                <Impressum />
            </Route>
            <Route path="/datenschutz">
                <Datenschutz />
            </Route>

            <Route exact path="/admin/:method?/:type?/:id?">
                <Admin />
            </Route>

            <Route path="/unauthorized">
              <Unauthorized/>
            </Route>  
            <Route path="*">
                <NotFound />
            </Route>

        </Switch>
        </Router>
        </main>
        {!window.location.pathname.startsWith("/admin") &&
          <Footer />
        }
        </div>);
}

function SmallNavContent(params) {
  const [menuitems, setMenuitems] = React.useState([false, false, false, false, false, false])
  const [mainstyle, setMainstyle] = React.useState([{},{},{},{},{},{}])
  function changeMenuItem(index, link) {
    if(menuitems[index]){
      changePage(link);
    } else {
      let this_state = [false, false, false, false, false, false]
      this_state[index] = true
      setMenuitems(this_state)
      let this_style = [{},{},{},{},{},{}]
      this_style[index] = {
        color: colors["white"],
        fontWeight: "bold"
      }
      setMainstyle(this_style);
    }
  }
  let project_pages = []
  for(let elem of params.projects){
    project_pages.push(
      <div className="nav-small-sub" onClick={() => changePage(elem["link"])}><p>{elem["titlemenu"]}</p></div>
    )
  }
  return(
    <div className="nav-small">
      <div className="nav-small-main" style={mainstyle[0]} onClick={() => changeMenuItem(0, "/fachgebiete")}><p>Unsere Fachgebiete</p></div>
      {menuitems[0] && 
      <>
        <div className="nav-small-sub" onClick={() => changePage("/bewirtschaftung")}><p>Bewirtschaftung</p></div>
        <div className="nav-small-sub" onClick={() => changePage("/erstvermietung")}><p>Erstvermietung</p></div>
        <div className="nav-small-sub" onClick={() => changePage("/verkauf")}><p>Verkauf</p></div>
        <div className="nav-small-sub" onClick={() => changePage("/treuhand")}><p>Treuhand</p></div>      
      </>  
      }
      <div className="nav-small-main" style={mainstyle[1]} onClick={() => changeMenuItem(1, "/unternehmen")}><p>Das Unternehmen</p></div>
      {menuitems[1] &&
      <>
        <div className="nav-small-sub" onClick={() => changePage("/ueber-uns")}><p>Über Uns</p></div>
        <div className="nav-small-sub" onClick={() => changePage("/unsere-werte")}><p>Unsere Werte</p></div>
      </>
      }
      {menuitems[1] && (params.activePages["partner"] === 1) &&
        <div className="nav-small-sub" onClick={() => changePage("/partner")}><p>Die Partner</p></div>
      }
      {menuitems[1] && (params.activePages["history"] === 1) &&
        <div className="nav-small-sub" onClick={() => changePage("/geschichte")}><p>Geschichte</p></div>
      }
      {menuitems[1] && (params.activePages["reference"] === 1) &&
        <div className="nav-small-sub" onClick={() => changePage("/referenzen")}><p>Referenzen</p></div>
      }    
      <div className="nav-small-main" style={mainstyle[2]} onClick={() => changeMenuItem(2, "/marktplatz")}><p>Marktplatz</p></div>
      {menuitems[2] &&
      <>
        <div className="nav-small-sub" onClick={() => changePage("/wir-vermieten")}><p>Wir vermieten</p></div>
        <div className="nav-small-sub" onClick={() => changePage("/wir-verkaufen")}><p>Wir verkaufen</p></div>
        <div className="nav-small-sub" onClick={() => changePage("/suchauftrag")}><p>Suchauftrag</p></div>
        {project_pages}
      </>
      }
      <div className="nav-small-main" style={mainstyle[3]} onClick={() => changeMenuItem(3, "/schalter")}><p>Online-Schalter</p></div>
      {menuitems[3] &&
      <>
        <div className="nav-small-sub" onClick={() => changePage("/eigentuemerportal")}><p>Eigentümerportal</p></div>
        <div className="nav-small-sub" onClick={() => changePage("/datenraum")}><p>Datenraum</p></div>
        <div className="nav-small-sub" onClick={() => changePage("/downloads")}><p>Downloads</p></div>
      </>
      }
      {menuitems[3] && (params.activePages["wissen"] === 1) &&
        <div className="nav-small-sub" onClick={() => changePage("/wissen")}><p>Wissen</p></div>
      }  
      <div className="nav-small-main" style={mainstyle[4]} onClick={() => changePage("/honorarrechner")}><p>Honorarrechner</p></div>
      <div className="nav-small-main" style={mainstyle[5]} onClick={() => changePage("/kontakt")}><p>Kontakt</p></div> 
    </div>
  )
}

function SmallUserContent(params) {
  return(
    <div className="nav-small">
      {window.location.pathname === "/user-eigentuemerportal" &&
        <div className="nav-small-main" onClick={() => changePage("/user-change-password")}>
          <FontAwesomeText text="Passwort ändern" icon={faUserCog} left={true} small_menu={true}/>
        </div>
      }
      {window.location.pathname === "/user-change-password" &&
        <div className="nav-small-main" onClick={() => changePage("/user-eigentuemerportal")}>
          <FontAwesomeText text="Dokumente" icon={faFileAlt} left={true} small_menu={true}/>
        </div>
      }
      <div className="nav-small-main" onClick={() => Logout()}>
        <FontAwesomeText text="Logout" icon={faSignOutAlt} left={true} small_menu={true}/>
      </div>
    </div>
  )
}

function Navigation(params) {
  const [styles, setStyles] = React.useState([])
  const [backgrounds, setBackgrounds] = React.useState([])
  const [active, setActive] = React.useState([])
  const [showmenu, setShowmenu] = React.useState(false)
  useEffect(()=>{
    setActive([false,false,false,false,false,false,false])
    setStyles([
      {
        opacity: 0
      },
      {
        opacity: 0
      },
      {
        opacity: 0
      },
      {
        opacity: 0
      },
      {
        opacity: 0
      },
      {
        opacity: 0
      },
      {
        opacity: 0
      },
    ])
    setBackgrounds([
      {},
      {},
      {},
      {},
      {},
      {},
      {},
    ])
  }, [])

  function changeSmallMenu(val) {
    setShowmenu(!showmenu)
  }

  if(window.location.pathname === "/user-eigentuemerportal" || window.location.pathname === "/user-datenraum" || window.location.pathname === "/user-change-password"){
    if(params.width < 1160 && window.location.pathname !== "/user-datenraum"){
      return(
        <>
        <div className="nav-user-box">
          <div className="Logo-Header Navlogo" onClick={() => changePage("/")}>
            <img
                src={navlogo}
                width="190"
                height="40"
                className="d-inline-block align-top"
                alt="DEFLORIN AG"
            />
          </div>
          <div className="Logo-Header" id="nav-icon">
          <div className="nav-small-icon" onClick={() => changeSmallMenu()}>
          {!showmenu &&
            <FontAwesomeIcon icon={faBars} />
          }
          {showmenu &&
            <FontAwesomeIcon icon={faTimes} />
          }
          </div>
        </div>
      </div>
      {showmenu && 
        <div className="nav-small">
          <SmallUserContent/>
        </div>
      }
        </>
      )
    } else {
      return(
        <>
        <div className="nav-user-box">
          <div className="Logo-Header Navlogo" onClick={() => changePage("/")}>
            <img
                src={navlogo}
                width="190"
                height="40"
                className="d-inline-block align-top"
                alt="DEFLORIN AG"
            />
          </div>
          <div className="nav-user-right-box">
            {window.location.pathname === "/user-eigentuemerportal" &&
              <div className="nav-user-elem">
                <div onClick={() => changePage("/user-change-password")}>
                  <FontAwesomeText text="Passwort ändern" icon={faUserCog} left={true} menu={true}/>
                </div>
              </div>
            }
            {window.location.pathname === "/user-change-password" &&
              <div className="nav-user-elem">
                <div onClick={() => changePage("/user-eigentuemerportal")}>
                  <FontAwesomeText text="Dokumente" icon={faFileAlt} left={true} menu={true}/>
                </div>
              </div>
            }
            <div className="nav-user-elem">
              <div onClick={() => Logout()}>
                <FontAwesomeText text="Logout" icon={faSignOutAlt} left={true} menu={true}/>
              </div>
            </div>
          </div>
        </div>
        </>
      )
    }
  }

  if(params.width < 1160){
    return(
      <>
      <div className="nav-small-box">
        <div className="Logo-Header Navlogo" onClick={() => changePage("/")} id="nav-logo">
          <img
              src={navlogo}
              width="190"
              height="40"
              className="d-inline-block align-top"
              alt="DEFLORIN AG"
          />
        </div>
        <div className="Logo-Header" id="nav-icon">
          <div className="nav-small-icon" onClick={() => changeSmallMenu()}>
          {!showmenu &&
            <FontAwesomeIcon icon={faBars} />
          }
          {showmenu &&
            <FontAwesomeIcon icon={faTimes} />
          }
          </div>
        </div>
      </div>
      {showmenu && 
        <div className="nav-small">
          <SmallNavContent projects={params.projects} activePages={params.activePages}/>
        </div>
      }
      </>
    )
  }
    let divClass = "Navbar";
    let aClass = "Navlink";
    let logoClass = "Navlogo"
    if(params.location === "header"){
        divClass = "Nav-Header Navbar"
        aClass = "a-Header Navlink"
        logoClass = "Logo-Header Navlogo"
    }
    function checkchange(link, idx){
      if(active[idx]){
        changePage(link)
      }
    }
    function hover(index){
      const new_styles = [...styles]
      for(let i = 0; i < new_styles.length; i++){
        if(i === index){
          new_styles[i] = {
            opacity: 0.95,
            cursor: "pointer"
          }
        } else {
          new_styles[i] = {
            opacity: 0
          }
        }
      }
      setStyles(new_styles)
      const new_backgrounds = [...backgrounds]
      for(let i = 0; i < new_backgrounds.length; i++){
        if(i === index){
          new_backgrounds[i] = {
            backgroundColor: "#e59300"
          }
        } else {
          new_backgrounds[i] = {}
        }
      }
      setBackgrounds(new_backgrounds)
      const new_active = [...active]
      for(let i = 0; i < new_active.length; i++){
        if(i === index){
          new_active[i] = {
            backgroundColor: "#e59300"
          }
        } else {
          new_active[i] = {}
        }
      }
      setActive(new_active)
    }
    function left(index){
      const new_styles = [...styles]
      new_styles[index] = {
        opacity: 0
      }
      setStyles(new_styles)
      const new_backgrounds = [...backgrounds]
      new_backgrounds[index] = {}
      setBackgrounds(new_backgrounds)
      setActive([false,false,false,false,false,false,false])
    }
    let project_pages = []
    for(let elem of params.projects){
      project_pages.push(
        <div style={styles[3]} className="navSub" onClick={() => changePage(elem["link"])}><p>{elem["titlemenu"]}</p></div>
      )
    }

    return(
      <>
        <div className={divClass}>
            <div className={logoClass} onClick={() => changePage("/")} id="nav-logo">
                <img
                    src={navlogo}
                    width="190"
                    height="40"
                    className="d-inline-block align-top"
                    alt="DEFLORIN AG"
                />
            </div>
            <div className={aClass} id="nav-fach" onMouseLeave={() => left(1)}>
                <div style={backgrounds[1]} className="navMain" onMouseEnter={() => hover(1)} onClick={() => changePage("/fachgebiete")}><p>Unsere Fachgebiete</p></div>
                {active[1] && 
                <>
                  <div style={styles[1]} className="navSub" onClick={() => checkchange("/bewirtschaftung", 1)}><p>Bewirtschaftung</p></div>
                  <div style={styles[1]} className="navSub" onClick={() => checkchange("/erstvermietung", 1)}><p>Erstvermietung</p></div>
                  <div style={styles[1]} className="navSub" onClick={() => checkchange("/verkauf", 1)}><p>Verkauf</p></div>
                  <div style={styles[1]} className="navSub" onClick={() => checkchange("/treuhand", 1)}><p>Treuhand</p></div>
                </>
                }
            </div>
            <div className={aClass} id="nav-unt" onMouseLeave={() => left(2)}>
                <div style={backgrounds[2]} onMouseEnter={() => hover(2)} className="navMain" onClick={() => changePage("/unternehmen")}><p>Das Unternehmen</p></div>
                {active[2] && 
                <>
                  <div style={styles[2]} className="navSub" onClick={() => checkchange("/ueber-uns", 2)}><p>Über Uns</p></div>
                  <div style={styles[2]} className="navSub" onClick={() => checkchange("/unsere-werte", 2)}><p>Unsere Werte</p></div>
                </>
                }
                {active[2] && (params.activePages["partner"] === 1) &&
                  <div style={styles[2]} className="navSub" onClick={() => checkchange("/partner", 2)}><p>Die Partner</p></div>
                }
                {active[2] && (params.activePages["history"] === 1) &&
                  <div style={styles[2]} className="navSub" onClick={() => checkchange("/geschichte", 2)}><p>Geschichte</p></div>
                }
                {active[2] && (params.activePages["reference"] === 1) &&
                  <div style={styles[2]} className="navSub" onClick={() => checkchange("/referenzen", 2)}><p>Referenzen</p></div>
                }
            </div>
            <div className={aClass} id="nav-markt" onMouseLeave={() => left(3)}>
                <div style={backgrounds[3]} onMouseEnter={() => hover(3)} className="navMain" onClick={() => changePage("/marktplatz")}><p>Marktplatz</p></div>
                {active[3] &&
                <>
                  <div style={styles[3]} className="navSub" onClick={() => checkchange("/wir-vermieten", 3)}><p>Wir vermieten</p></div>
                  <div style={styles[3]} className="navSub" onClick={() => checkchange("/wir-verkaufen", 3)}><p>Wir verkaufen</p></div>
                  <div style={styles[3]} className="navSub" onClick={() => checkchange("/suchauftrag", 3)}><p>Suchauftrag</p></div>
                  {project_pages}
                </>
                }
            </div>
            <div className={aClass} id="nav-sch" onMouseLeave={() => left(4)}>
                <div style={backgrounds[4]} onMouseEnter={() => hover(4)} className="navMain" onClick={() => changePage("/schalter")}><p>Online-Schalter</p></div>
                {active[4] &&
                <>
                  <div style={styles[4]} className="navSub" onClick={() => checkchange("/eigentuemerportal", 4)}><p>Eigentümerportal</p></div>
                  <div style={styles[4]} className="navSub" onClick={() => checkchange("/datenraum", 4)}><p>Datenraum</p></div>
                  <div style={styles[4]} className="navSub" onClick={() => checkchange("/downloads", 4)}><p>Downloads</p></div>
                </>
                }
                {active[4] && (params.activePages["wissen"] === 1) &&
                  <div style={styles[4]} className="navSub" onClick={() => checkchange("/wissen", 4)}><p>Wissen</p></div>
                }
            </div>
            <div className={aClass} onClick={() => changePage("/honorarrechner")} onMouseLeave={() => left(6)} id="nav-hon">
                <div style={backgrounds[6]} onMouseEnter={() => hover(6)} className="navMain"><p>Honorarrechner</p></div>
            </div>
            <div className={aClass} onClick={() => changePage("/kontakt")} onMouseLeave={() => left(5)} id="nav-kont">
                <div style={backgrounds[5]} onMouseEnter={() => hover(5)} className="navMain"><p>Kontakt</p></div>
            </div>
        </div>
        </>
    )
}

/* ================================= HELPER FUNCTIONS ================================= */

function changePage(link) {
  window.location.href=link;
}

function parseLineBreaks(text){
  const splitted_text = text.split("<br>")
  if (splitted_text.length === 1){
    return (
      <>
        {text}
      </>
    )
  }
  return (
    <>
      {splitted_text.map(function(item, idx) {
        return (

          <span>{item}{idx !== splitted_text.length - 1 && <br/>}</span>
        )
      })}
    </>
  );
}

async function Logout() {
  const url = "/api/v1/userarea/logout";
  await fetch(url)
    .then(checkStatus)
    .catch();
  changePage("/");
}

function checkStatus(response) {
  if(response.ok){
    return response;
  } else {
    throw Error("Error in request: " + response.statusText)
  }
}

async function sendMail(target, source, subject, content) {
  const url = "/api/v1/utils/sendMail";
  let params = new FormData();
  params.append('target', target);
  params.append('source', source);
  params.append('subject', subject);
  params.append('content', content);

  fetch(url, {method: "POST", body: params})
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {})
    .catch(_ => alert("Internal Server Error. Bitte versuchen Sie es erneut."));
}

async function getImage(link) {
  const url =  link;
  let imgurl = fetch(url)
    .then(checkStatus)
    .then(res => res.blob())
    .then(imageBlob => {
      const imageObjectURL = URL.createObjectURL(imageBlob);
      return imageObjectURL;
    })
    .catch(error => {console.log(error); return null});
  return imgurl;
}

/* --------------------------------- HELPER FUNCTIONS --------------------------------- */

/* ================================= GLOBAL ================================= */

/* Icon*/
function Icon(params){
    let width = "50"
    let height = "50"
    let color = "text-black"
    if(params.size){
      width = params.size
      height = params.size
    }
    if(params.color) {
      color = "text-" + params.color
    }
    let text_class = color + " iconstext"
    if((params.description && params.link) || params.hover){
      text_class += " iconslink"
    }
    return (
      <div className="iconscontainer">
        <img 
          className="icons"
          src={params.img}
          width={width}
          height={height}
          alt={params.img}
        />
        {params.description && params.link &&
            <p><a className={text_class} href={params.link}>{params.description}</a></p>
        }
        {params.text && !params.link && 
            <p className={text_class}>{params.text}</p>
        }
      </div>
    )
}

/* Title */
function Title(params) {
  return (
    <p className="hcard-first"> 
      <span className="hcard-title text-black">{params.title}</span> 
      <span className="hcard-space"> </span>
      <span className="hcard-slogan text-orange">{params.slogan}</span>
    </p>
  )
}

function FontAwesomeText(params) {
  let p_class = ""
  let icon_class = ""
  let div_class = ""
  if(params.menu) {
    p_class = "fa-menuicon-text"
    icon_class = "fa-menuicon-class"
    div_class = "fa-iconscontainer"
  }
  if(params.small_menu) {
    p_class = "fa-menuicon-text"
    icon_class = "fa-menuicon-class"
    div_class = "fa-iconscontainer fa-container-boundary"
  }
  return (
    <div className={div_class} >
      {params.left &&
        <FontAwesomeIcon className={icon_class} icon={params.icon} />
      }
      <p className={p_class}>{params.text} </p>
      {!params.left &&
        <FontAwesomeIcon className={icon_class} icon={params.icon} />
      }
    </div>
  )
}

/* Big Button */
function BigButton(params) {
  let divclass = "bigbutton bbwide background-orange"
  if(params.high) {
    divclass = "bigbutton bbhigh background-orange"
  }
  if(params.selfstyle) {
    divclass = ""
  }
  if(params.styling) {
    divclass += " " + params.styling
  } 
  if(params.link){
    return (
      <div className={divclass} onClick={() => changePage(params.link)}>
        <p>{params.text}</p>
      </div>
    )
  }
  else if(params.function) {
    return (
      <div className={divclass} onClick={params.function}>
        <p>{params.text}</p>
      </div>
    )
  } else if(params.doclink) {
    return(
    <div className={divclass}>
      <a href={params.doclink} target="_blank" rel="noreferrer">
        <p>{params.text}</p>
      </a>
    </div>
    )
  }
}

/* Text & Button */
function TxtBut(params) {
  return (
    <div className="SidePaddingBig tb-box">
      <p>{params.text}</p>
      <BigButton doclink={params.link} text={params.linktext}/>
    </div>
  )
}

/* Image & Text */
function ImgText(params) {
  let text = parseLineBreaks(params.text)
  let imgleft = true
  let divClass = "it-box"
  if(params.right){
    imgleft = false
    divClass = "it-box-reverse"
  } 
  return (
    <div className={divClass}>
      {imgleft && !params.imgtitle &&
        <img className="it-img-left"
        src={params.img}
        alt={params.alt}
        />
      }
      {imgleft && params.imgtitle &&
        <div className="it-img-box">
        <p className="it-imgtitle-left text-orange">{params.imgtitle}</p> 
        <img className="it-img-left"
        src={params.img}
        alt={params.alt}
        />
        </div>
      }
      {!imgleft && !params.imgtitle &&
        <img className="it-img-right"
        src={params.img}
        alt={params.alt}
        />
      }
      {!imgleft && params.imgtitle &&
        <div className="it-img-box">
        <p className="it-imgtitle-right text-orange">{params.imgtitle}</p> 
        <img className="it-img-right"
        src={params.img}
        alt={params.alt}
        />
        </div>
      }
      <div className="it-text">
        {params.slogan && 
          <p><span>{text}</span><br></br><br></br><span className="text-orange">{params.slogan}</span></p>
        }
        {!params.slogan &&
          <p>{text}</p>
        }
      </div>
    </div>
  )
}

/* ObjectTable */
function ObjectTable(params) {
  const [width, setWidth] = React.useState(window.innerWidth)
  function handleWindowSizeChange(){
    setWidth(window.innerWidth)
  }
  useEffect(()=>{
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  let table = params.table;
  if(width > table["maxSize"]){
    let rows = []
    for(let i = 0; i < table["title"].length; i++){
      console.log(table)
      if(table["title"][i]["show_big"]){
        console.log(table["title"][i])
        let thisColumn = [];
        thisColumn.push(
          <div className="ot-elem-title">
            <p>{table["title"][i]["text"]}</p>
          </div>
        )
        let counter = 1;
        let divClass = "";
        if(table["title"][i]["type"] === "text"){
          for(let thisElem of table["elems"]){
            console.log(table["title"][i])
            console.log(thisElem["fields"][i])
            if (counter % 2 !== 0){
              divClass = "ot-elem-text background-white";
            } else {
              divClass = "ot-elem-text";
            }
            if(thisElem["fields"][i]["text"].endsWith("m^2")){
              console.log("in the first")
              thisColumn.push(
                <div className={divClass}>
                  <p>{thisElem["fields"][i]["text"].slice(0, -3)}m<sup>2</sup></p>
                </div>
              )
            } else {
              console.log("in the second")
              thisColumn.push(
                <div className={divClass}>
                  <p>{thisElem["fields"][i]["text"]}</p>
                </div>
              )
            }
            counter += 1;
          }
        } else if(table["title"][i]["type"] === "document"){
          for(let thisElem of table["elems"]){
            if (counter % 2 !== 0){
              divClass = "ot-elem-document background-white";
            } else {
              divClass = "ot-elem-document";
            }
            thisColumn.push(
              <div className={divClass}>
                <DownloadElem location={thisElem["fields"][i]["document"]} type="project"/>
              </div>
            )
            counter += 1;
          }
        } else if(table["title"][i]["type"] === "mail"){
          for(let thisElem of table["elems"]){
            if (counter % 2 !== 0){
              divClass = "ot-elem-mail background-white";
            } else {
              divClass = "ot-elem-mail";
            }
            let link = "mailto:" + thisElem["fields"][i]["mail"]
            thisColumn.push(
              <div className={divClass}>
                <a href={link}><Icon img={mail_orange}></Icon></a>
              </div>
            )
            counter += 1;
          }
        }
        rows.push(
          <div className="ot-cell">
            {thisColumn}
          </div>
        );
      }
    }
    return(
      <>
      <div>
        <p className="ot-description">{table["description"]}</p>
      </div>
      <div className="ot-box">
        {rows}
      </div>
      </>
    )
  } else {
    let tableElems = []
    for(let i = 0; i < table["elems"].length; i++){
      let thisElem = [];
      for(let j = 0; j < table["title"].length; j++){
        if(table["title"][j]["type"] === "text" && table["title"][j]["show_small"]){
          if(table["elems"][i]["fields"][j]["text"].endsWith("m^2")){
            thisElem.push(
              <div className="ot-small-elem">
                <p className="ot-small-title">{table["title"][j]["text"]}:</p>
                <p className="ot-small-content">{table["elems"][i]["fields"][j]["text"].slice(0, -3)}m<sup>2</sup></p>
              </div>
            )
          } else {
            thisElem.push(
              <div className="ot-small-elem">
                <p className="ot-small-title">{table["title"][j]["text"]}:</p>
                <p className="ot-small-content">{table["elems"][i]["fields"][j]["text"]}</p>
              </div>
            )
          }
        } else if(table["title"][j]["type"] === "document" && table["title"][j]["show_small"]){
          thisElem.push(
            <div className="ot-small-elem">
              <p className="ot-small-title">{table["title"][j]["text"]}:</p>
              <DownloadElem location={table["elems"][i]["fields"][j]["document"]} type="project"/>
            </div>
          )
        } else if(table["title"][j]["type"] === "mail" && table["title"][j]["show_small"]){
          let link = "mailto:" + table["elems"][i]["fields"][j]["mail"]
          thisElem.push(
            <div className="ot-small-elem">
              <p className="ot-small-title">{table["title"][j]["text"]}:</p>
              <a href={link}><Icon img={mail_orange}></Icon></a>
            </div>
          )
        }
      }
      tableElems.push(
        <div className="ot-small-container">
          <div className="ot-small-maintitle">
            <p>{table["elems"][i]["title"]}</p>
          </div>
          <div className="ot-small-containerelem">
            {thisElem}
          </div>
        </div>
      )
    }
    return(
      <>
      <div>
        <p className="ot-description">{table["description"]}</p>
      </div>
      <div className="ot-main-small">
        {tableElems}
      </div>
      </>
    )
  }
}

/* TickTable */
function TickTable(params) {
  const [width, setWidth] = React.useState(window.innerWidth)
  function handleWindowSizeChange(){
    setWidth(window.innerWidth)
  }
  useEffect(()=>{
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  if(params.title === ""){
    return(
      <div className="tt-single flex-column">
        <div>
        {params.elems.map(function(item, idx) {
          return(
            <Icon img={tick_orange} text={item} size="40"/>
          )
        })}
        </div>
      </div>
    );
  }
  if(width > 1490){
    let n_elems = params.elems[0].length;
    let table = []
    table.push(
      <div className="tt-more-inner">
        <div className="tt-3-elem tt-main text-orange">
          <p className="tt-main text-orange">{params.title[0]}</p>
        </div>
        <div className="tt-3-elem tt-main text-orange">
          <p className="tt-main text-orange">{params.title[1]}</p>
        </div>
        <div className="tt-3-elem tt-main text-orange">
          <p className="tt-main text-orange">{params.title[2]}</p>
        </div>
      </div>
    )
    for(let i = 0; i < n_elems; i++){
      table.push(
        <div className="tt-more-inner">
          <div className="tt-3-elem">
            <Icon img={tick_orange} text={params.elems[0][i]} size="40"/>
          </div>
          <div className="tt-3-elem">
            <Icon img={tick_orange} text={params.elems[1][i]} size="40"/>
          </div>
          <div className="tt-3-elem">
            <Icon img={tick_orange} text={params.elems[2][i]} size="40"/>
          </div>
        </div>
      )
    }
    return(
      <div className="tt-more-outer">
        {table}
      </div>
    );
  } else if(width > 1060){
    let n_elems = params.elems[0].length;
    let table = []
    table.push(
      <div className="tt-more-inner">
        <div className="tt-2-elem tt-main text-orange">
          <p className="tt-main text-orange">{params.title[0]}</p>
        </div>
        <div className="tt-2-elem tt-main text-orange">
          <p className="tt-main text-orange">{params.title[1]}</p>
        </div>
      </div>
    )
    for(let i = 0; i < n_elems; i++){
      table.push(
        <div className="tt-more-inner">
          <div className="tt-2-elem">
            <Icon img={tick_orange} text={params.elems[0][i]} size="40"/>
          </div>
          <div className="tt-2-elem">
            <Icon img={tick_orange} text={params.elems[1][i]} size="40"/>
          </div>
        </div>
      )
    }
    return(
      <>
      <div className="tt-more-outer">
        {table}
      </div>
      <div className="tt-inner">
      <div><p className="tt-main text-orange">{params.title[2]}</p></div>
      {params.elems[2].map(function(item, idx) {
        return (
          <Icon img={tick_orange} text={item} size="40"/>
        )
      })}
    </div>
    </>
    );
  }
  return (
    <div className="tt-outer">
      <div className="tt-inner">
        <div><p className="tt-main text-orange">{params.title[0]}</p></div>
        {params.elems[0].map(function(item, idx) {
          return (
            <Icon img={tick_orange} text={item} size="40"/>
          )
        })}
      </div>
      <div className="tt-inner">
        <div><p className="tt-main text-orange">{params.title[1]}</p></div>
        {params.elems[1].map(function(item, idx) {
          return (
            <Icon img={tick_orange} text={item} size="40"/>
          )
        })}
      </div>
      <div className="tt-inner">
        <div><p className="tt-main text-orange">{params.title[2]}</p></div>
        {params.elems[2].map(function(item, idx) {
          return (
            <Icon img={tick_orange} text={item} size="40"/>
          )
        })}
      </div>
    </div>
  );
}

/* SmallSliderShow showing up to 3 SmallSliderCards that are clickable */
function SmallSliderShow(params){
  const [slides, setSlides] = React.useState([]);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [windowSize, setWindowSize] = React.useState(window.innerWidth)
  const [mainClass, setMainClass] = React.useState("sslider flex-row SidePaddingBig")
  const [slideBig, setSlideBig] = React.useState(-1);

  useEffect(()=>{
    function handleWindowSizeChange(){
      setWindowSize(window.innerWidth);
      if(windowSize < 635){
        setMainClass("sslider flex-row")
      }
      else{
        setMainClass("sslider flex-row SidePaddingBig")
      }
    }
    if(windowSize < 635){
      setMainClass("sslider flex-row");
    }
    setSlides(params.slides);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [params.slides, windowSize]);

  function previous(){
    if(currentSlide > 0){
      setCurrentSlide(currentSlide - 1)
    } else {
      setCurrentSlide(slides.length-1)
    }
  }
  function next() {
    setCurrentSlide((currentSlide + 1)% slides.length)
  }

  function previousBig(){
    if(slideBig > 0){
      setSlideBig(slideBig - 1)
    } else {
      setSlideBig(slides.length-1)
    }
  }
  function nextBig() {
    setSlideBig((slideBig + 1)% slides.length)
  }

  return (
    <>
    <div className={mainClass}>
      <BigButton function={previous} text="<" high={true} styling="topbotmarginBig"/>
      <div className="sscard-center">
        <img onClick={() => setSlideBig(currentSlide)}
          className="sscard-img-link"
          src={slides[currentSlide]}
          alt={params.alt}
        />
      </div>
      {windowSize > 1210 && 
        <div className="sscard-center">
          <img onClick={() => setSlideBig((currentSlide + 1)% slides.length)}
            className="sscard-img-link"
            src={slides[(currentSlide + 1)% slides.length]}
            alt={params.alt}
          />
        </div>
      }
      {windowSize > 1715 &&
        <div className="sscard-center">
          <img onClick={() => setSlideBig((currentSlide + 2)% slides.length)}
            className="sscard-img-link"
            src={slides[(currentSlide + 2)% slides.length]}
            alt={params.alt}
          />
        </div>
      }
      <BigButton function={next} text=">" high={true} styling="topbotmarginBig"/>
    </div>
    {slideBig !== -1 &&
      <div className="popupfield">
      <div className="popupcontent">
        <div className="popupcontainer-sscard">
          <div className="popupicon" onClick={() => setSlideBig(-1)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className="sscard-img-container">
            <BigButton function={previousBig} text="<" high={true}/>
            <img
              className="sscard-img-popup"
              src={slides[slideBig]}
              alt={params.alt}
            />
            <BigButton function={nextBig} text=">" high={true}/>
          </div>
        </div>
      </div>
    </div>
    }
    </>
  )
}

/* Small Cards for Slider */
function SmallSliderCard(params) {
  const [thisstyle, setThisstyle] = React.useState({});

  useLayoutEffect(() => {
    function updateStyle() {
      let width = window.innerWidth
      if(width > 470){
        setThisstyle({
          height: "220px",
        })
      } else {
        setThisstyle({
          height: parseInt((330 - (471.4 - width)* 0.7)*2/3) + "px"
        })
      }
    }
    window.addEventListener('resize', updateStyle);
    updateStyle();
    return () => window.removeEventListener('resize', updateStyle);
  }, []);
  if(params.fotoShow){
    return(
      <div className="sscard">
        <img
          className="sscard-img-link"
          src={params.img}
          alt={params.alt}
        />
      </div>
    )
  }
  if(params.verkaufpage){
    return(
      <div className="sscard">
        <div className="sscard-hover-img" style={thisstyle}>
          <div className="outer sscard-hover-img-container" style={thisstyle}>
            <img
              className="inner-front" style={thisstyle}
              src={params.img}
              alt={params.alt}
            />
            <div className="inner-back mcpopup background-orange" style={thisstyle}><p className="">{params.hovertext}</p></div>
          </div>
        </div>
        <p className="sscard-text">
          <span className="text-orange">{params.place}</span><br></br>
          <span>{params.type}</span><br></br>
        </p>
        <p className="sscard-title text-orange">{params.title}</p>
        <p className="sscard-text">
          <span>{params.text}</span>
        </p>
      </div>
    )
  } else {
    return(
      <div className="sscard">
        <p className="sscard-title text-orange">{params.title}</p>
        <img
          className="sscard-img"
          src={params.img}
          alt={params.alt}
        />
        <p className="sscard-text">
          <span>{params.place}</span><br></br>
          <span className="text-orange">{params.type}</span><br></br><br></br>
          <span>{params.text}</span>
        </p>
      </div>
    )
  }
}

/* SmallSlider showing up to 3 SmallSliderCards */
function SmallSlider(params){
  const [slides, setSlides] = React.useState([]);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [windowSize, setWindowSize] = React.useState(window.innerWidth)
  const [mainClass, setMainClass] = React.useState("sslider flex-row SidePaddingBig")

  useEffect(()=>{
    function handleWindowSizeChange(){
      setWindowSize(window.innerWidth);
      if(windowSize < 635){
        setMainClass("sslider flex-row")
      }
      else{
        setMainClass("sslider flex-row SidePaddingBig")
      }
    }
    if(windowSize < 635){
      setMainClass("sslider flex-row");
    }
    setSlides(params.slides);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [params.slides, windowSize]);

  function previous(){
    if(currentSlide > 0){
      setCurrentSlide(currentSlide - 1)
    } else {
      setCurrentSlide(slides.length-1)
    }
  }
  function next() {
    setCurrentSlide((currentSlide + 1)% slides.length)
  }

  return (
    <div className={mainClass}>
      <BigButton function={previous} text="<" high={true} styling="topbotmarginBig"/>
      {slides[currentSlide]}
      {windowSize > 1210 && 
        slides[(currentSlide + 1)% slides.length]
      }
      {windowSize > 1715 &&
        slides[(currentSlide + 2)% slides.length]
      }
      <BigButton function={next} text=">" high={true} styling="topbotmarginBig"/>
    </div>
  )
}

/* Big Cards for Slider */
function BigSliderCard(params) {
  const [textstyle, setTextstyle] = React.useState([]);

  useLayoutEffect(() => {
    function updateStyle() {
      let this_height = window.innerWidth / 3.2
      setTextstyle({
        bottom: parseInt(this_height / 12) + "px",
        fontSize: parseInt(Math.min(this_height / 20 + 2, 25)) + "px",
        padding: parseInt(this_height / 20) + "px",
      })
    }
    window.addEventListener('resize', updateStyle);
    updateStyle();
    return () => window.removeEventListener('resize', updateStyle);
  }, []);

  if(params.link){
    return(
      <div className="bscard bscard-link inner-back">
        <img
          className="bscard-img"
          src={params.img}
          alt={params.alt}
        />
        <div style={textstyle} className="bscard-text background-orange flex-row" onClick={() => changePage(params.link)}>
          <p className="text-black">{params.text}</p>
        </div>
      </div>
    )
  } else {
    return(
      <div className="bscard inner-back">
        <img
          className="bscard-img"
          src={params.img}
          alt={params.alt}
        />
      </div>
    )
  }
}

/* BigSlider showing 1 BigSliderCards */
function BigSlider(params){
  const [slides, setSlides] = React.useState([]);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [styler, setStyler] = React.useState({});
  const [buttonstyler, setButtonstyler] = React.useState({})
  let interval = 10000;
  if(params.interval){
    interval = params.interval
  }

  useEffect(()=>{
    setSlides(params.slides);
    let this_height = window.innerWidth / 3.2
    setStyler({
      height : parseInt(this_height) + "px" 
    });
    setButtonstyler({
      height: parseInt(this_height/4) + "px",
      width: parseInt(this_height/8) + "px",
      top: parseInt(this_height/2 - this_height/8),
    });

    const loop = setInterval(() =>{
      setCurrentSlide((currentSlide + 1)% slides.length)
    }, interval);
    return () => clearInterval(loop);
  }, [params.slides, currentSlide, slides, interval]);

  function previous(){
    if(currentSlide > 0){
      setCurrentSlide(currentSlide - 1)
    } else {
      setCurrentSlide(slides.length-1)
    }
  }
  function next() {
    setCurrentSlide((currentSlide + 1)% slides.length)
  }
  return (
    <div className="bslider outer" style={styler}>
      <div onClick={previous} style={buttonstyler} className="bigbutton background-orange bslider-bt-left">
        <p> &lt; </p>
      </div>
      {slides[currentSlide]}
      <div onClick={next} style={buttonstyler} className="bigbutton background-orange bslider-bt-right">
        <p> &gt; </p>
      </div>
    </div>
  )
}

/* Downloads */
function DownloadElem(params) {
  let splittedURL = window.location.href.split("/")
  let url = splittedURL[0] + "//" + splittedURL[2];
  if(splittedURL[2].includes("localhost")){
    url = "http://localhost:3001/api/v1/"
  } else {
    url += "/api/v1/"
  }
  if(params.type === "user") {
    url += "userarea/getFile/"
  } else if (params.type === "public") {
    url += "downloads/getFile/"
  } else if (params.type === "project") {
    url += "projects/getDoc/"
  }
  if(params.object){
    if("id" in params.object){
      url += params.object.id
      return(
        <div className="dl-elem">
          <a href={url} title={params.object.name} target="_blank" rel="noreferrer">
          <Icon img={download_orange} text={params.object.name} hover={true}/>
          </a>
        </div>
      )
    }
    url += params.object.location
    return(
      <div className="dl-elem">
        <a href={url} title={params.object.name} target="_blank" rel="noreferrer">
        <Icon img={download_orange} text={params.object.name} hover={true}/>
        </a>
      </div>
    )
  } else {
    url += params.location
    return(
      <div className="dl-elem">
        <a href={url} target="_blank" rel="noreferrer">
          <Icon img={download_orange} hover={true}/>
        </a>
      </div>
    )
  }
}

function Downloads(params) {
  let download_objects = params.object
  let main_class = "SidePaddingBig dlcard hcard"
  if (params.background){
    main_class += " background-" + params.background
  }
  let type = "public";
  if (params.type === "project") {
    type = "project";
    /*
    return(
      <div className={main_class}>
        <Title title="Downloads." slogan="Alles für Sie bereit."/>
        {download_objects.map(function(elem) {
          return(
            <DownloadElem object={elem} type="project"/>
          )
        })}
      </div>
    );*/
  }
  return(
    <div className={main_class}>
      <Title title="Downloads." slogan="Alles für Sie bereit."/>
      {download_objects.map(function(elem) {
        return(
          <DownloadElem object={elem} type={type}/>
        )
      })}
    </div>
  );
}


/* Cards for links in subpages*/
function MainCards(params) {
  const [thisstyle, setThisstyle] = React.useState({});

  useLayoutEffect(() => {
    function updateStyle() {
      let width = window.innerWidth
      if(width > 700){
        setThisstyle({
          height: "334px",
          width: "500px"
        })
      } else {
        setThisstyle({
          width: parseInt(width*0.8) + "px",
          height: parseInt(width*0.8/1.497) + "px"
        })
      }
    }
    window.addEventListener('resize', updateStyle);
    updateStyle();
    return () => window.removeEventListener('resize', updateStyle);
  }, []);

  let text = parseLineBreaks(params.text)
  return(
      <div className="mcard" onClick={() => changePage(params.link)}>
          <p className="mcard-title text-black">{params.title}</p>
          <div className="outer" style={thisstyle}>
            <img style={thisstyle}
              className="Cardimg inner-front"
              src={params.img}
              alt="mountains"
            />
            <div className="inner-back mcpopup background-orange"><p className="">Mehr erfahren.</p></div>
          </div>
          <p className="card-text text-orange">{text}</p>
      </div>
  )
}

/* SubCards as smaller elements of HomeCards */
function SubCard(params){
  return(
    <div className="scard">
      {params.elem.title &&
        <p className="scard-title text-orange">{params.elem.title}</p>
      }
      {params.elem.text &&
        <p className="scard-text">{params.elem.text}</p>
      }
      {params.elem.download &&
        params.elem.download.map(function(elem) {
          return(
            <DownloadElem object={elem} type={params.type}/>
          )
        })
      }
    </div>
  )
}

function Paragraph(params) {
  return(
    <div className="paragraph-cont">
      <div className="paragraph-title"><p>{params.title}</p></div>
      <div className="paragraph-text"><p>{parseLineBreaks(params.text)}</p></div>
    </div>
  )
}

/* Cards for sections on all pages */
function HomeCard(params) {
  let main_class = "hcard SidePaddingBig"
  let text = ""
  if(params.text){
    text = parseLineBreaks(params.text)
  }
  if (params.background && params.background !== ""){
    main_class += " background-" + params.background
  }
  return (
    <div className={main_class}>
      <Title title={params.title} slogan={params.slogan}/>
      {params.img &&
        <img
            className="hcard-img"
            width="100%"
            height="100%"
            src={params.img}
            alt=""
        />
      }
      {params.table &&
        <TickTable title={params.table.title} elems={params.table.elems} />
      }
      {params.objecttable &&
        params.objecttable.map(function(item, idx) {
          return (
            <ObjectTable table={item}/>
          )
        })
      }
      {params.text &&
        <p className="hcard-text text-black">{text}</p>
      }
      {params.link &&
        <BigButton link={params.link} text={params.linktext}/>
      }
      {params.imglink &&
        <a href={params.imglink.link}>
        <img
          className="PresenterImg hcard-img"
          src={params.imglink.img}
          alt=""
        /></a>
      }
      {params.login && params.login_type !== 3 &&
        <Login type={params.login_type}/>
      }
      {params.login && params.login_type === 3 &&
        <ChangePW/>
      }
      {params.subpages &&
        params.subpages.map(function(item, idx) {
          return (
            <SubCard elem={item} type={params.download}/>
          )
        })
      }
      {params.paragraphs &&
        params.paragraphs.map(function(elem, idx) {
          return(
            <Paragraph title={elem.title} text={elem.text} />
          )
        })
      }
    </div>
  )
}

function MainImage(params) {
  return(
    <div className="MainImgContainer">
      <img 
        className="MainImg"
        src={params.img}
        alt={params.alt}
      />
    </div>
  );
}

/* --------------------------------- GLOBAL --------------------------------- */

/* ================================= GLOBAL_CARDS ================================= */

function SuchauftragCard(params) {
  let background = ""
  let text = "Haben Sie Ihre Traumwohnung oder Ihr Traumhaus noch nicht gefunden? Wir informieren Sie exklusiv und kostenlos über passende Miet- oder Kaufobjekte in unserem Portfolio."
  if (params.background){
    background = params.background
  }
  if (params.text) {
    text = params.text
  }
  return(
    <HomeCard 
      title="Suchauftrag." slogan="Wir halten Ausschau für Sie." text={text}
      link="/suchauftrag" linktext="Suchauftrag erteilen." background={background}
    />
  );
}

function DatenraumCard(params) {
  let background = ""
  let text = "Sämtliche relevanten Informationen zum Kaufobjekt stehen im Datenraum für Ihren Kaufentscheid zur Verfügung."
  if (params.background){
    background = params.background
  }
  if (params.text) {
    text = params.text
  }
  return(
    <HomeCard 
      title="Datenraum." slogan="Alle Dokumente für Ihren Kaufentscheid." text={text}
      link="/datenraum" linktext="Zum Datenraum." background={background}
    />
  );
}

/* --------------------------------- GLOBAL_CARDS --------------------------------- */

/* ================================= FOOTER ================================= */

function Footer() {
  let thisYear = new Date().getFullYear();
  return(
      <footer>
      <div className="Footercontainer SidePaddingBig">
          <div className="FooterBlock">
              <div className="FLinks" onClick={() => changePage("/")}>
                <img
                    src={logo_white_trans}
                    width="100%"
                    alt="DEFLORIN AG"
                />
              </div>
          </div>
          <div className="FooterBlock">
            <p>
              DEFLORIN AG<br></br>Rheinstrasse 47<br></br>7012 Felsberg
              <br></br><br></br>
              <a className="text-black FLinks" href="tel:+41 81 377 70 12">081 377 70 12</a>
              <br></br>
              <a className="text-black FLinks" href="mailto:info@deflorin-ag.ch">info@deflorin-ag.ch</a>
            </p>
          </div>
          <div className="FooterBlock">
              <div className="FSocial">
                <a href="https://www.linkedin.com/company/deflorin-ag/"><Icon img={linkedin_black} size="80"/></a>
                <a href="https://facebook.com/deflorinag"><Icon img={facebook_black} size="80"/></a>
                <a href="https://instagram.com/deflorinag/"><Icon img={instagram_black} size="80"/></a>
              </div>
          </div>
      </div>
      <div className="SidePaddingBig FooterBottomContainer">
        <div className="FooterSVIT" id="FSVIT">
          <p>Mitglied bei:</p>
          <div className="FLinks">
            <a href="https://www.svit.ch/de/svit-graubuenden">
              <img
                src={logo_svit}
                alt="Swit Logo"
                width="40%"
              />
            </a>
          </div>
        </div>
        <div className="FooterBlock" id="FCopyright"><p> DEFLORIN AG &copy; {thisYear}</p></div>
        <div className="FooterBlock" id="FImpressum">
              <div className="FSocial">
                <div className="FLinks" onClick={() => changePage("/impressum")}>
                    <p>Impressum</p>
                </div>
                <div className="FLinks" onClick={() => changePage("/datenschutz")}>
                    <p>Datenschutz</p>
                </div>
              </div>
          </div>
      </div>
      </footer>
  )
}

/* --------------------------------- FOOTER --------------------------------- */

/* ================================= CONTACT_GLOBAL ================================= */

/* Contact Form */

const fontSizes = {
  'text-button' : '18px', 
  'title-sub-medium' : '22px',
  'text-normal': '20px',
}

const colors = {
  'white': '#ffffff',
  'orange': '#e59300',
  'black': '#13181e',
  'grey': '#575c62',
}

const contactFormStyle = makeStyles((theme) => ({
  root: {
    maxWidth: '500px',
    minWidth: '40%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginTop: '20px',
    '& > *': {
      margin: theme.spacing(1),
      backgroundColor: 'white',
      textAlign: 'left',
    },
    '& button': {
      cursor: 'pointer',
      border: 'none',
      fontWeight: 'bold',
      fontFamily: 'ArgentumSans-Light',
      fontSize: fontSizes['text-button'],
      color: colors['white'],
      height: '70px',
      width: '270px',
      maxWidth: '100%',
      backgroundColor: colors['orange'],
      textAlign: 'center',
    },
    '& button:hover': {
      color: colors['black'],
    }
  },
}));

const ContactForm = (params) => {
  const [messagesize, setMessagesize] = React.useState(3);
  const [showResponse, setShowResponse] = React.useState(false);
  const [response, setResponse] = React.useState({})
  let thema_default = ""
  if(params.thema){
    thema_default = params.thema
  }
  let error_msg = "Dieses Feld ist erforderlich."
  let no_option_selected_err = "Bitte wählen Sie ein Feld aus."
  const phoneRegExp = /((\+[0-9]{11})|([0-9]{10})|(\+[0-9]{2} [0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2})|([0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}))$/
  const themamap = {
    "offertanfrage": "Offertanfrage",
    "personal": "Wunsch persönliche Besprechung",
    "mietverhaltnis": "Mietverhältnis",
    "stockwerkeigentum": "Stockwerkeigentum",
    "fragen": "Fragen zu aktuellen Wohnungsangeboten",
    "allerlei": "Allerlei", 
  }
  const contactprefmap = {
    "phone": "Telefonisch",
    "email": "Per E-Mail",
  }
  const validationSchema = Yup.object({
    thema: Yup.string()
      .oneOf(
        ["offertanfrage", "personal", "mietverhaltnis", "stockwerkeigentum", "fragen", "allerlei"],
        no_option_selected_err
      )
      .required(error_msg),
    firstname: Yup.string()
      .max(30, "Maximal 30 Buchstaben")
      .required(error_msg),
    lastname: Yup.string()
      .max(30, "Maximal 30 Buchstaben")
      .required(error_msg),
    email: Yup.string()
      .email("Ungültige E-Mail Adresse")
      .required(error_msg),
    phone: Yup.string()
      .matches(phoneRegExp, "Ungültige Telefonnummer"),
    contactpref : Yup.string()
      .oneOf(
        ["phone", "email"],
        no_option_selected_err
      )
      .required(error_msg),
    message : Yup.string()
  })
  const classes = contactFormStyle();
  
  return (
    <>
    <Formik
      initialValues={{
        thema: thema_default,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        contactpref: "",
        message: "",
        //acceptedTerms: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting, resetForm}) => {
        let target = "fabio.deflorin@deflorin-ag.ch"
        let source = "server@deflorin-ag.ch"
        let subject = "Neue Kontaktanfrage von " + values.firstname + " " + values.lastname
        let content = "<html><h1>Hallo Fabio</h1><p>Du hast eine neue Kontaktanfrage erhalten:<br></br><br></br>"
        content += "Thema: " + themamap[values.thema] + "<br></br>"
        content += "Vorname: " + values.firstname + "<br></br>"
        content += "Nachname: " + values.lastname + "<br></br>"
        content += "E-Mail: " + values.email + "<br></br>"
        content += "Telefon: " + values.phone + "<br></br>"
        content += "Präferenz Kontakt: " + contactprefmap[values.contactpref] + "<br></br>"
        content += "Nachricht: " + values.message + "<br></br>"
        content += "</p></html>"
       
        sendMail(target, source, subject, content)
          .then(setSubmitting(false))
          .then(setShowResponse(true))
          .then(setResponse({
            "name": values.firstname + " " + values.lastname,
            "thema": themamap[values.thema],
            "contacttype": contactprefmap[values.contactpref].charAt(0).toLowerCase() +  contactprefmap[values.contactpref].slice(1)
          }));

        resetForm({values: ''});
      }}
    >
      <Form className={classes.root}>
        <Field component={TextField} variant="outlined" select={true}
          label="Thema*" name="thema" fullWidth={true}
        >
          <MenuItem value="">Thema auswählen</MenuItem>
          <MenuItem value="offertanfrage">Offertanfrage</MenuItem>
          <MenuItem value="personal">Wunsch persönliche Besprechung</MenuItem>
          <MenuItem value="mietverhaltnis">Mietverhältnis</MenuItem>
          <MenuItem value="stockwerkeigentum">Stockwerkeigentum</MenuItem>
          <MenuItem value="fragen">Fragen zu aktuellen Wohnungsangeboten</MenuItem>
          <MenuItem value="allerlei">Allerlei</MenuItem>
        </Field>
        <Field component={TextField} variant="outlined" 
          name="firstname" label="Vorname*" fullWidth={true}
        />
        <Field component={TextField} variant="outlined"
          name="lastname" label="Nachname*" fullWidth={true}
        />
        <Field component={TextField} variant="outlined"
          name="email" label="E-Mail*" type="email" fullWidth={true}
        />
        <Field component={TextField} variant="outlined"
          name="phone" label="Telefon" fullWidth={true}
        />
        <Field component={TextField} variant="outlined" select={true}
          label="Präferenz Kontakt*" name="contactpref" fullWidth={true}
        >
          <MenuItem value="">Kontaktart auswählen</MenuItem>
          <MenuItem value="phone">Telefonisch</MenuItem>
          <MenuItem value="email">E-Mail</MenuItem>
        </Field>
        <Field component={TextField} variant="outlined" fullWidth={true} onClick={() => setMessagesize(10)}
          name="message" label="Ihre Nachricht" multiline rows={messagesize}
        />
        <button type="submit">Absenden.</button>
      </Form>
    </Formik>
    {showResponse &&
      <div className="popupfield" onClick={() => setShowResponse(false)}>
        <div className="popupcontent">
          <div className="popupcontainer">
          <div className="popupicon">
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <p className="popuptext">
            {response["name"]},
            <br></br>
            <br></br>
            Vielen Dank für Ihre Kontaktanfrage zum Thema "{response['thema']}".
            <br></br>
            Wir werden uns schnellstmöglich bei Ihnen {response['contacttype']} melden.
            <br></br>
            <br></br>
            Freundliche Grüsse
            <br></br>
            DEFLORIN AG
          </p>
          </div>
        </div>
      </div>
    }
    </>
  )
}

function ContactInfo(params){
    const maillink = "mailto:" + params.contact.mail
    let tellink = "tel:+41"
    if(params.contact.tel){
      tellink = "tel:+41 " + params.contact.tel.substring(1)
    }
    let info_class = "ccard-info"
    let text_class = "ccard-info-text text-black"
    let name_class = "ccard-info-name"
    if(params.small) {
      text_class = "ccard-info-text-small text-black"
      name_class = "ccard-info-name-small"
      info_class = "ccard-info-small"
    }
    if(params.show_partner) {
      name_class = "ccard-info-name-small text-orange"
    }
    return (
        <div className={info_class}>
            {params.show_partner && 
              <p className="text-orange ccard-info-task">{params.contact.position}</p>
            }
          <img
                className="ccard-info-img"
                src={params.contact.img}
                alt="Contact Person"
            />
          <div className={text_class}>
            <div>
              {params.show_partner &&
                <p><a href={params.contact.website}>{params.contact.company}</a></p>
              }
              <p className={name_class}>{params.contact.name}</p>
              {!params.show_partner && 
                <p className="ccard-info-position">{params.contact.position}</p>
              }
            </div>
            <div className="ccard-tel-phone">
              <Icon img={phone_orange} description={params.contact.tel} link={tellink} size="40"/>
              <Icon img={mail_orange} description={params.contact.mail} link={maillink} size="40"/>
            </div>
          </div>
        </div>
    )
}

async function getContact(category){
  const url = "/api/v1/utils/getPeople/" + category;

  let data = await fetch(url)
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"]})
    .catch(error => {console.log(error); return []});
  for(let elem of data){
    elem["img"] = await getImage("/api/v1/utils/getImage/" + elem["imgid"]);
  }
  return data;
}

function ContactCard(params) {
  const [contactInfo, setContactInfo] = React.useState({});
  
  useEffect(() => {
    async function fetchData() {
      if(params.contact){
        const result = await getContact(params.contact);
        setContactInfo(result[0]);
      } else {
        const result = await getContact("0");
        setContactInfo(result[0]);
      }
    }
    fetchData();
  }, [params.contact]);
  let main_class = "ccard SidePaddingBig"
  let thema = ""
  if(params.thema){
    thema = params.thema
  }
  if (params.background){
    main_class += " background-" + params.background
  }
  let info = <ContactInfo contact={contactInfo} position={true}/>
  let form = <ContactForm mail={contactInfo["mail"]} thema={thema}/>
    return (
      <div className={main_class}>
        <p className="hcard-first"> 
            <span className="hcard-title text-black">Kontakt.</span> 
            <span className="hcard-space"> </span>
            <span className="hcard-slogan text-orange">Wir freuen uns auf Sie.</span>
          </p>
        <div className="ccard-inner">
          {info}
          {form}
        </div>
      </div>
    )
}

function AllContacts(params) {
  const [contactInfos, setContactInfo] = React.useState([]);
  
  useEffect(() => {
    async function fetchData() {
      const result = await getContact(params.filter);
      setContactInfo(result);
    }

    fetchData();
  }, [params.filter]);
  let contacts = []
  for (let elem of contactInfos){
    contacts.push(
      <ContactInfo contact={elem} position={params.position} show_partner={params.partner} small={true}/>
    )
  }
  return(
    <div className="flex-row SidePaddingBig contact-all">
      {contacts}
    </div>
  );
}

/* --------------------------------- CONTACT_GLOBAL --------------------------------- */

/* ================================= HONORARRECHNER_GLOBAL ================================= */

function HonorarCard(params) {
  if(params.nolink) {
    return(
      <div>
        <HomeCard title="Honorarrechner." slogan="Transparenz schafft Vertrauen." text="
          Erfahren Sie rasch und ohne Angabe von Kontaktdaten die Durchschnittskosten für Ihre gewünschte Dienstleistung.<br><br>
          Unser entwickelter Honorarrechner geht von durchschnittlichen Liegenschaften und Dienstleistungen aus und soll Ihnen vorerst einen Richtwert aufzeigen. Sehr gerne können Sie auch mit uns direkt Kontakt aufnehmen und wir unterbreiten Ihnen eine auf Ihre Bedürfnisse ausgerichtete und unverbindliche Offerte.  
        " background={params.background}/>
      </div>
    )
  }
  return (
    <div>
      <HomeCard title="Honorarrechner." slogan="Transparenz schafft Vertrauen." text="
          Erfahren Sie rasch und ohne Angabe von Kontaktdaten die Durchschnittskosten für Ihre gewünschte Dienstleistung.<br><br>
          Unser entwickelter Honorarrechner geht von durchschnittlichen Liegenschaften und Dienstleistungen aus und soll Ihnen vorerst einen Richtwert aufzeigen. Sehr gerne können Sie auch mit uns direkt Kontakt aufnehmen und wir unterbreiten Ihnen eine auf Ihre Bedürfnisse ausgerichtete und unverbindliche Offerte.  
        " background={params.background} link="/honorarrechner" linktext="Zum Honorarrechner."/>
    </div>
  );
}

/* --------------------------------- HONORARRECHNER_GLOBAL --------------------------------- */

/* ================================= LOGIN_PAGES ================================= */

const loginFormStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    '& > div': {
      margin: theme.spacing(1),
      textAlign: 'left',
      maxWidth: '500px',
      minWidth: '40%',
    },
    '& button': {
      cursor: 'pointer',
      border: 'none',
      fontWeight: 'bold',
      fontFamily: 'ArgentumSans-Light',
      fontSize: fontSizes['text-button'],
      color: colors['white'],
      height: '70px',
      width: '270px',
      maxWidth: '100%',
      backgroundColor: colors['orange'],
      textAlign: 'center',
    },
    '& button:hover': {
      color: colors['black'],
    },
    '& .button-right': {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      paddingTop: theme.spacing(1),
    }, 
    '& .error-msg' : {
      color: "red",
    }
  }
}))

function ChangePW(params){
  const [wrongPW, setWrongPW] = React.useState(false)
  const [changeSuccessful, setChangeSuccessful] = React.useState(false)
  const no_value_error = "Dieses Feld ist erforderlich."
  const wrong_value_error = "Zu schwaches Passwort. Überprüfen Sie die Anforderungen."
  const missmatch_error = "Passwörter stimmen nicht überein."
  const pwRegExp = /(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/
  const validationSchema = Yup.object({
    old_password: Yup.string().required(no_value_error),
    new_password_1: Yup.string()
      .matches(pwRegExp, wrong_value_error)
      .required(no_value_error),
    new_password_2: Yup.string()
      .oneOf([Yup.ref('new_password_1'), null], missmatch_error)
      .required(no_value_error),
  })
  const classes = loginFormStyle();
  return(
    <Formik 
      initialValues={{
        old_password: "",
        new_password_1: "",
        new_password_2: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        setWrongPW(false);
        let url = "/api/v1/userarea/changepw";
        let params = new FormData();
        params.append('old_password', values.old_password);
        params.append('new_password', values.new_password_2);
        fetch(url, {method: "POST", body: params})
          .then(response => {
            if(response.ok){
              setSubmitting(false);
              setChangeSuccessful(true);
            } else {
              setWrongPW(true);
              setSubmitting(false);
            }
          })
          .catch(_ => {
            setWrongPW(true);
            setSubmitting(false);
          })
      }}
    > 
    <>
      <Form className={classes.root}>
        <Field component={TextField} variant="outlined" type="password"
          name="old_password" label="Altes Passwort*" fullWidth={true}
        />
        <Field component={TextField} variant="outlined" type="password"
          name="new_password_1" label="Neues Passwort*" fullWidth={true}
        />
        <Field component={TextField} variant="outlined" type="password"
          name="new_password_2" label="Neues Passwort wiederholen*" fullWidth={true}
        />
        <div className="button-right">
          {wrongPW && 
            <p className="error-msg">Altes Passwort nicht korrekt.</p>
          }
          {!changeSuccessful &&
            <button type="submit">Passwort ändern.</button>
          }
        </div>
      </Form>
      {changeSuccessful &&
          <div>
            <p>Passwort erfolgreich geändert.</p>
            <BigButton text="Zurück zum Portal." function={() => changePage("/user-eigentuemerportal")}/>
          </div>
        }
    </>
    </Formik>  
  )
}

function Login(params){
  const [wronglogin, setWronglogin] = React.useState(false)
  const no_value_error = "Dieses Feld ist erforderlich."
  const validationSchema = Yup.object({
    user: Yup.string().required(no_value_error),
    password: Yup.string().required(no_value_error),
    type: Yup.number()
  })
  const classes = loginFormStyle();
  return(
    <Formik 
      initialValues={{
        user: "",
        password: "",
        type: params.type
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        setWronglogin(false);
        let url = "/api/v1/userarea";
        if(values.type === 1){
          url += "/logineigentumer";
        } else {
          url += "/logindaten";
        }
        let params = new FormData();
        params.append('username', values.user);
        params.append('password', values.password);
        fetch(url, {method: "POST", body: params})
          .then(response => {
            if(response.ok){
              setSubmitting(false);
              if(values.type === 1){
                changePage("/user-eigentuemerportal")
              } else if(values.type === 2){
                changePage("/user-datenraum")
              }
            } else {
              setWronglogin(true);
              setSubmitting(false);
            }
          })
          .catch(_ => {
            setWronglogin(true);
            setSubmitting(false);
          })
      }}
    >
      <Form className={classes.root}>
        <Field component={TextField} variant="outlined"
          name="user" label="Benutzername*" fullWidth={true}
        />
        <Field component={TextField} variant="outlined" type="password"
          name="password" label="Passwort*" fullWidth={true}
        />
        <div className="button-right">
          {wronglogin && 
            <p className="error-msg">Benutzername oder Passwort falsch.</p>
          }
          <button type="submit">Login.</button>
        </div>
      </Form>
    </Formik>  
  )
}

function DatenraumLogin(params) {
  return (
    <HomeCard title="Datenraum." slogan="Alle Dokumente für Ihren Kaufentscheid."
      text="Sämtliche relevanten Informationen zum Kaufobjekt stehen im Datenraum für Ihren Kaufentscheid zur Verfügung."
      login={true} login_type={2}/>
  );
}

function EigentuemerLogin(params) {
  return (
    <HomeCard title="Eigentümerportal." slogan="Sie haben den Durchblick."
      text="Sämtliche relevanten Informationen zur Ihrer Immobilie stehen Ihnen im Kundenportal jederzeit zur Verfügung."
      login={true} login_type={1}/>
  );
}

function PasswordChange(params) {
  return (
    <HomeCard title="Passwort ändern."
      text="Wählen Sie ein neues Passwort. Das Passwort muss mindestens 1 Zahl, 1 Sonderzeichen, kleine und grosse Buchstaben beinhalten und mindestens 8 Zeichen lang sein."
      login={true} login_type={3}/>
  )
}

function AdminLogin(params) {
  const [wrongPW, setWrongPW] = React.useState(false)
  const [waitingMail, setWaitingMail] = React.useState(false);
  const [wrongCode, setWrongCode] = React.useState(false);
  const no_value_error = "Dieses Feld ist erforderlich."
  const wrong_value_error = "Ungültige Eingabe."
  const validationSchema = Yup.object({
    user: Yup.string().email(wrong_value_error).required(no_value_error),
    password: Yup.string().required(no_value_error),
    code: Yup.string(),
  })
  const classes = loginFormStyle();
  return(
    <Formik 
      initialValues={{
        user: "",
        password: "",
        code: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        setWrongPW(false);
        let url = "/api/v1/userarea/loginadmin";
        let params = new FormData();
        params.append('username', values.user);
        params.append('password', values.password);
        params.append('code', values.code);
        fetch(url, {method: "POST", body: params})
          .then(response => {
            if(!response.ok){
              if(waitingMail){
                setWrongCode(true);
              } else {
                setWrongPW(true);
              }
              throw Error("Invalid credentials");
            }
            return response;
            })
          .then(resp => resp.json())
          .then(data => {
            if(data["status"] === "1"){
              setWaitingMail(true);
              setWrongPW(false);
            } else if (data["status"] === "0"){
              changePage("/admin");
            }
            setSubmitting(false);
          })
          .catch(_ => {
            setSubmitting(false);
          })
      }}
    > 
    <>
      <Form className={classes.root}>
        <Field component={TextField} variant="outlined"
          name="user" label="Benutzername*" fullWidth={true}
        />
        <Field component={TextField} variant="outlined" type="password"
          name="password" label="Passwort*" fullWidth={true}
        />
        { waitingMail &&
          <Field component={TextField} variant="outlined"
            name="code" label="Code*" fullWidth={true}
          />
        }
        <div className="button-right">
          {wrongPW && 
            <p className="error-msg">Passwort nicht korrekt.</p>
          }
          {wrongCode && 
            <p className="error-msg">Code nicht korrekt.</p>
          }
          {!waitingMail &&
            <button type="submit">Login.</button>
          }
          {waitingMail &&
            <button type="submit">Code bestätigen.</button>
          }
        </div>
      </Form>
    </>
    </Formik>  
  )
}

/* --------------------------------- LOGIN_PAGES --------------------------------- */

/* ================================= SUCHAUFTRAG_FORM ================================= */

const suchauftragFormStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    paddingLeft: '15%',
    paddingRight: '15%',
    textAlign: 'left',
    '& > div' : {
      margin: theme.spacing(2),
      minWidth: '300px',
      maxWidth: '350px',
    },
    '& > div > *': {
      margin: theme.spacing(1),
    },
    '& p': {
      textAlign: 'left',
    },
    '& button': {
      cursor: 'pointer',
      border: 'none',
      fontWeight: 'bold',
      fontFamily: 'ArgentumSans-Light',
      fontSize: fontSizes['text-button'],
      color: colors['white'],
      height: '70px',
      width: '270px',
      maxWidth: '100%',
      backgroundColor: colors['orange'],
    },
    '& button:hover': {
      color: colors['black'],
    },
    '& .sa-title': {
      color: colors['orange'],
      fontSize: fontSizes['text-normal'],
    },
    '& .button-right': {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'flex-end',
      paddingTop: '20px',
    }
  },
  subdiv: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-evenly',
    '& > *': {
      marginTop: theme.spacing(1),
    }
  },
  middle: {
    maxWidth: '47.5%',
  },
  short: {
    maxWidth: '20%',
  },
  long: {
    maxWidth: '75%',
  },
  label: {
    fontSize: "10px",
  }
}))

function SuchFormResponse(params) {
  return(
    <div className="popupfield" onClick={params.function}>
      <div className="popupcontent">
        <div className="sa-popupcontainer">
          <div className="popupicon">
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <p className="popuptext">
            <span className="sa-response-title">Sehr geehrte Damen und Herren</span>
            <br></br>
            <br></br>
            <span className="sa-response-text">Wir bedanken uns für Ihren erteilten Suchauftrag. Sobald das für Sie passende Objekt in unserem Portfolio vorhanden ist, informieren wir Sie gerne exklusiv, kostenlos und unverbindlich per E-Mail.</span>
            <br></br>
            {params.response['extended'] &&
            <>
              <span className="sa-response-extended">Sie möchten uns ebenfalls einen Auftrag erteilen, über unser eigenes Immobilienportfolio hinaus die passende Immobilie für Sie zu finden. Gerne kontaktieren wir Sie für die Besprechung des weiteren Vorgehens sowie Unterbreitung einer massgeschneiderten Offerte.</span>
              <br></br>
            </>
            }
            <br></br>
            <span className="sa-response-text">Ihre Suchkriterien haben Sie wie folgt definiert:</span>
            <br></br>
            {params.response['kauf'] &&
              <span className="sa-response-category">Kategorie: Kaufen</span>
            }
            {!params.response['kauf'] &&
              <span className="sa-response-category">Kategorie: Mieten</span>
            }
            <br></br>
            {params.response["1"] && 
              <>
              <span className="sa-response-type">Typ: Wohnung</span>
              <br></br>
                {params.response["1_content"].map(function(value) {
                  return(
                    <>
                    <span className="sa-response-items">{value}</span><br></br>
                    </>
                  )
                })}
              </>
            }
            {params.response["2"] && 
              <>
              <span className="sa-response-type">Typ: Einfamilienhaus</span>
              <br></br>
                {params.response["2_content"].map(function(value) {
                  return(
                    <>
                    <span className="sa-response-items">{value}</span><br></br>
                    </>
                  )
                })}
              </>
            }
            {params.response["3"] && 
              <>
              <span className="sa-response-type">Typ: Möblierte Wohnung</span>
              <br></br>
                {params.response["3_content"].map(function(value) {
                  return(
                    <>
                    <span className="sa-response-items">{value}</span><br></br>
                    </>
                  )
                })}
              </>
            }
            {params.response["4"] && 
              <>
              <span className="sa-response-type">Typ: Aussen-Parkplatz</span>
              <br></br>
                {params.response["4_content"].map(function(value) {
                  return(
                    <>
                    <span className="sa-response-items">{value}</span><br></br>
                    </>
                  )
                })}
              </>
            }
            {params.response["5"] && 
              <>
              <span className="sa-response-type">Typ: Auto-Einstellplatz</span>
              <br></br>
                {params.response["5_content"].map(function(value) {
                  return(
                    <>
                    <span className="sa-response-items">{value}</span><br></br>
                    </>
                  )
                })}
              </>
            }
            {params.response["6"] && 
              <>
              <span className="sa-response-type">Typ: Bastelraum/Lagerraum/Aufenthaltsraum</span>
              <br></br>
                {params.response["6_content"].map(function(value) {
                  return(
                    <>
                    <span className="sa-response-items">{value}</span><br></br>
                    </>
                  )
                })}
              </>
            }
            {params.response["7"] && 
              <>
              <span className="sa-response-type">Typ: Gewerbe</span>
              <br></br>
                {params.response["7_content"].map(function(value) {
                  return(
                    <>
                    <span className="sa-response-items">{value}</span><br></br>
                    </>
                  )
                })}
              </>
            }
            {params.response["kauf"] && params.response["10"] && 
              <>
              <span className="sa-response-type">Typ: Bauland</span>
              <br></br>
                {params.response["10_content"].map(function(value) {
                  return(
                    <>
                    <span className="sa-response-items">{value}</span><br></br>
                    </>
                  )
                })}
              </>
            }
            {params.response["kauf"] && params.response["11"] && 
              <>
              <span className="sa-response-type">Typ: Mehrfamilienhaus</span>
              <br></br>
                {params.response["11_content"].map(function(value) {
                  return(
                    <>
                    <span className="sa-response-items">{value}</span><br></br>
                    </>
                  )
                })}
              </>
            }
            <br></br>
            <span className="sa-response-text">Haben Sie Ihr Traumobjekt bereits gefunden und möchten nicht mehr informiert werden? Wir freuen uns über Ihre Rückmeldung und Ihre Kontaktdaten werden umgehend gelöscht.</span>
          </p>
        </div>
      </div>
    </div>
  )
}

function SuchForm(params) {
  const [showResponse, setShowResponse] = React.useState(false);
  const [response, setResponse] = React.useState({});
  const [kaufmiet, setKaufmiet] = React.useState("");
  const [type, setType] = React.useState([true, false, false, false, false, false, false, false, false, false, false, false]);
  const [k123nrzimmer, setK123nrzimmer] = React.useState([1, 10])
  const [k123area, setK123area] = React.useState([1,800])
  const [k123price, setK123price] = React.useState([1,10000000])
  const [k45platz, setK45platz] = React.useState([1, 10])
  const [k45price, setK45price] = React.useState([1, 100000])
  const [k6area, setK6area] = React.useState([1,300])
  const [k6price, setK6price] = React.useState([1, 100000])
  const [k7area, setK7area] = React.useState([1,500])
  const [k7price, setK7price] = React.useState([1, 10000000])
  const [k10area, setK10area] = React.useState([1,5000])
  const [k10price, setK10price] = React.useState([1, 10000000])
  const [k11price, setK11price] = React.useState([1, 100000000])
  const [m123nrzimmer, setM123nrzimmer] = React.useState([1, 10])
  const [m123area, setM123area] = React.useState([1,800])
  const [m123price, setM123price] = React.useState([1,20000])
  const [m45platz, setM45platz] = React.useState([1, 10])
  const [m45price, setM45price] = React.useState([1, 1000])
  const [m6area, setM6area] = React.useState([1,300])
  const [m6price, setM6price] = React.useState([1, 100000])
  const [m7area, setM7area] = React.useState([1,500])
  const [m7price, setM7price] = React.useState([1, 50000])
  function updateKaufmiet(new_value){
    if(new_value !== kaufmiet){
      setType([true, false, false, false, false, false, false, false, false, false, false, false])
      setKaufmiet(new_value)
    }
  }
  function editLabel(value){
    let stringVal = value.toString()
    let valLength = stringVal.length;
    if(valLength > 9){
      stringVal = stringVal.substring(0, valLength-9) + "'" + stringVal.substring(valLength-9)
      valLength += 1;
    }
    if(valLength > 6){
      stringVal = stringVal.substring(0, valLength-6) + "'" + stringVal.substring(valLength-6)
      valLength += 1;
    }
    if(valLength > 3){
      stringVal = stringVal.substring(0, valLength-3) + "'" + stringVal.substring(valLength-3)
    }
    return stringVal;
  }
  function updateType(idx, new_value){
    const new_type = [...type]
    new_type[idx] = new_value
    if(new_value && idx !== 0){
      new_type[0] = false
    }
    if(!new_value && idx !== 0){
      new_type[0] = new_type.slice(1).every(e => !e)
    }
    setType(new_type)
  }
  let error_msg = "Dieses Feld ist erforderlich."
  let no_option_selected_err = "Bitte wählen Sie ein Feld aus."
  let invalid_msg = "Ungültige Eingabe"
  const phoneRegExp = /((\+[0-9]{11})|([0-9]{10})|(\+[0-9]{2} [0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2})|([0-9]{3} [0-9]{3}\[0-9]{2} [0-9]{2}))$/
  const erstzweitmap = {
    "erst": "Erstwohnsitz",
    "zweit": "Zweitwohnsitz",
  }
  const k45_nutzungmap = {
    "Kleinwagen" : "Kleinwagen",
    "Motorrad" : "Motorrad",
    "Minivan": "Minivan/Kleintransporter",
    "Wohnwagen": "Wohnwagen",
    "Wohnmobil": "Wohnmobil",
  }
  const k7_nutzungmap = {
    "buero" : "Büro",
    "laden": "Ladenfläche",
    "werkstatt": "Werkstatt",
  }
  const k10_nutzungmap = {
    "Wohnen" : "Wohnen",
    "Industrie": "Industrie",
    "Gewerbe": "Gewerbe",
    "Misch": "Gemischte Nutzung",
  }

  const validationSchema = Yup.object({
    anrede: Yup.string().max(30, "Maximal 30 Buchstaben"),
    titel: Yup.string().max(30, "Maximal 30 Buchstaben"),
    firstName: Yup.string().max(30, "Maximal 30 Buchstaben")
      .required(error_msg),
    lastName: Yup.string().max(30, "Maximal 30 Buchstaben")
      .required(error_msg),
    email: Yup.string().email("Ungültige E-Mail Adresse")
      .required(error_msg),
    phone: Yup.string().matches(phoneRegExp, "Ungültige Telefonnummer"),
    street: Yup.string().max(30, "Maximal 30 Buchstaben"),
    house_nr: Yup.number(),
    plz: Yup.number().min(1000, invalid_msg).max(9999, invalid_msg),
    ort: Yup.string().max(40, "Maximal 40 Buchstaben"),
    ort_search: Yup.string().max(40, "Maximal 40 Buchstaben"),
    radius: Yup.number(),
    kanton: Yup.string().max(30, "Maximal 30 Buchstaben")
      .required(error_msg),
    erst: Yup.string()
      .when("firstName", {
        is: (val) => ((type[1] || type[2]|| type[3])),
        then: Yup.string()
          .oneOf(
            ["erst", "zweit"],
            no_option_selected_err
          ).required(error_msg)
      }),
      k123_message: Yup.string(),
      k45_message: Yup.string(),
      k6_nutzung: Yup.string().max(100, "Maximal 100 Buchstaben"),
      k6_message: Yup.string(),
      k7_message: Yup.string(),
      k10_message: Yup.string(),
      k11_message: Yup.string(),
      m123_message: Yup.string(),
      m45_message: Yup.string(),
      m6_nutzung: Yup.string().max(100, "Maximal 100 Buchstaben"),
      m6_message: Yup.string(),
      m7_message: Yup.string(),
  })

  const classes = suchauftragFormStyle();

  return (
    <>
    <Formik
      initialValues={{
        anrede: "",
        titel: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        street: "",
        house_nr: "",
        plz: "",
        ort: "",
        ort_search: "",
        radius: "",
        kanton: "",
        erst: "",
        k123_message: "",
        k45_nutzung: "",
        k45_message: "",
        k6_nutzung: "",
        k6_message: "",
        k7_nutzung: "",
        k7_message: "",
        k10_nutzung: "",
        k10_message: "",
        k11_nutzung: "",
        k11_message: "",
        m123_message: "",
        m45_nutzung: "",
        m45_message: "",
        m6_nutzung: "",
        m6_message: "",
        m7_nutzung: "",
        m7_message: "",
        extended: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        let new_response = {}
        let target = "fabio.deflorin@deflorin-ag.ch"
        let source = "server@deflorin-ag.ch"
        let subject = "Neuer Suchauftrag von " + values.firstName + " " + values.lastName
        let content = "<h1>Hallo Fabio</h1><p>Du hast einen neuen Suchauftrag erhalten:<p>"
        content += "<h2>Kontaktdaten</h2>"
        content += "<p>Anrede: " + values.anrede + "<br></br>"
        content += "Titel: " + values.titel + "<br></br>"
        content += "Vorname: " + values.firstName + "<br></br>"
        content += "Nachname: " + values.lastName + "<br></br>"
        content += "E-Mail: " + values.email + "<br></br>"
        content += "Telefon: " + values.phone + "<br></br>"
        content += "Adresse 1: " + values.street + " " + values.house_nr + "<br></br>"
        content += "Adresse 2: " + values.plz + " " + values.ort + "</p>"
        content += "<h2>Suchangaben</h2>"
        if(values.extended.includes("yes")){
          content += "<h3>Erweiterter Suchauftrag: JA</h3>"
          new_response["extended"] = true
        } else {
          content += "<h3>Erweiterter Suchauftrag: NEIN</h3>"
          new_response["extended"] = false
        }
        content += "<p>Gemeinde: " + values.ort_search + "<br></br>"
        content += "Suchumkreis: " + values.radius + "<br></br>"
        content += "Kanton: " + values.kanton + "</p>"
        if(kaufmiet === "kauf"){
          new_response["kauf"] = true
          content += "<h2>Kategorie: Kaufen</h2>"
          if(type[1]){
            new_response["1"] = true
            content += "<h3>Typ: Wohnung</h3>"
            content += "<p>Anzahl Zimmer: " + k123nrzimmer[0] + "-" + k123nrzimmer[1] + "<br></br>"
            content += "Wohnfläche: " + k123area[0] + "-" + k123area[1] + "<br></br>"
            content += "Erst-/Zweitwohnung: " + erstzweitmap[values.erst] + "<br></br>"
            content += "Kaufpreis: " + k123price[0] + "-" + k123price[1] + "<br></br>"
            content += "Anmerkungen: " + values.k123_message + "</p>"
            new_response["1_content"] = [
              "Anzahl Zimmer: " + k123nrzimmer[0] + "-" + k123nrzimmer[1],
              "Wohnfläche: " + k123area[0] + "-" + k123area[1],
              "Erst-/Zweitwohnung: " + erstzweitmap[values.erst],
              "Kaufpreis: " + k123price[0] + "-" + k123price[1],
              "Anmerkungen: " + values.k123_message
            ]
          } else {
            new_response["1"] = false
          }
          if(type[2]){
            new_response["2"] = true
            content += "<h3>Typ: Einfamilienhaus</h3>"
            content += "<p>Anzahl Zimmer: " + k123nrzimmer[0] + "-" + k123nrzimmer[1] + "<br></br>"
            content += "Wohnfläche: " + k123area[0] + "-" + k123area[1] + "<br></br>"
            content += "Erst-/Zweitwohnung: " + erstzweitmap[values.erst] + "<br></br>"
            content += "Kaufpreis: " + k123price[0] + "-" + k123price[1] + "<br></br>"
            content += "Anmerkungen: " + values.k123_message + "</p>"
            new_response["2_content"] = [
              "Anzahl Zimmer: " + k123nrzimmer[0] + "-" + k123nrzimmer[1],
              "Wohnfläche: " + k123area[0] + "-" + k123area[1],
              "Erst-/Zweitwohnung: " + erstzweitmap[values.erst],
              "Kaufpreis: " + k123price[0] + "-" + k123price[1],
              "Anmerkungen: " + values.k123_message
            ]
          } else {
            new_response["2"] = false
          }
          if(type[3]){
            new_response["3"] = true
            content += "<h3>Typ: Möblierte Wohnung</h3>"
            content += "<p>Anzahl Zimmer: " + k123nrzimmer[0] + "-" + k123nrzimmer[1] + "<br></br>"
            content += "Wohnfläche: " + k123area[0] + "-" + k123area[1] + "<br></br>"
            content += "Erst-/Zweitwohnung: " + erstzweitmap[values.erst] + "<br></br>"
            content += "Kaufpreis: " + k123price[0] + "-" + k123price[1] + "<br></br>"
            content += "Anmerkungen: " + values.k123_message + "</p>"
            new_response["3_content"] = [
              "Anzahl Zimmer: " + k123nrzimmer[0] + "-" + k123nrzimmer[1],
              "Wohnfläche: " + k123area[0] + "-" + k123area[1],
              "Erst-/Zweitwohnung: " + erstzweitmap[values.erst],
              "Kaufpreis: " + k123price[0] + "-" + k123price[1],
              "Anmerkungen: " + values.k123_message
            ]
          }else {
            new_response["3"] = false
          }
          if(type[4]){
            new_response["4"] = true 
            content += "<h3>Typ: Aussen-Parkplatz</h3>"
            content += "<p>Anzahl Parkplätze: " + k45platz[0] + "-" + k45platz[1] + "<br></br>"
            content += "Nutzung: "
            content += values.k45_nutzung.map(x => k45_nutzungmap[x]).join("/")
            content += "<br></br>"
            content += "Kaufpreis: " + k45price[0] + "-" + k45price[1] + "<br></br>"
            content += "Anmerkungen: " + values.k45_message + "</p>"
            new_response["4_content"] = [
              "Anzahl Parkplätze: " + k45platz[0] + "-" + k45platz[1],
              "Nutzung: "+ values.k45_nutzung.map(x => k45_nutzungmap[x]).join("/"),
              "Kaufpreis: " + k45price[0] + "-" + k45price[1],
              "Anmerkungen: " + values.k45_message
            ]
          }else {
            new_response["4"] = false
          }
          if(type[5]){
            new_response["5"] = true 
            content += "<h3>Typ: Auto-Einstellplatz</h3>"
            content += "<p>Anzahl Parkplätze: " + k45platz[0] + "-" + k45platz[1] + "<br></br>"
            content += "Nutzung: "
            content += values.k45_nutzung.map(x => k45_nutzungmap[x]).join("/")
            content += "<br></br>"
            content += "Kaufpreis: " + k45price[0] + "-" + k45price[1] + "<br></br>"
            content += "Anmerkungen: " + values.k45_message + "</p>"
            new_response["5_content"] = [
              "Anzahl Parkplätze: " + k45platz[0] + "-" + k45platz[1],
              "Nutzung: "+ values.k45_nutzung.map(x => k45_nutzungmap[x]).join("/"),
              "Kaufpreis: " + k45price[0] + "-" + k45price[1],
              "Anmerkungen: " + values.k45_message
            ]
          }else {
            new_response["5"] = false
          }
          if(type[6]){
            new_response["6"] = true
            content += "<h3>Typ: Bastelraum/Lagerraum/Aufenthaltsraum</h3>"
            content += "<p>Fläche: " + k6area[0] + "-" + k6area[1] + "<br></br>"
            content += "Nutzung: " + values.k6_nutzung + "<br></br>"
            content += "Kaufpreis: " + k6price[0] + "-" + k6price[1] + "<br></br>"
            content += "Anmerkungen: " + values.k6_message + "</p>"
            new_response["6_content"] = [
              "Fläche: " + k6area[0] + "-" + k6area[1],
              "Nutzung: " + values.k6_nutzung,
              "Kaufpreis: " + k6price[0] + "-" + k6price[1],
              "Anmerkungen: " + values.k6_message
            ]
          } else {
            new_response["6"] = false
          }
          if(type[7]){
            new_response["7"] = true
            content += "<h3>Typ: Gewerbe</h3>"
            content += "<p>Fläche: " + k7area[0] + "-" + k7area[1] + "<br></br>"
            content += "Nutzung: "
            content += values.k7_nutzung.map(x => k7_nutzungmap[x]).join("/")
            content += "<br></br>"
            content += "Kaufpreis: " + k7price[0] + "-" + k7price[1] + "<br></br>"
            content += "Anmerkungen: " + values.k7_message + "</p>"
            new_response["7_content"] = [
              "Fläche: " + k7area[0] + "-" + k7area[1],
              "Nutzung: " + values.k7_nutzung.map(x => k7_nutzungmap[x]).join("/"),
              "Kaufpreis: " + k7price[0] + "-" + k7price[1],
              "Anmerkungen: " + values.k7_message
            ]
          } else {
            new_response["7"] = false
          }
          if(type[10]){
            new_response["10"] = true
            content += "<h3>Typ: Bauland</h3>"
            content += "<p>Fläche: " + k10area[0] + "-" + k10area[1] + "<br></br>"
            content += "Nutzung: "
            content += values.k10_nutzung.map(x => k10_nutzungmap[x]).join("/")
            content += "<br></br>"
            content += "Kaufpreis: " + k10price[0] + "-" + k10price[1] + "<br></br>"
            content += "Anmerkungen: " + values.k10_message + "</p>"
            new_response["10_content"] = [
              "Fläche: " + k10area[0] + "-" + k10area[1],
              "Nutzung: " + values.k10_nutzung.map(x => k10_nutzungmap[x]).join("/"),
              "Kaufpreis: " + k10price[0] + "-" + k10price[1],
              "Anmerkungen: " + values.k10_message
            ]
          } else {
            new_response["10"] = false
          }
          if(type[11]){
            new_response["11"] = true
            content += "<h3>Typ: Mehrfamilienhaus</h3>"
            content += "Nutzung: "
            content += values.k11_nutzung.map(x => k10_nutzungmap[x]).join("/")
            content += "<br></br>"
            content += "Kaufpreis: " + k11price[0] + "-" + k11price[1] + "<br></br>"
            content += "Anmerkungen: " + values.k11_message + "</p>"
            new_response["11_content"] = [
              "Nutzung: " + values.k11_nutzung.map(x => k10_nutzungmap[x]).join("/"),
              "Kaufpreis: " + k11price[0] + "-" + k11price[1],
              "Anmerkungen: " + values.k11_message
            ]
          } else {
            new_response["11"] = false
          }
        }
        if(kaufmiet === "miet"){
          new_response["kauf"] = false
          content += "<h2>Kategorie: Mieten</h2>"
          if(type[1]){
            new_response["1"] = true
            content += "<h3>Typ: Wohnung</h3>"
            content += "<p>Anzahl Zimmer: " + m123nrzimmer[0] + "-" + m123nrzimmer[1] + "<br></br>"
            content += "Wohnfläche: " + m123area[0] + "-" + m123area[1] + "<br></br>"
            content += "Erst-/Zweitwohnung: " + erstzweitmap[values.erst] + "<br></br>"
            content += "Mietzins: " + m123price[0] + "-" + m123price[1] + "<br></br>"
            content += "Anmerkungen: " + values.m123_message + "</p>"
            new_response["1_content"] = [
              "Anzahl Zimmer: " + m123nrzimmer[0] + "-" + m123nrzimmer[1],
              "Wohnfläche: " + m123area[0] + "-" + m123area[1],
              "Erst-/Zweitwohnung: " + erstzweitmap[values.erst],
              "Kaufpreis: " + m123price[0] + "-" + m123price[1],
              "Anmerkungen: " + values.m123_message
            ]
          } else {
            new_response["1"] = false
          }
          if(type[2]){
            new_response["2"] = true
            content += "<h3>Typ: Einfamilienhaus</h3>"
            content += "<p>Anzahl Zimmer: " + m123nrzimmer[0] + "-" + m123nrzimmer[1] + "<br></br>"
            content += "Wohnfläche: " + m123area[0] + "-" + m123area[1] + "<br></br>"
            content += "Erst-/Zweitwohnung: " + erstzweitmap[values.erst] + "<br></br>"
            content += "Mietzins: " + m123price[0] + "-" + m123price[1] + "<br></br>"
            content += "Anmerkungen: " + values.m123_message + "</p>"
            new_response["2_content"] = [
              "Anzahl Zimmer: " + m123nrzimmer[0] + "-" + m123nrzimmer[1],
              "Wohnfläche: " + m123area[0] + "-" + m123area[1],
              "Erst-/Zweitwohnung: " + erstzweitmap[values.erst],
              "Kaufpreis: " + m123price[0] + "-" + m123price[1],
              "Anmerkungen: " + values.m123_message
            ]
          } else {
            new_response["2"] = false
          }
          if(type[3]){
            new_response["3"] = true
            content += "<h3>Typ: Möblierte Wohnung</h3>"
            content += "<p>Anzahl Zimmer: " + m123nrzimmer[0] + "-" + m123nrzimmer[1] + "<br></br>"
            content += "Wohnfläche: " + m123area[0] + "-" + m123area[1] + "<br></br>"
            content += "Erst-/Zweitwohnung: " + erstzweitmap[values.erst] + "<br></br>"
            content += "Mietzins: " + m123price[0] + "-" + m123price[1] + "<br></br>"
            content += "Anmerkungen: " + values.m123_message + "</p>"
            new_response["3_content"] = [
              "Anzahl Zimmer: " + m123nrzimmer[0] + "-" + m123nrzimmer[1],
              "Wohnfläche: " + m123area[0] + "-" + m123area[1],
              "Erst-/Zweitwohnung: " + erstzweitmap[values.erst],
              "Kaufpreis: " + m123price[0] + "-" + m123price[1],
              "Anmerkungen: " + values.m123_message
            ]
          } else {
            new_response["3"] = false
          }
          if(type[4]){
            new_response["4"] = true 
            content += "<h3>Typ: Aussen-Parkplatz</h3>"
            content += "<p>Anzahl Parkplätze: " + m45platz[0] + "-" + m45platz[1] + "<br></br>"
            content += "Nutzung: "
            content += values.m45_nutzung.map(x => k45_nutzungmap[x]).join("/")
            content += "<br></br>"
            content += "Mietzins: " + m45price[0] + "-" + m45price[1] + "<br></br>"
            content += "Anmerkungen: " + values.m45_message + "</p>"
            new_response["4_content"] = [
              "Anzahl Parkplätze: " + m45platz[0] + "-" + m45platz[1],
              "Nutzung: "+ values.m45_nutzung.map(x => k45_nutzungmap[x]).join("/"),
              "Kaufpreis: " + m45price[0] + "-" + m45price[1],
              "Anmerkungen: " + values.m45_message
            ]
          } else {
            new_response["4"] = false
          }
          if(type[5]){
            new_response["5"] = true 
            content += "<h3>Typ: Auto-Einstellplatz</h3>"
            content += "<p>Anzahl Parkplätze: " + m45platz[0] + "-" + m45platz[1] + "<br></br>"
            content += "Nutzung: "
            content += values.m45_nutzung.map(x => k45_nutzungmap[x]).join("/")
            content += "<br></br>"
            content += "Mietzins: " + m45price[0] + "-" + m45price[1] + "<br></br>"
            content += "Anmerkungen: " + values.m45_message + "</p>"
            new_response["5_content"] = [
              "Anzahl Parkplätze: " + m45platz[0] + "-" + m45platz[1],
              "Nutzung: "+ values.m45_nutzung.map(x => k45_nutzungmap[x]).join("/"),
              "Kaufpreis: " + m45price[0] + "-" + m45price[1],
              "Anmerkungen: " + values.m45_message
            ]
          } else {
            new_response["5"] = false
          }
          if(type[6]){
            new_response["6"] = true
            content += "<h3>Typ: Bastelraum/Lagerraum/Aufenthaltsraum</h3>"
            content += "<p>Fläche: " + m6area[0] + "-" + m6area[1] + "<br></br>"
            content += "Nutzung: " + values.m6_nutzung + "<br></br>"
            content += "Mietzins: " + m6price[0] + "-" + m6price[1] + "<br></br>"
            content += "Anmerkungen: " + values.m6_message + "</p>"
            new_response["6_content"] = [
              "Fläche: " + m6area[0] + "-" + m6area[1],
              "Nutzung: " + values.m6_nutzung,
              "Kaufpreis: " + m6price[0] + "-" + m6price[1],
              "Anmerkungen: " + values.m6_message
            ]
          } else {
            new_response["6"] = false
          }
          if(type[7]){
            new_response["7"] = true
            content += "<h3>Typ: Gewerbe</h3>"
            content += "<p>Fläche: " + m7area[0] + "-" + m7area[1] + "<br></br>"
            content += "Nutzung: "
            content += values.m7_nutzung.map(x => k7_nutzungmap[x]).join("/")
            content += "<br></br>"
            content += "Mietzins: " + m7price[0] + "-" + m7price[1] + "<br></br>"
            content += "Anmerkungen: " + values.m7_message + "</p>"
            new_response["7_content"] = [
              "Fläche: " + m7area[0] + "-" + m7area[1],
              "Nutzung: " + values.m7_nutzung.map(x => k7_nutzungmap[x]).join("/"),
              "Kaufpreis: " + m7price[0] + "-" + m7price[1],
              "Anmerkungen: " + values.m7_message
            ]
          } else {
            new_response["7"] = false
          }
        }
        sendMail(target, source, subject, content)
          .then(setSubmitting(false))
          .then(setResponse(new_response))
          .then(setShowResponse(true))
          .catch((err) => {console.log(err)})
      }}
    >
    {props => (
      <Form className={classes.root}>
        <div>
          <p className="sa-title">Kontaktdaten</p>
          <div className={classes.subdiv}>
            <Field component={TextField} variant="outlined" className={classes.middle}
              name="anrede" label="Anrede" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" className={classes.middle}
              name="titel" label="Titel" fullWidth={true}
            />
          </div>
          <Field component={TextField} variant="outlined" 
            name="firstName" label="Vorname*" fullWidth={true}
          />
          <Field component={TextField} variant="outlined" 
            name="lastName" label="Nachname*" fullWidth={true}
          />
          <Field component={TextField} variant="outlined" type="email"
            name="email" label="E-Mail*" fullWidth={true}
          />
          <Field component={TextField} variant="outlined" 
            name="phone" label="Telefon" fullWidth={true}
          />
          <div className={classes.subdiv}>
            <Field component={TextField} variant="outlined" className={classes.long}
              name="street" label="Strasse" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" className={classes.short}
              name="house_nr" label="Nr." fullWidth={true}
            />
          </div>
          <div className={classes.subdiv}>
            <Field component={TextField} variant="outlined" className={classes.short}
              name="plz" label="PLZ" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" className={classes.long}
              name="ort" label="Ort" fullWidth={true}
            />
          </div>
        </div>
        <div>
          <p className="sa-title">Angaben zur Suche</p>
          <Field component={TextField} variant="outlined" 
            name="ort_search" label="Gemeinde" fullWidth={true}
          />
          <Field component={TextField} variant="outlined" 
            name="radius" label="Suchumkreis maximal" fullWidth={true}
            InputProps={{endAdornment: <InputAdornment position="end">km</InputAdornment>}}
          />
          <Field component={TextField} variant="outlined" 
            name="kanton" label="Kanton*" fullWidth={true}
          />
          <p className="sa-title">Kategorie</p>
          <div className="flex-row sa-button-box">
            {kaufmiet === "kauf" && 
              <BigButton text="Kaufen" function={() => updateKaufmiet("")}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {kaufmiet !== "kauf" && 
              <BigButton text="Kaufen" function={() => updateKaufmiet("kauf")}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
            {kaufmiet === "miet" &&
              <BigButton text="Mieten" function={() => updateKaufmiet("")}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {kaufmiet !== "miet" &&
              <BigButton text="Mieten" function={() => updateKaufmiet("miet")}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
          </div>
          <div className="flex-column sa-button-box">
            {type[1] && kaufmiet !== "" &&
              <BigButton text="Wohnung" function={() => updateType(1, false)}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {!type[1] && kaufmiet !== "" &&
              <BigButton text="Wohnung" function={() => updateType(1, true)}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
            {type[2] && kaufmiet !== "" &&
              <BigButton text="Einfamilienhaus" function={() => updateType(2, false)}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {!type[2] && kaufmiet !== "" &&
              <BigButton text="Einfamilienhaus" function={() => updateType(2, true)}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
            {type[3] && kaufmiet !== "" &&
              <BigButton text="Möblierte Wohnung" function={() => updateType(3, false)}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {!type[3] && kaufmiet !== "" &&
              <BigButton text="Möblierte Wohnung" function={() => updateType(3, true)}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
            {type[4] && kaufmiet !== "" &&
              <BigButton text="Aussen-Parkplatz" function={() => updateType(4, false)}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {!type[4] && kaufmiet !== "" &&
              <BigButton text="Aussen-Parkplatz" function={() => updateType(4, true)}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
            {type[5] && kaufmiet !== "" &&
              <BigButton text="Auto-Einstellpatz" function={() => updateType(5, false)}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {!type[5] && kaufmiet !== "" &&
              <BigButton text="Auto-Einstellpatz" function={() => updateType(5, true)}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
            {type[6] && kaufmiet !== "" &&
              <BigButton text="Bastelraum/Lagerraum/Aufenthaltsraum" function={() => updateType(6, false)}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {!type[6] && kaufmiet !== "" &&
              <BigButton text="Bastelraum/Lagerraum/Aufenthaltsraum" function={() => updateType(6, true)}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
            {type[7] && kaufmiet !== "" &&
              <BigButton text="Gewerbe" function={() => updateType(7, false)}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {!type[7] && kaufmiet !== "" &&
              <BigButton text="Gewerbe" function={() => updateType(7, true)}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
            {type[10] && kaufmiet === "kauf" &&
              <BigButton text="Bauland" function={() => updateType(10, false)}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {!type[10] && kaufmiet === "kauf" &&
              <BigButton text="Bauland" function={() => updateType(10, true)}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
            {type[11] && kaufmiet === "kauf" &&
              <BigButton text="Mehrfamilienhaus" function={() => updateType(11, false)}
              selfstyle={true} styling="sa-button text-black background-orange"/>
            }
            {!type[11] && kaufmiet === "kauf" &&
              <BigButton text="Mehrfamilienhaus" function={() => updateType(11, true)}
              selfstyle={true} styling="sa-button text-orange background-grey"/>
            }
          </div>
        </div>
        <div>
          {(type[1] || type[2] || type[3]) && kaufmiet === "kauf" &&
            <>
            <p className="sa-title">Kaufen: Wohnung/Einfamilienhaus/Möblierte Wohnung</p>
            <Typography gutterBottom>
              Anzahl Zimmer
            </Typography>
            <Slider value={k123nrzimmer} onChange={(event, newValue) => setK123nrzimmer(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 10, label: '10'}]}
              min={0} max={10} step={0.5} m={100} sx={{marginTop: 500, width: 10}}
              valueLabelFormat={value => editLabel(value)}
            />
            <Typography gutterBottom>
              Wohnfläche in m<sup>2</sup>
            </Typography>
            <Slider value={k123area} onChange={(event, newValue) => setK123area(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 800, label: '800'}]}
              min={0} max={800} step={1}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" select={true}
              label="Erst-/Zweitwohnsitz*" name="erst" fullWidth={true}
            >
              <MenuItem value="">Bitte auswählen</MenuItem>
              <MenuItem value="erst">Erstwohnsitz</MenuItem>
              <MenuItem value="zweit">Zweitwohnsitz</MenuItem>
            </Field>
            <Typography gutterBottom>
              Kaufpreis in CHF für Wohnobjekt *
            </Typography>
            <Slider value={k123price} onChange={(event, newValue) => setK123price(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 10000000, label: "10'000'000"}]}
              min={0} max={10000000} step={1000}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="k123_message" label="Besondere Wünsche / Anmerkungen / Nennung Neubauprojektname / Nennung spezifische Liegenschaft" multiline rows={5}
            />
            </>
          }
          {(type[4] || type[5]) && kaufmiet === "kauf" &&
            <>
            <p className="sa-title">Kaufen: Aussen-Parkplatz/Auto-Einstellplatz</p>
            <Typography gutterBottom>
              Anzahl Parkplätze
            </Typography>
            <Slider value={k45platz} onChange={(event, newValue) => setK45platz(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 10, label: '10'}]}
              min={0} max={10} step={1}
              valueLabelFormat={value => editLabel(value)}
            />
            <FormControl component="fieldset" style={{ display: "flex" }}>
              <FormLabel component="legend">Nutzug</FormLabel>
              <FormGroup>
                <Field component={CheckboxWithLabel} type="checkbox" name="k45_nutzung" color="primary"
                  key="Kleinwagen" value="Kleinwagen" Label={{label: "Kleinwagen"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k45_nutzung" color="primary"
                  key="Motorrad" value="Motorrad" Label={{label: "Motorrad"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k45_nutzung" color="primary"
                  key="Minivan" value="Minivan" Label={{label: "Minivan/Kleintransporter"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k45_nutzung" color="primary"
                  key="Wohnwagen" value="Wohnwagen" Label={{label: "Wohnwagen"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k45_nutzung" color="primary"
                  key="Wohnmobil" value="Wohnmobil" Label={{label: "Wohnmobil"}}
                />
              </FormGroup>
            </FormControl>
            <Typography gutterBottom>
              Kaufpreis in CHF*
            </Typography>
            <Slider value={k45price} onChange={(event, newValue) => setK45price(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 100000, label: "100'000"}]}
              min={0} max={100000} step={1000}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="k45_message" label="Besondere Wünsche/Anmerkungen" multiline rows={5}
            />
            </>
          }
          {(type[6]) && kaufmiet === "kauf" &&
            <>
            <p className="sa-title">Kaufen: Bastelraum/Lagerraum/Aufenthaltsraum</p>
            <Typography gutterBottom>
              Fläche Bastel-/Lager-/Aufenthalsraum in m<sup>2</sup>
            </Typography>
            <Slider value={k6area} onChange={(event, newValue) => setK6area(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 300, label: '300'}]}
              min={0} max={300} step={1}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="k6_nutzung" label="Nutzung als"
            />
            <Typography gutterBottom>
              Kaufpreis in CHF*
            </Typography>
            <Slider value={k6price} onChange={(event, newValue) => setK6price(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 100000, label: "100'000"}]}
              min={0} max={100000} step={1000}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="k6_message" label="Besondere Wünsche/Anmerkungen" multiline rows={5}
            />
            </>
          }
          {(type[7]) && kaufmiet === "kauf" &&
            <>
            <p className="sa-title">Kaufen: Gewerbe</p>
            <Typography gutterBottom>
              Gewerbefläche in m<sup>2</sup>
            </Typography>
            <Slider value={k7area} onChange={(event, newValue) => setK7area(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 500, label: '500'}]}
              min={0} max={500} step={1}
              valueLabelFormat={value => editLabel(value)}
            />
            <FormControl component="fieldset" style={{ display: "flex" }}>
              <FormLabel component="legend">Nutzugsart für Gewerbe*</FormLabel>
              <FormGroup>
                <Field component={CheckboxWithLabel} type="checkbox" name="k7_nutzung"  color="primary"
                  key="buero" value="buero" Label={{label: "Büro"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k7_nutzung" color="primary"
                  key="laden" value="laden" Label={{label: "Ladenfläche"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k7_nutzung" color="primary"
                  key="werkstatt" value="werkstatt" Label={{label: "Werkstatt"}}
                />
              </FormGroup>
            </FormControl>
            <Typography gutterBottom>
              Kaufpreis in CHF für Gewerbe*
            </Typography>
            <Slider value={k7price} onChange={(event, newValue) => setK7price(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 10000000, label: "10'000'000"}]}
              min={0} max={10000000} step={1000}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="k7_message" label="Besondere Wünsche/Anmerkungen" multiline rows={5}
            />
            </>
          }
          {(type[10]) && kaufmiet === "kauf" &&
            <>
            <p className="sa-title">Kaufen: Bauland</p>
            <Typography gutterBottom>
              Grundstücksfläche in m<sup>2</sup>
            </Typography>
            <Slider value={k10area} onChange={(event, newValue) => setK10area(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 5000, label: '5000'}]}
              min={0} max={5000} step={1}
              valueLabelFormat={value => editLabel(value)}
            />
            <FormControl component="fieldset" style={{ display: "flex" }}>
              <FormLabel component="legend">Nutzugsart für Bauland*</FormLabel>
              <FormGroup>
                <Field component={CheckboxWithLabel} type="checkbox" name="k10_nutzung"  color="primary"
                  key="Wohnen" value="Wohnen" Label={{label: "Wohnen"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k10_nutzung" color="primary"
                  key="Industrie" value="Industrie" Label={{label: "Industrie"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k10_nutzung" color="primary"
                  key="Gewerbe" value="Gewerbe" Label={{label: "Gewerbe"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k10_nutzung" color="primary"
                  key="Misch" value="Misch" Label={{label: "Gemischte Nutzung"}}
                />
              </FormGroup>
            </FormControl>
            <Typography gutterBottom>
              Kaufpreis in CHF für Bauland*
            </Typography>
            <Slider value={k10price} onChange={(event, newValue) => setK10price(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 10000000, label: "10'000'000"}]}
              min={0} max={10000000} step={1000}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="k10_message" label="Besondere Wünsche/Anmerkungen" multiline rows={5}
            />
            </>
          }
          {(type[11]) && kaufmiet === "kauf" &&
            <>
            <p className="sa-title">Kaufen: Mehrfamilienhaus</p>
            <FormControl component="fieldset" style={{ display: "flex" }}>
              <FormLabel component="legend">Nutzugsart für Mehrfamilienhaus*</FormLabel>
              <FormGroup>
                <Field component={CheckboxWithLabel} type="checkbox" name="k11_nutzung" color="primary"
                  key="Wohnen" value="Wohnen" Label={{label: "Wohnen"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k11_nutzung" color="primary"
                  key="Industrie" value="Industrie" Label={{label: "Industrie"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k11_nutzung" color="primary"
                  key="Gewerbe" value="Gewerbe" Label={{label: "Gewerbe"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="k11_nutzung" color="primary"
                  key="Misch" value="Misch" Label={{label: "Gemischte Nutzung"}}
                />
              </FormGroup>
            </FormControl>
            <Typography gutterBottom>
              Kaufpreis in CHF für Mehrfamilienhaus*
            </Typography>
            <Slider value={k11price} onChange={(event, newValue) => setK11price(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 100000000, label: "100'000'000"}]}
              min={0} max={100000000} step={1000}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="k11_message" label="Besondere Wünsche/Anmerkungen" multiline rows={5}
            />
            </>
          }
          {(type[1] || type[2] || type[3]) && kaufmiet === "miet" &&
            <>
            <p className="sa-title">Mieten: Wohnung/Einfamilienhaus/Möblierte Wohnung</p>
            <Typography gutterBottom>
              Anzahl Zimmer
            </Typography>
            <Slider value={m123nrzimmer} onChange={(event, newValue) => setM123nrzimmer(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 10, label: '10'}]}
              min={0} max={10} step={0.5}
              valueLabelFormat={value => editLabel(value)}
            />
            <Typography gutterBottom>
              Wohnfläche in m<sup>2</sup>
            </Typography>
            <Slider value={m123area} onChange={(event, newValue) => setM123area(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 800, label: '800'}]}
              min={0} max={800} step={1}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" select={true}
              label="Erst-/Zweitwohnsitz*" name="erst" fullWidth={true}
            >
              <MenuItem value="">Bitte auswählen</MenuItem>
              <MenuItem value="erst">Erstwohnsitz</MenuItem>
              <MenuItem value="zweit">Zweitwohnsitz</MenuItem>
            </Field>
            <Typography gutterBottom>
              Monatlicher Bruttomietzins in CHF für Wohnobjekt*
            </Typography>
            <Slider value={m123price} onChange={(event, newValue) => setM123price(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 20000, label: "20'000"}]}
              min={0} max={20000} step={100}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="m123_message" label="Besondere Wünsche/Anmerkungen" multiline rows={5}
            />
            </>
          }
          {(type[4] || type[5]) && kaufmiet === "miet" &&
            <>
            <p className="sa-title">Mieten: Aussen-Parkplatz/Auto-Einstellplatz</p>
            <Typography gutterBottom>
              Anzahl Parkplätze
            </Typography>
            <Slider value={m45platz} onChange={(event, newValue) => setM45platz(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 10, label: '10'}]}
              min={0} max={10} step={1}
              valueLabelFormat={value => editLabel(value)}
            />
            <FormControl component="fieldset" style={{ display: "flex" }}>
              <FormLabel component="legend">Nutzug</FormLabel>
              <FormGroup>
                <Field component={CheckboxWithLabel} type="checkbox" name="m45_nutzung" color="primary"
                  key="Kleinwagen" value="Kleinwagen" Label={{label: "Kleinwagen"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="m45_nutzung" color="primary"
                  key="Motorrad" value="Motorrad" Label={{label: "Motorrad"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="m45_nutzung" color="primary"
                  key="Minivan" value="Minivan" Label={{label: "Minivan/Kleintransporter"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="m45_nutzung" color="primary"
                  key="Wohnwagen" value="Wohnwagen" Label={{label: "Wohnwagen"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="m45_nutzung" color="primary"
                  key="Wohnmobil" value="Wohnmobil" Label={{label: "Wohnmobil"}}
                />
              </FormGroup>
            </FormControl>
            <Typography gutterBottom>
              Monatlicher Bruttomietzins in CHF*
            </Typography>
            <Slider value={m45price} onChange={(event, newValue) => setM45price(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 1000, label: "1'000"}]}
              min={0} max={1000} step={10}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="m45_message" label="Besondere Wünsche/Anmerkungen" multiline rows={5}
            />
            </>
          }
          {(type[6]) && kaufmiet === "miet" &&
            <>
            <p className="sa-title">Mieten: Bastelraum/Lagerraum/Aufenthaltsraum</p>
            <Typography gutterBottom>
              Fläche Bastel-/Lager-/Aufenthalsraum in m<sup>2</sup>
            </Typography>
            <Slider value={m6area} onChange={(event, newValue) => setM6area(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 300, label: '300'}]}
              min={0} max={300} step={1}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="m6_nutzung" label="Nutzung als"
            />
            <Typography gutterBottom>
              Monatlicher Bruttomietzins in CHF*
            </Typography>
            <Slider value={m6price} onChange={(event, newValue) => setM6price(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 1000, label: "1'000"}]}
              min={0} max={1000} step={10}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="m6_message" label="Besondere Wünsche/Anmerkungen" multiline rows={5}
            />
            </>
          }
          {(type[7]) && kaufmiet === "miet" &&
            <>
            <p className="sa-title">Mieten: Gewerbe</p>
            <Typography gutterBottom>
              Gewerbefläche in m<sup>2</sup>
            </Typography>
            <Slider value={m7area} onChange={(event, newValue) => setM7area(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 500, label: '500'}]}
              min={0} max={500} step={1}
              valueLabelFormat={value => editLabel(value)}
            />
            <FormControl component="fieldset" style={{ display: "flex" }}>
              <FormLabel component="legend">Nutzugsart für Gewerbe*</FormLabel>
              <FormGroup>
                <Field component={CheckboxWithLabel} type="checkbox" name="m7_nutzung" color="primary"
                  key="buero" value="buero" Label={{label: "Büro"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="m7_nutzung" color="primary"
                  key="laden" value="laden" Label={{label: "Ladenfläche"}}
                />
                <Field component={CheckboxWithLabel} type="checkbox" name="m7_nutzung" color="primary"
                  key="werkstatt" value="werkstatt" Label={{label: "Werkstatt"}}
                />
              </FormGroup>
            </FormControl>
            <Typography gutterBottom>
              Monatlicher Bruttomietzins in CHF für Gewerbe*
            </Typography>
            <Slider value={m7price} onChange={(event, newValue) => setM7price(newValue)} valueLabelDisplay="auto"
              marks={[{value: 0, label: '0'}, {value: 50000, label: "50'000"}]}
              min={0} max={50000} step={100}
              valueLabelFormat={value => editLabel(value)}
            />
            <Field component={TextField} variant="outlined" fullWidth={true}
              name="m7_message" label="Besondere Wünsche/Anmerkungen" multiline rows={5}
            />
            </>
          }
          {!(type.slice(1).every(e => !e)) &&
            <>
            <FormControl component="fieldset" style={{ display: "flex" }}>
              <FormLabel component="legend">Erweiterter Suchauftrag (optional)</FormLabel>
              <FormGroup>
                <Field component={CheckboxWithLabel} type="checkbox" name="extended" color="primary"
                  key="yes" value="yes" Label={ 
                    {style: {fontSize: '10px important!', textAlign: 'left'},
                      label: "Ich möchte einen Auftrag erteilen, bei welchem die DEFLORIN AG über das eigene Immobilienportfolio hinaus aktiv für mich die passende Immobilie sucht. Dieser Auftrag ist kostenpflichtig und wir werden uns gerne vorgängig mit Ihnen in Verbindung setzen für die Zustellung einer unverbindlichen Offerte."}
                  }
                />
              </FormGroup>
            </FormControl>
            <div className="button-right">
              <button type="submit">Absenden.</button>
            </div>
            </>
          }
        </div>
      </Form>
      )}
    </Formik>
    {showResponse &&
      <SuchFormResponse function={() => setShowResponse(false)} response={response} />
    }
    </>
  )
}
/* --------------------------------- SUCHAUFTRAG_FORM --------------------------------- */

/* ================================= HOME ================================= */

async function getSlider(){
  const url = "/api/v1/utils/getSlides";
  let data = await fetch(url)
    .then(checkStatus)
    .then(res => res.json())
    .then(data => {return data["data"]})
    .catch(error => {console.log(error); return []});
  for(let elem of data){
    elem["img"] = await getImage("api/v1/utils/getImage/" + elem["imgid"]);
  }
  return data;
}

function Home() {
  const [sliderData, setData] = React.useState([])

  useEffect(() => {
    async function fetchData() {
      const result = await getSlider();
      setData(result);
    }
    fetchData();
  }, []);
  let slides = [];
  for(let elem of sliderData){
    slides.push(
      <BigSliderCard link={elem["link"]} img={elem["img"]} text={elem["text"]}/>
    )
  }
    const content_fachgebiete = {
      title: "",
      elems: [
        "Bewirtschaftung.", "Erstvermietung.", "Verkauf.", "Treuhand."
      ],
    }

    let h_cards = []
    h_cards.push(<HomeCard title="DEFLORIN AG." slogan="Der Partner für Ihre Immobilie." 
      text="Willkommen bei Ihren neuen Fachpersonen aus der Nachbarschaft für Immobilien- und Treuhanddienstleistungen. Sie erwartet ein hochmotiviertes Jungunternehmen mit langjähriger Branchenerfahrung und einem Ziel: <br>Für Sie Zeit und Ihre Immobilie Mehrwerte zu schaffen."/>)
    h_cards.push(<HomeCard img={homewerteimg} background="grey" title="Unsere Werte." slogan="Damit zusammenkommt, was zusammengehört." 
      text="Bei DEFLORIN AG setzen wir auf die richtigen Puzzlesteine, um Ihnen beste Servicequalität zu bieten."
      link="/unsere-werte" linktext="Mehr erfahren."/>)
    h_cards.push(<HomeCard table={content_fachgebiete} title="Unsere Fachgebiete." slogan="Entlastung mit Gewinn." 
      text="Dank modernster Technologie, geschultem Personal und starken Partnern bietet Ihnen DEFLORIN AG immer die passende Dienstleistung."
      link="/fachgebiete" linktext="Mehr erfahren."/>)
    return (
        <div>
            <BigSlider slides={slides}/>
            {h_cards}
            <ContactCard background="grey"/>
        </div>
    )
  }

/* --------------------------------- HOME --------------------------------- */

/* ================================= FACHGEBIETE ================================= */
  
/* Fachgebiete main */
function Fachgebiete() {
    return (
        <div>
            <div className="smptitle SidePaddingBig">
              <Title title="Unsere Fachgebiete." slogan="Entlastung mit Gewinn."/>
            </div>
            <div className="cardscontainer SidePaddingBig">
              <MainCards img={bewirtschaftung_1000} title="Bewirtschaftung" text="Rundum gut betreut." link="/bewirtschaftung"/>
              <MainCards img={erstvermietung_1000} title="Erstvermietung" text="Nachhaltigen Immobilienwert schaffen." link="/erstvermietung"/>
              <MainCards img={verkauf_1000} title="Verkauf" text="Ihr Objekt im Scheinwerferlicht." link="/verkauf"/>
              <MainCards img={treuhand_1000} title="Treuhand" text="Zahlen unter Kontrolle." link="/treuhand"/>
            </div>
        </div>
    );
}

function DienstleistungsView(params) {
  const fields = {
    'mietliegenschaft': {
      texttitle: 'Mietliegenschaft.',
      slogan: 'Unsere Dienstleistungen für Sie.',
      title: [
        "Administrative Bewirtschaftung",
        "Technische Bewirtschaftung",
        "Finanzielle Bewirtschaftung"
      ],
      elems: [
        [
          "Kundenportal mit Zugriff auf die wichtigsten Dokumente",
          "Mieterbetreuung",
          "Kündigungsbearbeitung",
          "Wiedervermietung",
          "Laufende Mietvertragskontrolle",
          "Reporting",
          "Bewirtschaftung von Service- und Versicherungsverträgen"
        ],
        [
          "Wohnungsabnahmen und -übergaben",
          "Koordination Instandstellungsarbeiten",
          "Hauswartbetreuug",
          "Erstellung Kontroll- und Zustandsbericht der Liegenschaft",
          "Abwicklung von Versicherungsfällen",
          "Sicherstellung des Betriebs der Heizungsanlage",
          "Erstellung Schlussabrechnung bei Mieterwechsel"
        ],
        [
          "Inkasso",
          "Mietkautionsbetreuung",
          "Kontrolle und Begleichung von Rechnungen",
          "Erstellung Budget",
          "Erstellung Erfolgsrechnung und Bilanz",
          "Erstellung Nebenkostenabrechnung",
          "Lohnabrechnung Hauswartung"
        ]
      ]
    },
    'stockwerkeigentum': {
      texttitle: 'Stockwerkeigentum.',
      slogan: 'Unsere Dienstleistungen für Sie.',
      title: [
        "Administrative Bewirtschaftung",
        "Technische Bewirtschaftung",
        "Finanzielle Bewirtschaftung"
      ],
      elems: [
        [
          "Kundenportal mit Zugriff auf die wichtigsten Dokumente",
          "Organisation und Leitung der jährlichen Stockwerkeigentümerversammlung",
          "Führung des Versammlungsprotokolls",
          "Ausführung und Koordination der gefassten Beschlüsse",
          "Archivierung von Versammlungsprotokollen, Begründungsakten, Reglementen etc.",
          "Bewirtschaftung von Service- und Versicherungsverträgen"
        ],
        [
          "Koordination Instandstellungsarbeiten beim gemeinschaftlichen Bereich",
          "Erstellung und Kontrolle der Hausordnung",
          "Hauswartbetreuug",
          "Erstellung Kontroll- und Zustandsbericht vom gemeinschaftlichen Bereich",
          "Abwicklung von Versicherungsfällen beim gemeinschaftlichen Bereich",
          "Sicherstellung des Betriebs der Heizungsanlage"
        ],
        [
          "Inkasso von Beitragsforderungen",
          "Betreuung Erneuerungsfondskonto",
          "Kontrolle und Begleichung von Rechnungen",
          "Erstellung Budget",
          "Erstellung Erfolgsrechnung und Bilanz",
          "Lohnabrechnung Hauswartung"
        ]
      ]
    },
    'erstvermietung': {
      texttitle: 'Unsere Dienstleistung.',
      slogan: 'Von der Analyse bis zur Schlüsselübergabe.',
      title: [
        "Planung",
        "Vorbereitung",
        "Realisation"
      ],
      elems: [
        [
          "Projektanalyse",
          "Marktanalyse",
          "Grundrissberatung",
          "Materialisierungsberatung",
          "Mietzinsempfehlung",
          "Erstellung Vermarktungskonzept",
          "Erstellung Vermarktungsbudget"
        ],
        [
          "Projekthomepage aufbereiten",
          "Erstvermietungsbroschüre aufbereiten",
          "360°-Aufnahmen erstellen",
          "Inserate aufbereiten",
          "Mietvertragsinhalt definieren",
          "Mieterordner erstellen",
          "Definierte Werbemittel aufbereiten"
        ],
        [
          "Aufschaltung Projekthomepage und Inserate",
          "Definierte Vermarktungsmassnahmen termingerecht umsetzen",
          "Wohnungsbesichtigungen durchführen",
          "Überprüfung Mietgesuche",
          "Mietverträge erstellen",
          "Unterstützung Hauswartsuche sowie Abschluss von Serviceleistungen",
          "Koordination und Durchführung von Wohnungsübergaben"
        ]
      ]
    },
    'verkauf': {
      texttitle: 'Einzelverkauf.',
      slogan: 'Unsere Dienstleistungen für Sie.',
      title: [
        "Planung",
        "Vorbereitung",
        "Realisation"
      ],
      elems: [
        [
          "Marktanalyse",
          "Verkaufspreisempfehlung",
          "Erstellung Vermarktungskonzept",
          "Erstellung Vermarktungsbudget"
        ],
        [
          "Drohnen- und Innenaufnahmen erstellen, inkl. 360°-Aufnahmen",
          "Verkaufsdokumentation und Inserate aufbereiten",
          "Datenraum aufbereiten",
          "Kontaktherstellung mit Notar und Partnerbank"
        ],
        [
          "Aufschaltung Inserate",
          "Durchführung von Besichtigungen",
          "Verkaufsverhandlung",
          "Organisation und Teilnahme Beurkundung"
        ]
      ]
    },
    'erstverkauf': {
      texttitle: 'Verkauf Neubauprojekte.',
      slogan: 'Unsere Dienstleistungen für Sie.',
      title: [
        "Planung",
        "Vorbereitung",
        "Realisation"
      ],
      elems: [
        [
          "Projektanalyse",
          "Marktanalyse",
          "Grundrissberatung",
          "Materialisierungsberatung",
          "Verkaufspreisempfehlung pro Objekt",
          "Vorschlag Wertquotenverteilung pro Objekt",
          "Erstellung Vermarktungskonzept",
          "Erstellung Vermarktungsbudget"
        ],
        [
          "Beratung Inhalt Begründungsakten",
          "Beratung Inhalt Reglement",
          "Beratung Inhalt Kaufvertrag",
          "Projekthomepage aufbereiten",
          "Verkaufsdokumentation aufbereiten",
          "Textaufbereitung",
          "Inserate vorbereiten",
          "Datenraum vorbereiten"
        ],
        [
          "Aufschaltung Projekthomepage und Inserate",
          "Vermarktungsmassnahmen termingerecht umsetzen",
          "Verkaufsbesprechungen durchführen",
          "Erstellung Reservationsvereinbarungen",
          "Koordiation Beurkundungstermine",
          "Teilnahme an Beurkungsterminen",
          "Koordination Wohnungsübergaben",
          "Durchführung Wohnungsübergaben"
        ]
      ]
    },
  }

  if(params.background){
    return(
      <HomeCard title={fields[params.type]['texttitle']} slogan={fields[params.type]['slogan']}
        table={fields[params.type]} background={params.background}/> 
    )
  } else {
    return(
      <HomeCard title={fields[params.type]['texttitle']} slogan={fields[params.type]['slogan']}
        table={fields[params.type]}/>
    )
  }

}

function Bewirtschaftung() {
  return (
    <div>
      <MainImage img={bewirtschaftung} alt=""/>
      <HomeCard title="Bewirtschaftung." slogan="Rundum gut betreut."
        text="Eine professionelle Bewirtschaftung bildet die Basis für die Werterhaltung Ihrer Immobilie. Wir entlasten Sie und sorgen durch die ganzheitliche Betreuung für die Werterhaltung und Wertentwicklung Ihrer Liegenschaft. Dank unserem Kundenportal haben Sie jederzeit die wichtigsten Dokumente Ihrer Liegenschaft abrufbereit."/>
      <DienstleistungsView type="mietliegenschaft" background="grey"/>
      <DienstleistungsView type="stockwerkeigentum"/>
      <HonorarCard background="grey"/>
      <ContactCard/>
    </div>
  );
}

function Erstvermietung() {
  return (
    <div>
      <MainImage img={erstvermietung} alt=""/>
      <HomeCard title="Erstvermietung." slogan="Nachhaltigen Immobilienwert schaffen."
        text="Wir entlasten Sie und übernehmen die ganzheitliche Vermarktung Ihrer Liegenschaft. Durch eine frühzeitige Kontaktaufnahme mit DEFLORIN AG können wir Ihnen von Beginn an beratend zur Seite stehen und eine erfolgreiche Abwicklung der Erstvermietung mit glücklichen Mietern und optimaler Rendite gewährleisten.  
        <br><br>
        Durch unsere langjährige Erfahrung in der Immobilienbewirtschaftung wissen wir worauf zu achten ist, um die Liegenschaft auch nach Abschluss des Erstvermietungsprojektes über viele Jahre hinweg optimal und sorgenfrei bewirtschaften zu können."/>
      <DienstleistungsView type="erstvermietung" background="grey"/>
      <HonorarCard/>
      <ContactCard background="grey"/>
    </div>
  );
}

function Verkauf() {
  return (
    <div>
      <MainImage img={verkauf} alt=""/>
      <HomeCard title="Verkauf." slogan="Der erste Eindruck zählt."
        text="Wir finden für Sie den passenden und glücklichen Käufer. Mittels unserer raschen und reibungslosen Verkaufsabwicklung sowie zusätzlichen Treuhanddienstleistungen schaffen wir für Sie und Ihr Verkaufsobjekt einen Mehrwert."/>
      <DienstleistungsView type="verkauf" background="grey"/>
      <DienstleistungsView type="erstverkauf"/>
      <HonorarCard background="grey"/>
      <ContactCard/>
    </div>
  );
}

function Treuhand() {
  const imagelink = {
    link: "https://www.durschei.ch/",
    img: logo_durschei
  }
  return (
    <div>
      <MainImage img={treuhand} alt=""/>
      <HomeCard title="Treuhand." slogan="Zahlen unter Kontrolle."
        text="Mit unserer Gründungspartnerin Durschei Fiduziaria SA können wir Ihnen modular die passende Treuhanddienstleistung aus einer Hand anbieten. Sie erhalten ein professionelles und einheitliches Gesamtpaket durch zwei kleine und regional verankerte Unternehmen, welche auf Ihre Bedürfnisse eingehen können. So können Sie sich auf eine im Treuhandbereich spezialisierte und erfahrene Partnerin verlassen."/>
      <HomeCard title="Dienstleistung der Durschei Fiduziaria SA." slogan="Sie haben den Überblick."
        text="Überzeugen Sie sich selbst von den vielseitigen Dienstleistungen der erfahrenen Durschei Fiduziaria SA:" 
        background="grey" imglink={imagelink}/> 
      <ContactCard contact="2"/>
    </div>
  );
}

/* --------------------------------- FACHGEBIETE --------------------------------- */

/* ================================= UNTERNEHMEN ================================= */
  
function Unternehmen(params) {

    return (
      <div>
            <div className="smptitle SidePaddingBig">
              <Title title="Das Unternehmen." slogan="Gestern. Heute. Morgen."/>
            </div>
            <div className="cardscontainer SidePaddingBig">
              <MainCards img={ueber_uns_1000} title="Über uns" text="Wir stellen uns vor." link="/ueber-uns"/>
              <MainCards img={unsere_werte_1000} title="Unsere Werte" text="Das zeichnet uns aus." link="/unsere-werte"/>
              {(params.activePages["history"] === 1) &&
                <MainCards img={geschichte_1000} title="Geschichte" text="Ein Blick zurück." link="/geschichte"/>
              }
              {(params.activePages["partner"] === 1) &&
                <MainCards img={partner_1000} title="Die Partner" text="Zusammen stark." link="/partner"/>
              }
              {(params.activePages["reference"] === 1) &&
                <MainCards img={referenzen_1000} title="Referenzen" text="Erfolgreich umgesetzt." link="/referenzen"/>
              }
            </div>
        </div>
    );
}

async function getUnternehmsBroschure(){
  let ID = await fetch("/api/v1/utils/getSettings/2")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {
      return data["data"][0]["content"]["docid"].toString();
    })
    .catch((err) => {console.log(err); return "0"})
  let result = await getDownloadDocs("0", ID);
  return result;
}

function UeberUns() {
  const [data, setData] = React.useState({});

  useEffect(() => {
    async function fetchData() {
      const result = await getUnternehmsBroschure();
      setData(result);
    }

    fetchData();
  }, []);
  let link = data;
  let show_broschure = false;
  let apiLink = ""
  if("name" in link){
    show_broschure = true;
    let splittedURL = window.location.href.split("/")
    let url = splittedURL[0] + "//" + splittedURL[2];
    if(splittedURL[2].includes("localhost")){
      url = "http://localhost:3001/api/v1/downloads/getFile/" + link["link"];
    } else {
      url += "/api/v1/downloads/getFile/" + link["link"]
    }
    apiLink = url
  }
    return(
      <div>
        <MainImage img={ueber_uns} alt=""/>
        <div className="smptitle SidePaddingBig">
          <Title title="Über uns." slogan="Wir stellen uns vor."/>
        </div>
        <AllContacts filter="1" position={true}/>
        {show_broschure &&
          <TxtBut text="Erfahren Sie in unserer Unternehmensbroschüre mehr über DEFLORIN AG." 
            linktext={link["name"] + "."} link={apiLink}/>
        }
        <ContactCard background="grey"/>
      </div>
    );
}

function UnsereWerte() {
  return(
      <div>
        <MainImage img={unsere_werte} alt=""/>
        <div className="smptitle SidePaddingBig">
          <Title title="Unsere Werte." slogan="Das zeichnet uns aus."/>
        </div>
        <div className="SidePaddingBig">
          <ImgText img={puzzle_loyal} 
            text="Die Interessen unserer Kunden vertreten wir mit voller Überzeugung und ganzem Einsatz. Bei unseren Dienstleistungen ist Vertrauen ein wichtiges Gut. Vertrauen schaffen wir durch persönlichen Kontakt mit unseren Kunden:"
            slogan="Auf uns können Sie sich verlassen."/>
          <ImgText img={puzzle_organisiert} right={true}
            text="Langeweile ist Gold wert. Dies trifft auf jeden Fall bei der Organisation zu. Unsere Prozesse sind klar strukturiert, das Ablagesystem ist digitalisiert und unsere Dokumente für Sie und Ihre Immobilie vorausschauend aufgebaut. Dank unserer  Organisation erhalten wir mehr Zeit für das Wichtige:"
            slogan="Für Sie."/>
          <ImgText img={puzzle_ueberzeugt} 
            text="Auch neue Herausforderungen bringen uns nicht aus der Ruhe. Die Situation wird nüchtern analysiert und geduldig eine rationale Lösung gesucht. Unser Engagement endet erst bei der Lösung des Problems. Denn wir sind überzeugt:"
            slogan="Jedes Problem kann mit dem nötigen Ehrgeiz und Durchhaltewillen gelöst werden."/>
          <ImgText img={puzzle_quality} right={true}
            text="Dank modernen Programmen, digitalisierten Abläufen und Fachpersonal mit den entsprechenden Weiterbildungen, bieten wir was Sie und Ihre Immobilie verdient:"
            slogan="Das Beste."/>
          <ImgText img={puzzle_transparent} 
            text="Sei es vor dem ersten Kundenkontakt mit unserem einmaligen Online-Honorarrechner oder den jederzeit abrufbaren Liegenschaftsdaten und Dokumente in unserem Eigentümerportal:"
            slogan="Transparenz schafft Vertrauen."/>
          <ImgText img={puzzle_bewegung} right={true}
            text="Für Sie und Ihre Immobilie sind wir stets auf Achse. Sei es durch unsere stetige Weiterbildung, durch unsere regen Liegenschaftskontrollgänge oder bei der Durchforstung von neuen und innovativen Prozess- und Vermarktungsmethoden für Ihren Erfolg:"
            slogan="Wir bewegen uns für Sie."/>
        </div>
        <ContactCard background="grey"/>
      </div>
  );
}

//TODO: Not visible at beginning, only if 4 or more

function Partner() {
  return(
    <div>
      <MainImage img={partner} alt=""/>
      <HomeCard title="Die Partner." slogan="Gemeinsam stark."
        text="Gemeinsam zum Erfolg. Auch über unsere Immobilien- und Treuhanddienstleistungen hinaus möchten wir  Gesamtlösungen anbieten können. Für Sie und Ihre Immobilie arbeiten wir deshalb mit regional verankerten Partnern zusammen. Unser Team von Experten unterstützt Sie bei der Erhaltung, Entwicklung und Erweiterung Ihrer Liegenschaften."/>
      <AllContacts filter="3" partner={true}/>
    </div>
  );
}

//TODO: Not visible at beginning | images | slideshow
async function getHistory(){
  let data = await fetch("/api/v1/utils/getHistory")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"];})
    .catch(err => {console.log(err); return []})
  for (let reference of data){
    reference["img"] = await getImage("/api/v1/utils/getImage/" + reference["imgid"]);
  }
  return data;
}

function Geschichte() {
  const [history, setHistory] = React.useState([]);
  useEffect(() => {
    async function fetchData() {
      const result = await getHistory();
      setHistory(result);
    }
    fetchData();
  }, []);
  let history_elems = []
  for(let [i, val] of history.entries()){
    history_elems.push(
      <ImgText img={val["img"]} text={val["text"]} 
        imgtitle={val["title"]} right={i % 2 !== 0}/>
    )
  }
  return(
    <div>
      <MainImage img={geschichte} alt=""/>
      <HomeCard title="Geschichte." slogan="Ein Blick zurück."
        text="Tauchen Sie ein in die Geschichte der DEFLORIN AG, welche im Jahr 2021 beginnt. Finden Sie jährliche Beiträge über Momente, welche die DEFLORIN AG prägte und in Erinnerung bleiben werden."  
      />
      <div className="SidePaddingBig">
        {history_elems}
      </div>
    </div>
);
}

//TODO: Not visible at beginning
async function getReferences(){
  let data = await fetch("/api/v1/utils/getReference")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"];})
    .catch(err => {console.log(err); return []})
  for (let reference of data){
    reference["img"] = await getImage("/api/v1/utils/getImage/" + reference["imgid"]);
  }
  return data;
}

function Referenzen() {
  const [references, setReferences] = React.useState([]);
  useEffect(() => {
    async function fetchData() {
      const result = await getReferences();
      setReferences(result);
    }
    fetchData();
  }, []);
  let slides = []
  for(let elem of references){
    slides.push(
      <SmallSliderCard title={elem["maintitle"]} img={elem["img"]} place={elem["subtitle"]}
        type={elem["type"]} text={elem["text"]}/>
    )
  }
  return(
    <div>
      <MainImage img={referenzen} alt=""/>
      <HomeCard title="Referenzen." slogan="Unser Wirken für Sie."
        text="Für unsere Auftraggeber bieten wir erfahrene Immobilien- und Treuhandkompetenz. Vor, während und nach der Auftragsvergabe sind wir stets bemüht, das Beste herauszuholen. Zufriedene Auftraggeber sind die grössten Erfolge. Überzeugen Sie sich selbst."
      />
      <SmallSlider slides={slides}/>
      <HonorarCard background="grey"/>
      <ContactCard/>
    </div>
  );
}

/* --------------------------------- UNTERNEHMEN --------------------------------- */

/* ================================= MARKTPLATZ ================================= */
async function getMarktplatzImg(projects){
  let data = []
  for(let elem of projects){
    data.push(
      await getImage("/api/v1/projects/getImg/" + elem["imgmarktplatz"])
    )
  }
  return data;
}

function Marktplatz(params) {
  const [projectsImg, setprojectsImg] = React.useState({});
  useEffect(() => {
    async function fetchData() {
      const result = await getMarktplatzImg(params.projects);
      setprojectsImg(result);
    }
    fetchData();
  }, [params.projects]);
  let project_pages = []
  for(let [idx, elem] of params.projects.entries()){
    project_pages.push(
      <MainCards img={projectsImg[idx]} title={elem["title"]} text={elem["summary"]} link={elem["link"]}/>
    )
  }
    return (
      <div>
            <div className="smptitle SidePaddingBig">
              <Title title="Marktplatz." slogan="Mieten. Kaufen. Suchen."/>
            </div>
            <div className="cardscontainer SidePaddingBig">
              <MainCards img={vermieten_1000} title="Wir vermieten" text="Zeit für einen Tapetenwechsel." link="/wir-vermieten"/>
              <MainCards img={marktplatz_verkauf_1000} title="Wir verkaufen" text="Zuhause neu definiert." link="/wir-verkaufen"/>
              <MainCards img={suchauftrag_1000} title="Suchauftrag" text="Wir halten Ausschau für Sie." link="/suchauftrag"/>
              {project_pages}
            </div>
        </div>
    );
}

function WirVermieten() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://flatfox.ch/public/client/embed.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  let verkauf = false
  return(
    <div>
      <MainImage img={vermieten} alt=""/>
      <HomeCard title="Vermieten." slogan="Zeit für einen Tapetenwechsel."
        text="Ob Wohnung, Büro, Laden, Parkplatz oder Hobbyraum. Bei uns finden Sie Ihr passendes Mietobjekt."
      />
      <div className="SidePaddingBig" data-embed="flatfox" data-uid="fb76f969-5432-4d37-bc3a-b411c57b7dc7" data-initial-offer-type="RENT" data-lang="de"></div>
      {verkauf &&
        <DatenraumCard background="grey"/>
      }
      {!verkauf &&
        <HomeCard title="Downloads." slogan="Sofort Griffbereit."
          text="Erhalten Sie als zukünftige oder bereits bestehende Mieter auch ausserhalb unserer Öffnungszeiten optimale Unterstützung. Die wichtigsten Vorlagen und Informationsblätter stellen wir Ihnen gerne zur Verfügung."
          link="/downloads" linktext="Mehr erfahren." background="grey"/>
      }
      <SuchauftragCard/>
      <ContactCard background="grey"/>
    </div>
  );
}

function WirVerkaufen() {
  const [references, setReferences] = React.useState([]);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://flatfox.ch/public/client/embed.js";
    script.async = true;
    document.body.appendChild(script);
    async function fetchData() {
      const result = await getReferences();
      setReferences(result);
    }
    fetchData();
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  let slides = []
  for(let elem of references){
    if(elem["hovertext"] && elem["hovertext"] !== ""){
      slides.push(
        <SmallSliderCard title={elem["maintitle"]} img={elem["img"]} place={elem["subtitle"]}
          type={elem["type"]} text={elem["text"]} verkaufpage={true} hovertext={elem["hovertext"]}/>
      )
    }
  }
  let verkauf = true
  return(
    <div>
      <MainImage img={marktplatz_verkauf} alt=""/>
      <HomeCard title="Verkaufen." slogan="Ihr neues Zuhause."
        text="Ob Wohnung, Büro, Laden, Parkplatz oder Hobbyraum. Bei uns finden Sie Ihr passendes Kaufobjekt und einem Tapetenwechsel steht nichts mehr im Weg."
      />
      <div className="SidePaddingBig" data-embed="flatfox" data-uid="fb76f969-5432-4d37-bc3a-b411c57b7dc7" data-initial-offer-type="SALE" data-lang="de"></div>
      {slides.length > 0 &&
      <>
        <div className="smptitle SidePaddingBig">
          <Title title="Referenzen." slogan="Erfolgreich verkauft."/>
        </div>
        <SmallSlider slides={slides}/>
      </>
      }
      {verkauf &&
        <DatenraumCard background="grey"/>
      }
      {!verkauf &&
        <HomeCard title="Downloads." slogan="Sofort Griffbereit."
          text="Erhalten Sie als zukünftige oder bereits bestehende Mieter auch ausserhalb unserer Öffnungszeiten optimale Unterstützung. Die wichtigsten Vorlagen und Informationsblätter stellen wir Ihnen gerne zur Verfügung."
          link="/downloads" linktext="Mehr erfahren." background="grey"/>
      }
      <SuchauftragCard/>
      <ContactCard background="grey"/>
    </div>
  );
}

function Suchauftrag() {
  return(
    <div>
      <MainImage img={suchauftrag} alt=""/>
      <HomeCard title="Suchauftrag." slogan="Wir halten Ausschau für Sie." 
        text="Sind Sie auf der Suche nach Ihrer Traumwohnung oder nach Ihrem Traumhaus und möchten keine Angebote von uns verpassen? Wir informieren Sie exklusiv und kostenlos über passende Miet- oder Kaufobjekte in unserem Portfolio."/>
      <SuchForm />
      <ContactCard background="grey"/>
    </div>
  );
}

async function getProjectImg(names){
  let data = []
  for(let elem of names){
    data.push(
      await getImage("/api/v1/projects/getImg/" + elem)
    )
  }
  return data;
}

function ProjectPage(params) {
  const [bigImg, setBigImg] = React.useState([]);
  const [smallImg, setSmallImg] = React.useState([]);
  useEffect(() => {
    async function fetchBig() {
      const result = await getProjectImg(params.object["img_big"]);
      setBigImg(result);
    }
    async function fetchSmall() {
      const result = await getProjectImg(params.object["img_small"]);
      setSmallImg(result);
    }
    fetchBig();
    fetchSmall();
  }, [params.object]);
  let this_object = params.object
  let verkauf = (this_object['verkauf'] === 1)
  const bigslides = []
  for (let img of bigImg){
    bigslides.push(<BigSliderCard img={img} />)
  }
  return(
    <div>
      <BigSlider slides={bigslides}/>
      <HomeCard title={this_object['title']} slogan={this_object['summary']} text={this_object['text']}/>
      <HomeCard title={this_object['offer']} slogan={this_object['claim']} 
        objecttable={this_object["projecttable"]} background="grey" text={this_object['tabledescription']} />
      <HomeCard title="Impressionen." slogan="Wir lassen keine Wünsche offen." link={this_object["imglink"]} linktext={this_object["imglinktext"]}/>
      {this_object["iframelink"] &&
        <iframe style={{paddingBottom: "60px"}} title="360 degree view" width="70%" height="500" src={this_object["iframelink"]} frameBorder="0" allowFullScreen allow="xr-spatial-tracking"></iframe>
      }
      <SmallSliderShow slides={smallImg}/>
      <Downloads object={this_object["downloads"]} background="grey" type="project"/>
      {verkauf &&
      <>
        <DatenraumCard/>
        <SuchauftragCard background="grey"
          text="Ist Ihre Traumwohnung bereits reserviert oder verkauft? Erteilen Sie uns einen Suchauftrag mit dem entsprechenden Vermerk. Gerne informieren wir Sie exklusiv und kostenlos, sobald das gewünschte Objekt wieder verfügbar wird."
        />
        <ContactCard/>
      </>
      }
      {!verkauf &&
      <>
        <SuchauftragCard
          text="Ist Ihre Traumwohnung bereits reserviert oder vermietet? Erteilen Sie uns einen Suchauftrag mit dem entsprechenden Vermerk. Gerne informieren wir Sie exklusiv und kostenlos, sobald das gewünschte Objekt wieder verfügbar wird."
        />
        <ContactCard background="grey"/>
      </>
      }
    </div>
  );
}

function ExampleProjectPage(params) {
  let this_object = params.object
  let verkauf = this_object['verkauf']
  const bigslides = []
  for (let img of this_object['img_big']){
    bigslides.push(<BigSliderCard img={img} />)
  }
  const table_params = {
    "Nr.": [
      "A.01", "A.02", "A.01", "A.02", "A.01", "A.02", "A.01", "A.02", 
    ],
    "Geschoss": [
      "EG", "EG", "EG", "EG", "EG", "EG", "EG", "EG",
    ],
    "Zimmer": [
      "3.5", "4.5", "3.5", "4.5", "3.5", "4.5", "3.5", "4.5", 
    ],
    "Fläche": [
      "115", "200", "115", "200", "115", "200", "115", "200", 
    ],
    "Balkon": [
      "22", "", "22", "", "22", "", "22", "", "22", "", 
    ],
    "Nettomietzins": [
      "1'500.-", "1'600.-", "1'500.-", "1'600.-", "1'500.-", "1'600.-", "1'500.-", "1'600.-", 
    ],
    "Nebenkosten": [
      "345.-", "200.-", "345.-", "200.-", "345.-", "200.-", "345.-", "200.-", 
    ],
    "Bruttomietzins": [
      "2'000.-", "1'800.-", "2'000.-", "1'800.-", "2'000.-", "1'800.-", "2'000.-", "1'800.-", 
    ]
  }
  return(
    <div>
      <BigSlider slides={bigslides}/>
      <HomeCard title={this_object['name']} slogan={this_object['summary']} text={this_object['text']}/>
      <HomeCard title="Angebot." slogan="Ihre neue Traumwohnung." objecttable={table_params} background="grey"/>
      <div className="smptitle SidePaddingBig">
        <Title title="Impressionen." slogan="Wir lassen keine Wünsche offen."/>
      </div>
      <SmallSliderShow slides={this_object["img_small"]}/>
      <Downloads object={this_object["downloads"]} background="grey"/>
      {verkauf &&
        <DatenraumCard/>
      }
      {verkauf &&
        <SuchauftragCard background="grey"
          text="Ist Ihre Traumwohnung bereits reserviert oder verkauft? Erteilen Sie uns einen Suchauftrag mit dem entsprechenden Vermerk. Gerne informieren wir Sie exklusiv und kostenlos, sobald das gewünschte Objekt wieder verfügbar wird."
        />
      }
      {!verkauf &&
        <SuchauftragCard background="grey"
          text="Ist Ihre Traumwohnung bereits reserviert oder vermietet? Erteilen Sie uns einen Suchauftrag mit dem entsprechenden Vermerk. Gerne informieren wir Sie exklusiv und kostenlos, sobald das gewünschte Objekt wieder verfügbar wird."
        />
      }
      <ContactCard/>
    </div>
  );
}

/* --------------------------------- MARKTPLATZ --------------------------------- */

/* ================================= ONLINE-SCHALTER ================================= */

function Schalter(params) {
    return (
      <div>
            <div className="smptitle SidePaddingBig">
              <Title title="Online-Schalter." slogan="Immer für Sie bereit."/>
            </div>
            <div className="cardscontainer SidePaddingBig">
              <MainCards img={portal_1000} title="Eigentümerportal" text="Sie haben den Durchblick." link="/eigentuemerportal"/>
              <MainCards img={datenraum_1000} title="Datenraum" text="Alle Dokumente für Ihren Kaufentscheid." link="/datenraum"/>
              <MainCards img={downloads_1000} title="Downloads" text="Sofort griffbereit." link="/downloads"/>
              {(params.activePages["wissen"] === 1) &&
                <MainCards img={wissen_1000} title="Wissen" text="Das Immobilienlexikon." link="/wissen"/>
              }
            </div>
        </div>
    );
}

async function IsUserLoggedIn(type){
  let url = ""
  if(type === 0){
    url = "/api/v1/userarea/adminloggedin";
  }
  if(type === 1){
    url = "/api/v1/userarea/eigentumerloggedin";
  }
  else if (type === 2){
    url = "/api/v1/userarea/datenloggedin"
  }
  let status = await fetch(url)
    .then(resp => {
      return resp.ok;
    })
    .catch(error => {console.log(error); return false})
  return status;
}

async function getUserDocuments(type){
  let authorized = await IsUserLoggedIn(type);
  if(!authorized){
    return{"status": "unauthorized"};
  }
  const url = "/api/v1/userarea/mydocuments";
  let userdocuments = await fetch(url)
    .then(resp => {
      if(resp.ok){
        return resp.json();
      } else {
        return {"status": "unauthorized"};
      }
    })
    .catch(error => {return {"status": "unauthorized"}});
  return userdocuments;
}

function Eigentuemerportal(){
  const [loggedIn, setLoggedIn] = React.useState(false);
  useEffect(() => {
    async function fetchData() {
      const result = await IsUserLoggedIn(1);
      setLoggedIn(result);
    }
    fetchData();
  }, []);
  if(loggedIn){
    changePage("/user-eigentuemerportal");
  }
  return(
    <div>
      <MainImage img={portal} alt=""/>
      <EigentuemerLogin />
      <ContactCard background="grey"/>
    </div>
  )
}

function EigentuemerLoggedIn(){
  const [documents, setDocuments] = React.useState({});
  useEffect(() => {
    async function fetchData() {
      const result = await getUserDocuments(1);
      setDocuments(result);
    }
    fetchData();
  }, []);
  if (documents["status"] && documents["status"] === "unauthorized"){
    changePage("/eigentuemerportal");
  }
  let subpages = []
  for (const [, value] of Object.entries(documents)){
    let download_list = []
    for (let elem of value["documents"]){
      download_list.push({
        "name": elem["name"],
        "location": elem["link"],
      })
    }
    subpages.push({
      "title": value["title"],
      "download": download_list
    })
  }

  return(
    <div>
      <MainImage img={portal} alt=""/>
      <HomeCard title="Eigentümerportal." slogan="Sie haben den Durchblick."
        subpages={subpages} download="user"/>
      <ContactCard background="grey"/>
    </div>
  )
}

function ChangePasswordLoggedIn(){
  const [loggedIn, setLoggedIn] = React.useState([false, true]);
  useEffect(() => {
    async function fetchData() {
      const result = await IsUserLoggedIn(1);
      setLoggedIn([true, result]);
    }
    fetchData();
  }, []);
  if(loggedIn[0] && !loggedIn[1]){
    changePage("/unauthorized");
  }
  return(
    <>
    {loggedIn[0] &&
      <div>
      <MainImage img={portal} alt=""/>
      <PasswordChange />
      <ContactCard background="grey"/>
    </div>
    }
    </>
  )
}

function Datenraum(){
  const [loggedIn, setLoggedIn] = React.useState(false);
  useEffect(() => {
    async function fetchData() {
      const result = await IsUserLoggedIn(2);
      setLoggedIn(result);
    }
    fetchData();
  }, []);
  if(loggedIn){
    changePage("/user-datenraum");
  }
  return(
    <div>
      <MainImage img={datenraum} alt=""/>
      <DatenraumLogin/>
      <ContactCard background="grey"/>
    </div>
  )
}

function DatenraumLoggedIn(){
  const [documents, setDocuments] = React.useState({});
  useEffect(() => {
    async function fetchData() {
      const result = await getUserDocuments(2);
      setDocuments(result);
    }
    fetchData();
  }, []);
  if (documents["status"] && documents["status"] === "unauthorized"){
    changePage("/datenraum");
  }
  let subpages = []
  for (const [, value] of Object.entries(documents)){
    let download_list = []
    for (let elem of value["documents"]){
      download_list.push({
        "name": elem["name"],
        "location": elem["link"],
      })
    }
    subpages.push({
      "title": value["title"],
      "download": download_list
    })
  }

  return(
    <div>
      <MainImage img={portal} alt=""/>
      <HomeCard title="Datenraum." slogan="Alle Dokumente für Ihren Kaufentscheid."
        subpages={subpages} download="user"/>
      <ContactCard background="grey"/>
    </div>
  )
}

async function getDownloadDocs(category, id){
  if(category !== "0"){
    let data = await fetch("/api/v1/downloads/getStructure/" + category)
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {return data["data"]})
      .catch(err => {console.log(err); return []})
    let response = []
    for (let dir of data) {
      let downloads = [];
      for (let doc of dir["documents"]){
        downloads.push({
          "name": doc["name"],
          "location": doc["link"]
        })
      }
      response.push({
        "title": dir["title"],
        "download": downloads
      })
    }
    return response;
  } else {
    let data = await fetch("/api/v1/downloads/getDocInfo/" + id)
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {return data["data"][0]})
      .catch((err) => {console.log(err); return{}})
    return data;
  }
}

function DownloadPage(){
  const [data, setData] = React.useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await getDownloadDocs("1", "0");
      setData(result);
    }

    fetchData();
  }, []);
  let subpages = data;
  return(
    <div>
      <MainImage img={downloads} alt=""/>
      <HomeCard title="Downloads." slogan="Sofort griffbereit."
        text="Es ist uns wichtig, Sie als zukünftige oder bereits bestehende Mieter auch ausserhalb unserer Öffnungszeiten optimal unterstützen zu können. Die wichtigsten Vorlagen und Informationsblätter stellen wir Ihnen gerne zur Verfügung."
        subpages={subpages} download="public"/>
      <ContactCard background="grey"/>
    </div>
  )
}

function Wissen(){
  const [data, setData] = React.useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await getDownloadDocs("2", "0");
      setData(result);
    }

    fetchData();
  }, []);
  let subpages = data;
  return(
    <div>
      <MainImage img={wissen} alt=""/>
      <HomeCard title="Wissen." slogan="Das Immobilienlexikon."
        text="Finden Sie spannende Artikel rund um das Thema Immobilien.
        <br><br>
        Hier finden Sie Erklärungen von Fachbegriffen in der Immobilienbewirtschaftung, Tipps und Tricks beim optimalen Verkauf Ihrer Immobilie oder Hilfeleistungen beim Kauf Ihres neuen Eigenheims. Eignen Sie sich ein Grundwissen über die spannende Materie an und fachsimpeln Sie mit uns an der nächsten Sitzung."
        subpages={subpages} download="public"/>
      <ContactCard background="grey"/>
    </div>
  )
}

/* --------------------------------- ONLINE-SCHALTER --------------------------------- */

/* ================================= KONTAKT ================================= */

function Kontakt() {
  return (
    <div className="ccard SidePaddingBig">
      <p className="hcard-first"> 
          <span className="hcard-title text-black">Kontakt.</span> 
          <span className="hcard-space"> </span>
          <span className="hcard-slogan text-orange">Wir freuen uns auf Sie.</span>
        </p>
      <div className="ccard-inner">
        <ContactForm mail="fabio.deflorin@deflorin-ag.ch"/>
      </div>
    </div>
  )
}

/* --------------------------------- KONTAKT --------------------------------- */

/* ================================= HONORARRECHNER ================================= */

const honorarrechnerFormStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingBottom: '40px',
    '& > div': {
      maxWidth: '500px',
      minWidth: '300px',
      margin: theme.spacing(2),
    },
    '& .box-right > *': {
      margin: theme.spacing(1),
    },
    '& button': {
      cursor: 'pointer',
      border: 'none',
      fontWeight: 'bold',
      fontFamily: 'ArgentumSans-Light',
      fontSize: fontSizes['text-button'],
      color: colors['white'],
      height: '70px',
      width: '270px',
      maxWidth: '100%',
      backgroundColor: colors['orange'],
    },
    '& button:hover': {
      color: colors['black'],
    },
    '& .button-right': {
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'flex-end',
      paddingTop: '20px',
    }
  },
}));

async function getCalculations(){
  const url = "/api/v1/utils/getHonorar";
  let data = await fetch(url)
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"]})
    .catch(error => {console.log(error); return {}});
  return data;
}

function HRechner(params) {
  const [result, setResult] = React.useState("");
  const [type, setType] = React.useState("");
  const [counter, setCounter] = React.useState(0);
  const [calculations, setCalculations] = React.useState({});
  useEffect(() => {
    async function fetchData() {
      const result = await getCalculations();
      setCalculations(result);
    }
    fetchData();
  }, []);
  function updateRight(new_type){
    setType(new_type)
  }
  let error_msg = "Dieses Feld ist erforderlich."
  let no_option_selected_err = "Bitte wählen Sie ein Feld aus."
  let invalid_msg = "Ungültige Eingabe"
  let too_less_msg = "Bei weniger als 2 kontaktieren Sie uns bitte direkt über das Kontaktformular."
  const classes = honorarrechnerFormStyle();
  
  return(
    <div>
      <Formik 
        initialValues={{
          plz: "",
          ort: "",
          nr_mietwohnungen_bewirtschaftung: "",
          nr_mietwohnungen_vermietung: "",
          nr_nebenobjekte_bewirtschaftung: "",
          nr_nebenobjekte_stockwerkeigentum: "",
          nr_nebenobjekte_erstvermietung: "",
          nr_nebenobjekte_erstverkauf: "",
          current_mietzinseinnahmen: "",
          nr_eigentumswohnungen: "",
          objecttype: "",
          nr_zimmer: "",
          verkaufspreis: "",
          erwartung_mietzinseinnahmen: "",
          nr_eigentumswohnungen_verkauf: "",
          erwartung_gesamtverkaufspreis: "",
        }}
        validationSchema={Yup.object({
          plz: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(1000, invalid_msg)
            .max(9999, invalid_msg)
            .required(error_msg),
          ort: Yup.string()
            .required(error_msg),
          nr_mietwohnungen_bewirtschaftung: Yup.number()
            .min(1, invalid_msg)
            .when("plz", {
              is: (val) => ((type === "mietliegenschaft")),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
                .required(error_msg)
            }),
          nr_mietwohnungen_vermietung: Yup.number()
            .min(1, invalid_msg)
            .when("plz", {
              is: (val) => ((type === "erstvermietung")),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
                .required(error_msg)
            }),
          nr_nebenobjekte_bewirtschaftung: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(0, invalid_msg)
            .when("plz", {
              is: (val) => (type === "mietliegenschaft"),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
                .required(error_msg)
            }),
          nr_nebenobjekte_stockwerkeigentum: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(0, invalid_msg)
            .when("plz", {
              is: (val) => (type === "stockwerkeigentum"),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
                .required(error_msg)
            }),
          nr_nebenobjekte_erstvermietung: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(0, invalid_msg)
            .when("plz", {
              is: (val) => (type === "erstvermietung"),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
                .required(error_msg)
            }),
          nr_nebenobjekte_erstverkauf: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(0, invalid_msg)
            .when("plz", {
              is: (val) => (type === "erstverkauf"),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
                .required(error_msg)
            }),
          current_mietzinseinnahmen: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(0, invalid_msg)
            .when("plz", {
              is: (val) => (type === "mietliegenschaft"),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
              .required(error_msg)
            }),

          nr_eigentumswohnungen: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(1, invalid_msg)
            .when("plz", {
              is: (val) => (type === "stockwerkeigentum"),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
              .required(error_msg)
            }),

          objecttype: Yup.string()
            .oneOf(
              ["haus", "wohnung"],
              no_option_selected_err
            )
            .when("plz", {
              is: (val) => (type === "verkauf"),
              then: Yup.string().required(error_msg)
            }),
          nr_zimmer: Yup.number().typeError("Geben Sie eine Zahl ein.")
            .min(1, invalid_msg)
            .when("plz", {
              is: (val) => (type === "verkauf"),
              then: Yup.number().typeError("Geben Sie eine Zahl ein.")
              .required(error_msg)
            }),
          verkaufspreis: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(1, invalid_msg)
            .when("plz", {
              is: (val) => (type === "verkauf"),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
              .required(error_msg)
            }),

          erwartung_mietzinseinnahmen: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(1, invalid_msg)
            .when("plz", {
              is: (val) => (type === "erstvermietung"),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
              .required(error_msg)
            }),

          nr_eigentumswohnungen_verkauf: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(1, invalid_msg)
            .min(2, too_less_msg)
            .when("plz", {
              is: (val) => (type === "erstverkauf"),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
              .required(error_msg)
            }),
          erwartung_gesamtverkaufspreis: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
            .min(1, invalid_msg)
            .when("plz", {
              is: (val) => (type === "erstverkauf"),
              then: Yup.number().integer("Geben Sie eine Zahl ohne Kommastellen ein.").typeError("Geben Sie eine Zahl ein.")
              .required(error_msg)
            }),
        })}
        onSubmit={async (values) => {
          if(counter >= 4){
            setResult("Ihre 5 Abfragen mit dem Honorarrechner freuen uns sehr. Nehmen Sie doch mit uns Kontakt auf und wir unterbreiten Ihnen sehr gerne eine massgeschneiderte Offerte.")
            setCounter(counter + 1)
            return
          }
          setCounter(counter + 1)
          if(type === "stockwerkeigentum"){
            let thisElem = []
            for(let elem of calculations){
              if(elem["id"] === 2){
                thisElem = elem;
              }
            }
            let fee = thisElem["calculations"]["grund_honorar"]
            let counter = 0
            let nr_wohnungen = values.nr_eigentumswohnungen
            while(nr_wohnungen > 0 && counter < thisElem["calculations"]["tarife_wohnung"].length){
              let max = thisElem["calculations"]["tarife_wohnung"][counter]["max"]
              let this_honorar = thisElem["calculations"]["tarife_wohnung"][counter]["honorar"]
              if(nr_wohnungen <= max){
                fee += this_honorar * nr_wohnungen
              }
              else {
                fee += this_honorar * max
              }
              nr_wohnungen -= max
              counter += 1
            }
            counter = 0
            let nr_nebenobjekte = values.nr_nebenobjekte_stockwerkeigentum
            while(nr_nebenobjekte > 0  && counter < thisElem["calculations"]["tarife_nebenobjekt"].length){
              let max = thisElem["calculations"]["tarife_nebenobjekt"][counter]["max"]
              let this_honorar = thisElem["calculations"]["tarife_nebenobjekt"][counter]["honorar"]
              if(nr_nebenobjekte <= max){
                fee += this_honorar * nr_nebenobjekte
              }
              else {
                fee += this_honorar * max
              }
              nr_nebenobjekte -= max
              counter += 1
            }
            let stringFee = fee.toString();
            let lenfee = stringFee.length;
            if(lenfee > 3){
              stringFee = stringFee.substring(0, lenfee-3) + "'" + stringFee.substring(lenfee-3)
            }
            setResult(thisElem["textbefore"] + stringFee + thisElem["textafter"])           
          }
          else {
            let thisElem = []
            for(let elem of calculations){
              if(elem["key"] === type){
                thisElem = elem;
              }
            }
            let user_price = values[thisElem["calculations"]["field_name"]]
            if(user_price < thisElem["calculations"]["min"]){
              alert("Der von Ihnen angegebene Preis liegt ausserhalb des Bereichs.")
            }
            else{
              let counter = 0
              let honorar_array = thisElem["calculations"]["honorar"]
              while(honorar_array[counter]["max"] < user_price){
                counter += 1
              }
              let fee = honorar_array[counter]["honorar"]
              setResult(thisElem["textbefore"] + fee + thisElem["textafter"])
            }
          }
        }}
      >
        {props => (
        <Form className={classes.root}>
          <div className="hr-f-elem flex-column">
            <div className="hr-f-title text-orange"><p>Dienstleistung:</p></div>
            {type === "mietliegenschaft" && 
              <BigButton text="Bewirtschaftung Mietliegenschaft" function={() => updateRight("mietliegenschaft")}
              selfstyle={true} styling="hr-button text-black background-orange"/>
            }
            {type !== "mietliegenschaft" && 
              <BigButton text="Bewirtschaftung Mietliegenschaft" function={() => updateRight("mietliegenschaft")}
              selfstyle={true} styling="hr-button text-orange background-grey"/>
            }
            {type === "stockwerkeigentum" &&
              <BigButton text="Bewirtschaftung Stockwerkeigentum" function={() => updateRight("stockwerkeigentum")}
              selfstyle={true} styling="hr-button text-black background-orange"/>
            }
            {type !== "stockwerkeigentum" &&
              <BigButton text="Bewirtschaftung Stockwerkeigentum" function={() => updateRight("stockwerkeigentum")}
              selfstyle={true} styling="hr-button text-orange background-grey"/>
            }
            {type === "erstvermietung" &&
              <BigButton text="Erstvermietung Wohnliegenschaften (Neubauprojekt)" function={() => updateRight("erstvermietung")}
              selfstyle={true} styling="hr-button text-black background-orange"/>
            }
            {type !== "erstvermietung" &&
              <BigButton text="Erstvermietung Wohnliegenschaften (Neubauprojekt)" function={() => updateRight("erstvermietung")}
              selfstyle={true} styling="hr-button text-orange background-grey"/>
            }
            {type === "verkauf" &&
              <BigButton text="Verkauf von Wohnungen und Einfamilienhäuser" function={() => updateRight("verkauf")}
              selfstyle={true} styling="hr-button text-black background-orange"/>
            }
            {type !== "verkauf" &&
              <BigButton text="Verkauf von Wohnungen und Einfamilienhäuser" function={() => updateRight("verkauf")}
              selfstyle={true} styling="hr-button text-orange background-grey"/>
            }
            {type === "erstverkauf" &&
              <BigButton text="Erstverkauf Wohnungen (Neubauprojekt)" function={() => updateRight("erstverkauf")}
              selfstyle={true} styling="hr-button text-black background-orange"/>
            }
            {type !== "erstverkauf" &&
              <BigButton text="Erstverkauf Wohnungen (Neubauprojekt)" function={() => updateRight("erstverkauf")}
              selfstyle={true} styling="hr-button text-orange background-grey"/>
            }
          </div>
          <div>
            <div className="box-right">
          {type !==  "" &&
          <>
            <Field component={TextField} variant="outlined" 
              name="plz" label="PLZ*" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" 
              name="ort" label="Ort*" fullWidth={true}
            />
          </>
          }
          {type ===  "mietliegenschaft" &&
          <>
            <Field component={TextField} variant="outlined" 
              name="nr_mietwohnungen_bewirtschaftung" label="Anzahl Mietwohnungen*" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" 
              name="nr_nebenobjekte_bewirtschaftung" label="Anzahl Nebenobjekte (Parkplätze, Bastelräume etc.)*" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" 
              name="current_mietzinseinnahmen" label="Jahresmietzinseinnahmen" fullWidth={true}
              helperText="Durchschnittliche Netto-IST-Mietzinseinnahmen in CHF der letzten 3 Jahre (Mieteinnahmen ohne Nebenkosten und abzüglich der Mietzinsverluste aufgrund Leerstand oder dergleichen)"
              InputProps={{endAdornment: <InputAdornment position="end">CHF</InputAdornment>}}
            />
          </>
          }
          {type ===  "stockwerkeigentum" &&
          <>
            <Field component={TextField} variant="outlined"
              name="nr_eigentumswohnungen" label="Anzahl Eigentumswohnungen*" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" 
              name="nr_nebenobjekte_stockwerkeigentum" label="Anzahl Nebenobjekte (Parkplätze, Bastelräume etc.)*" fullWidth={true}
            />
          </>
          }
          {type ===  "verkauf" &&
          <>
            <Field component={TextField} variant="outlined" select={true}
              label="Einfamilienhaus/Eigentumswohnung*" name="objecttype" fullWidth={true}
            >
              <MenuItem value="">Bitte auswählen</MenuItem>
              <MenuItem value="haus">Einfamilienhaus</MenuItem>
              <MenuItem value="wohnung">Eigentumswohnung</MenuItem>
            </Field>
            <Field component={TextField} variant="outlined" 
              name="nr_zimmer" label="Anzahl Zimmer*" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" 
              name="verkaufspreis" label="Vorstellung Verkaufspreis*" fullWidth={true}
              helperText="Vorstellung Verkaufspreis in CHF (Alternativ Eingabe Verkehrswert. Ersichtlich im Dokument vom Amt für Immobilienbewertung)"
              InputProps={{endAdornment: <InputAdornment position="end">CHF</InputAdornment>}}
            />
          </>
          }
          {type ===  "erstvermietung" &&
          <>
            <Field component={TextField} variant="outlined" 
              name="nr_mietwohnungen_vermietung" label="Anzahl Mietwohnungen*" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" 
              name="nr_nebenobjekte_erstvermietung" label="Anzahl Nebenobjekte (Parkplätze, Bastelräume etc.)*" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" 
              name="erwartung_mietzinseinnahmen" label="Erwartung Jahresmietzinseinnahmen*" fullWidth={true}
              helperText="Erwartung jährlich erzielbare, durchschnittliche Netto-IST-Mietzinseinnahmen in CHF"
              InputProps={{endAdornment: <InputAdornment position="end">CHF</InputAdornment>}}
            />
          </>
          }
          {type ===  "erstverkauf" &&
          <>
            <Field component={TextField} variant="outlined" 
              name="nr_eigentumswohnungen_verkauf" label="Anzahl Eigentumswohnungen*" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" 
              name="nr_nebenobjekte_erstverkauf" label="Anzahl Nebenobjekte (Parkplätze, Bastelräume etc.)*" fullWidth={true}
            />
            <Field component={TextField} variant="outlined" 
              name="erwartung_gesamtverkaufspreis" label="Erwartung Gesamtverkaufspreis*" fullWidth={true}
              helperText="Erwartung erzielbarer Gesamtverkaufspreis sämtlicher Wohnungen in CHF"
              InputProps={{endAdornment: <InputAdornment position="end">CHF</InputAdornment>}}
            />
          </>
          }
          </div>
          {counter >= 5 &&
            <div className="hr-f-result"><p>{parseLineBreaks(result)}</p></div>
          }
          {result !== "" && counter < 5 &&
            <div className="hr-f-result"><p><span className="text-orange">Unser Honorar für Ihre gewünschte Dienstleistung:</span><br></br>{parseLineBreaks(result)}</p></div>
          }
          {type !==  "" &&
          <>
            <div className="button-right">
              <button type="submit">Honorar berechnen.</button>
            </div>
          </>
          }
          </div>
        </Form>
        )}
      </Formik>
      {result !== "" &&
        <DienstleistungsView type={type} />
      }
    </div>
  );
}


function Honorarrechner() {
    return (
      <div>
        <MainImage img={honorarrechner} alt=""/>
        <HonorarCard nolink={true}/>
        <HRechner/>
        <ContactCard background="grey"/>
      </div>
    );
  }

/* --------------------------------- HONORARRECHNER --------------------------------- */

/* ================================= ERROR_CODES ================================= */

function NotFound(){
  const [wait, setWait] = React.useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {setWait(false)}, 1000);
    return () => clearTimeout(timer);
  }, [])

  if(wait){
    return (<div></div>)
  }
      return(
          <div>
              <MainImage img={default_big} alt=""/>
              <HomeCard title="404." slogan="Diese Seite existiert nicht."
                text="Überprüfen Sie, dass der eingegebene Link korrekt ist oder wenden Sie sich an uns."/>
              <ContactCard />
          </div>
      )
}

function Unauthorized(){
  return(
    <div>
      <MainImage img={default_big} alt=""/>
      <HomeCard title="401." slogan="Unauthorized."
        text="Um diese Seite zu erreichen, müssen Sie sich anmelden."/>
      <ContactCard />
    </div>
  )
}

/* --------------------------------- ERROR_CODES --------------------------------- */

/* ================================= IMPRESSUM ================================= */

function Impressum(){
  let elems = [
    {
      "title": "Haftungsausschluss",
      "text": "Die Autorin (DEFLORIN AG) übernimmt keinerlei Gewähr bezüglich der Korrektheit, Vollständigkeit oder Aktualität der Inhalte auf dieser Webiste.<br>Alle Angaben und Angebote, insbesondere jene des Honorarrechners, haben ausschliesslich Beispielcharakter und sind daher komplett unverbindlich. Die Autorin (DEFLORIN AG) behält sich das Recht vor, Teile oder das gesamte Angebot der aufgeführten Dienstleistungen und deren Konditionen ohne Ankündigung zu ändern, zu ergänzen oder zu entfernen."
    },
    {
      "title": "Haftung für Links",
      "text": "Die Autorin (DEFLORIN AG) übernimmt keinerlei Garantie für Verweise und Links auf Webseiten Dritter. Es wird jegliche Verantwortung abgelehnt. Der Zugriff sowie die entsprechende Nutzung erfolgen auf eigene Gefahr.",
    },
    {
      "title": "Urheberrechte",
      "text": "Jegliche Rechte, insbesondere das Urheberrecht, des gesamten Inhalts der Website wie beispielsweise Texte, Bilder oder Dateien gehören ausschliesslich der DEFLORIN AG. Für Weiterverwendung oder Reproduktion jeglicher Art bedingt es einer schriftlichen Zustimmung der Urheberrechtsträgerin.",
      },
  ]
  return(
      <div>
          <HomeCard title="Impressum." slogan=""
            text="DEFLORIN AG<br><br>Rheinstrasse 47<br>7012 Felsberg<br><br>081 377 70 12<br>info@deflorin-ag.ch"
            paragraphs={elems}
          />
      </div>
  )
}

/* --------------------------------- IMPRESSUM --------------------------------- */

/* ================================= DATENSCHUTZ ================================= */

function Datenschutz(){
  const elems = [
    {
      "title": "Personenbezogene Daten",
      "text": "Beim Besuch unserer Website werden grundsätzlich keine Angaben zu Ihrer Person gesammelt. Zwecks Verbesserungen des Services können jedoch unpersönliche Nutzungsdaten wie IP-Adressen, Datum, Uhrzeit etc. verwendet werden.<br>Personenbezogene Daten werden ausschliesslich erhoben, wenn diese zur Erbringung eines Services unabdingbar sind und diese von Ihnen selbst zur Verfügung gestellt werden. Diese Daten werden ausschliesslich im Rahmen des entsprechenden Anliegens verwendet."
    },
    {
      "title": "Weitergabe von personenbezogenen Daten",
      "text": "Jegliche Personendaten, welche uns von Ihnen zur Verfügung gestellt werden, werden weder verkauft noch mit Dritten ausserhalb der DEFLORIN AG geteilt. Einzige Ausnahme sind externe Dienstleister, welche in unserem Auftrag Ihre Anfragen oder Anliegen zwecks Serviceerbringung verarbeiten. Dabei ist gewährleistet, dass auch von diesen Dienstleistern Anforderungen des Datenschutzes und der Datensicherheit eingehalten werden.",
    },
    {
      "title": "Verwendung von Cookies",
      "text": "Beim Aufruf bestimmter Unterseiten werden Session-Cookies verwendet. Diese sind zwingend erforderlich, um bestimmte Applikationen nutzen zu können. Session-Cookies werden nach Ende der Browser-Sitzung automatisch von Ihrem Computer gelöscht und ausschliesslich zur Nutzung der entsprechenden Applikation verwendet. Mithilfe der entsprechenden Einstellung in Ihrem Browser haben Sie zudem die Möglichkeit, solche Cookies nur dann zuzulassen, wenn Sie dies wünschen."
    },
    {
      "title": "Webanalyse unter Verwendung von Google Analytics",
      "text": "Um das Nutzerverhalten auf dieser Website zu analyisieren wird Google Analytics, ein Webanalysedienst der Google Inc., eingesetzt. Dazu werden Cookies verwendet. Im Auftrage der DEFLORIN AG wertet Google diese aus, um Reports über Websiteaktivitäten zusammenzustellen. Gemäss Google werden dabei keine Zusammenführungen dieser Daten und Personendaten durchgeführt. Dieser Service und damit die Weitergabe von Daten zum Nutzerverhaltan kann im Browser mittels Einstellungen verhindert und gelöscht werden."
    },
    { 
      "title": "Aufbewahrung und Sicherung von Daten",
      "text": "Um Ihre Daten sicher zu speichern, setzt die DEFLORIN AG entsprechende Technologien ein. Daten werden nur so lange gespeichert, wie es zur Erbringung der entsprechenden Dienstleistung und/oder der Bearbeitung des entsprechenden Anliegens notwendig ist.",
    },
    {
      "title": "Ihre Rechte",
      "text": "Als Nutzer*in unserer Website haben Sie grundsätzlich das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch. Falls Sie der Ansicht sind, dass bei der Verarbeitung Ihrer Daten gegen Datenschutzrecht verstossen wird, so können Sie sich bei der Aufsichtsbehörde beschweren."
    },
    {
      "title": "Kontakt",
      "text": "Bei jeglichen Fragen sowie Anliegen bezüglich der oben genannten Rechte können Sie sich an uns wenden:<br><br>DEFLORIN AG<br><br>Rheinstrasse 47<br>7012 Felsberg<br><br>081 377 70 12<br>info@deflorin-ag.ch"
    },
    {
      "title": "Änderungen dieser Bestimmungen",
      "text": "Die DEFLORIN AG behält sich das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Geltend ist dabei immer die auf dieser Website veröffentlichte Fassung."
    }
  ]
  return(
      <div>
          <HomeCard title="Datenschutz." slogan=""
            text="Ihre Privatsphäre wird von uns geschätzt. Beachten Sie daher die folgenden Ausführungen unserer Datenschutzerklärung."
            paragraphs={elems}
          />
      </div>
  )
}

/* --------------------------------- DATENSCHUTZ --------------------------------- */

/* ================================= ADMIN ================================= */

function AddNewElem(params){
  const [submittedSuccessful, setSubmittedSuccessful] = React.useState(false);
  const [error, setError] = React.useState("");
  const FILE = ("file" in params["specials"]);
  const PROJECT = ("project" in params["specials"]);
  let elements = []
  let invals = {}
  invals["url"] = params.url;
  if(params.id){
    invals["id"] = params.id;
  }
  for(let elem of params.fields){
    if (params.currentData && elem["field_name"] in params.currentData){
      invals[elem["field_name"]] = params.currentData[elem["field_name"]]
    } else {
      invals[elem["field_name"]] = ""
    }
    if(elem["type"] === "textfield"){
      elements.push(
        <Field component={TextField} variant="outlined" name={elem["field_name"]}
          label={elem["label"]} fullWidth={true}
        />
      )
    } else if(elem["type"] === "textfield_large") {
      elements.push(
        <Field component={TextField} variant="outlined" name={elem["field_name"]}
          label={elem["label"]} fullWidth={true} multiline rows={20}
        />
      )
    } else if(elem["type"] === "drop-down"){
      let options = []
      options.push(
        <MenuItem value="">Bitte auswählen</MenuItem>
      );
      for(let option of elem["options"]){
        options.push(
          <MenuItem value={option["value"]}>{option["text"]}</MenuItem>
        );
      }
      elements.push(
        <Field component={TextField} variant="outlined" select={true}
          label={elem["label"]} name={elem["field_name"]}
        >
          {options}
        </Field>
      )
    } else if(elem["type"] === "file"){
      elements.push(
        <label for={elem["field_name"]}>{elem["label"]}</label>       
      )
    } else if(elem["type"] === "select"){
      let options = []
      for(let option of elem["options"]){
        options.push(
          <Field component={CheckboxWithLabel} type="checkbox" name={elem["field_name"]} color="primary"
            key={option["value"]} value={option["value"]} Label={{label: option["label"]}}/>
        )
      }
      elements.push(
        <FormControl component="fieldset" style={{display: "flex"}}>
          <FormLabel component="legend">{elem["label"]}</FormLabel>
          <FormGroup>{options}</FormGroup>
        </FormControl>
      )
    }
  }
  if(PROJECT){
    invals["img_big"] = [];
    invals["img_small"] = [];
    invals["img_marktplatz"] = "";
  }
  const classes = loginFormStyle();
  return(
    <div className= "adm-new-container">
      {!FILE && !PROJECT && 
        <div>
          <Formik
            enableReinitialize
            initialValues={invals}
            onSubmit={(values, {setSubmitting}) => {
              let url = values.url;
              let params = new FormData();
              for(const [key, value] of Object.entries(values)){
                params.append(key, value);
              }
              fetch(url, {method: "POST", body: params})
                .then(response => {
                  if(response.status === 200 || response.status === 400){
                    return response;
                  } else {
                    throw Error(response.statusText);
                  }
                })
                .then(resp => resp.json())
                .then(data => {
                  if("error" in data){
                    setError(data["error"])
                    setSubmitting(false);
                  } else {
                    setSubmittedSuccessful(true);
                    setError("");
                    setSubmitting(false);
                  }
                })
                .catch(error => {console.log(error); setError(error.toString()); setSubmitting(false)})
            }}
          >
            <>
              <Form className={classes.root}>
                <div><p>{params.title}</p></div>
                {elements}
                <div className="button-right">
                  <p className="error-msg">{error}</p>
                  <button type="submit">Eintrag hinzufügen.</button>
                </div>
              </Form>
            </>
          </Formik>
        </div>
      }
      {FILE && !PROJECT &&
        <div>
          <Formik
            enableReinitialize
            initialValues={invals}
            onSubmit={(values, {setSubmitting}) => {
              let url = values.url;
              let params = new FormData();
              for(const [key, value] of Object.entries(values)){
                params.append(key, value);
              }
              fetch(url, {method: "POST", body: params})
                .then(response => {
                  if(response.status === 200 || response.status === 400){
                    return response;
                  } else {
                    throw Error(response.statusText);
                  }
                })
                .then(resp => resp.json())
                .then(data => {
                  if("error" in data){
                    setError(data["error"])
                    setSubmitting(false);
                  } else {
                    setSubmittedSuccessful(true);
                    setError("");
                    setSubmitting(false);
                  }
                })
                .catch(error => {console.log(error); setError(error.toString())})
            }}
          >
            {({setFieldValue}) => (
              <Form className={classes.root}>
                <div><p>{params.title}</p></div>
                {elements}
                <input type="file" name="file" onChange={(event) => {setFieldValue("file", event.target.files[0]) }} />
                <div className="button-right">
                  <p className="error-msg">{error}</p>
                  <button type="submit">Eintrag hinzufügen.</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
      {PROJECT &&
        <div>
          <Formik
            enableReinitialize
            initialValues={invals}
            onSubmit={(values, {setSubmitting}) => {
              let url = values.url;
              let params = new FormData();
              for(const [key, value] of Object.entries(values)){
                if(key === 'img_small' || key === 'img_big'){
                  for(let elem of value){
                    params.append(key, elem);
                  }
                } else {
                  params.append(key, value);
                }
              }
              fetch(url, {method: "POST", body: params})
                .then(response => {
                  if(response.status === 200 || response.status === 400){
                    return response;
                  } else {
                    throw Error(response.statusText);
                  }
                })
                .then(resp => resp.json())
                .then(data => {
                  if("error" in data){
                    setError(data["error"])
                    setSubmitting(false);
                  } else {
                    setSubmittedSuccessful(true);
                    setError("");
                    setSubmitting(false);
                  }
                })
                .catch(error => {console.log(error); setError(error.toString())})
            }}
            >
            {({setFieldValue, values}) => (
              <Form className={classes.root}>
                <div><p>{params.title}</p></div>
                {elements}
                <label for="img_big">Bilder für Slider oben [H: 625, B:2000] (alle zusammen auswählen)</label>
                <input type="file" name="img_big" multiple onChange={(event) => {setFieldValue("img_big", Array.from(event.target.files))}}/>
                <p>ausgewählte Bilder:<br></br>
                  <>
                    {values.img_big.map(function(file, idx){
                      return(
                        <><span>{file.name} <br></br></span></>
                      )
                    })}
                  </>
                </p>
                <label for="img_small">Bilder für Impressionen [H: 668, B:1000] (alle zusammen auswählen)</label>
                <input type="file" name="img_small" multiple onChange={(event) => {setFieldValue("img_small", Array.from(event.target.files))}}/>
                <p>ausgewählte Bilder:<br></br>
                  <>
                    {values.img_small.map(function(file, idx){
                      return(
                        <><span>{file.name} <br></br></span></>
                      )
                    })}
                  </>
                </p>
                <label for="img_marktplatz">Bild für Marktplatz [H: 668, B:1000] (nur 1 Bild)</label>
                <input type="file" name="img_marktplatz" onChange={(event) => {setFieldValue("img_marktplatz", event.target.files[0])}}/>
                <div className="text-orange">
                  <p>Die restlichen Felder kannst du in der Übersicht bearbeiten/hinzufügen.</p>
                </div>
                <div className="button-right">
                  <p className="error-msg">{error}</p>
                  <button type="submit">Eintrag hinzufügen.</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
      {submittedSuccessful &&
        <div className="adm-new-success">
          <p>Erfolgreich hinzugefügt.</p>
        </div>
      }
    </div>
  )
}

async function getData(url){
  if(url === ""){
    return {};
  }
  let data = await fetch(url)
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data;})
    .catch(error => {console.log(error); return {"error": "Something went wrong"}})
  return data;
}

function ShowElements(params){
  const [data, setContent] = React.useState({})
  useEffect(() => {
    async function fetchData() {
      const result = await getData(params.url);
      if ("data" in result || "error" in result){
        setContent(result);
      } else if(params.data_given) {
        setContent(params.data_given)
      }
    }
    fetchData();
  }, [params]);

  function deleteElem(url, idx){
    fetch(url)
      .then(checkStatus)
      .then(_ => {
        const new_data = [...data["data"]]
        new_data.splice(idx, 1);
        setContent({"data": new_data});
      })
      .catch(err => console.log(err))
  }

  if("error" in data){
    return(
      <div>
        <p>Daten konnten nicht vom Server übermittelt werden. Lade Seite neu.</p>
      </div>
    )
  } else if ("data" in data){
    let rows = []
    for(let field of params.fields){
      let elems = []
      if(data["data"].length > 0){
        for(let elem of data["data"]){
          if("map" in field){
            elems.push(
              <div className="adm-show-table-elem">
                <p>{field["map"][elem[field["key"]]]}</p>
              </div>
            )
          } else if("list" in field){
            let subelements = []
            for (let subelem of elem[field["key"]]){
              subelements.push(
                <p>{subelem[field["list"]["key"]]}</p>
              );
            }
            elems.push(
              <div className="adm-show-table-elem">
                {subelements}
              </div>
            )
          } else if("img" in field){
            elems.push(
              <div className="adm-show-table-elem">
                <img
                  className="adm-show-table-img"
                  src={elem[field["key"]]}
                  alt="Failed to load"
                />
              </div>
            )
          } 
          
          else {
            elems.push(
              <div className="adm-show-table-elem">
                <p>{elem[field["key"]]}</p>
              </div>
            )
          }
        }
      }
      rows.push(
        <div className="adm-show-table-row">
          <div className="adm-show-table-title">
            <p>{field["title"]}</p>
          </div>
          {elems}
        </div>
      )
    }
    if (params.linede) {
      let lastrow = []
      for(let i = 0; i < data["data"].length; i++){
        let elem = data["data"][i]; 
        lastrow.push(
          <div className="adm-show-table-edit">
            <div className="adm-show-table-edit-elem" onClick={() => changePage("/admin/edit/" + params.type + "/" + elem["id"])}>
              <FontAwesomeIcon icon={faEdit}/>
            </div>
            <div className="adm-show-table-edit-elem" onClick={() => deleteElem(params.del_url + "/" + elem["id"], i)}>
              <FontAwesomeIcon icon={faBan}/>
            </div>
          </div>
        )
      }
      rows.push(
        <div className="adm-show-table-row">
          <div className="adm-show-table-title"><p>Ändern</p></div>
          {lastrow}
        </div>
      )
    }
    return(
      <div className="adm-show-container">
        {rows}
      </div>
    )
  } else {
    return(
      <div>
        <p>Loading</p>
      </div>
    )
  }

}

function APortal(params){
  const show_user_url = "/api/v1/controls/getUser";
  const del_user_url = "/api/v1/controls/delUser";
  const show_user_fields = [
    {"title": "ID", "key": "id"},
    {"title": "E-Mail", "key": "email"},
    {"title": "Kategorie", "key": "category",
      "map":{"0": "Admin", "1": "Eigentümerportal", "2": "Datenraum"}
    },
    {"title": "Vorname", "key": "firstname"},
    {"title": "Nachname", "key": "surname"},
    {"title": "Erstellt", "key": "creation_time"}
  ]
  const show_dir_url = "/api/v1/controls/getDirstruct";
  const del_dir_url = "/api/v1/controls/delUserDir";
  const show_dir_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Ordner", "key": "title"},
    {"title": "Dokumente","key": "documents", 
      "list": {"key": "name"}
    },
    {"title": "User", "key": "users",
      "list": {"key": "email"},
    }
  ];
  const show_doc_url = "/api/v1/controls/getDoc";
  const del_doc_url = "/api/v1/controls/delUserDoc";
  const show_doc_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Name", "key": "name"}
  ];

  return(
    <>
    <div className="adm-titles"><p>PORTAL</p></div>
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Users</p>
      </div>
      <div>
        <BigButton function={() => changePage("/admin/add/user")} text="Neuer User."/>
        <ShowElements url={show_user_url} linede={true} del_url={del_user_url} fields={show_user_fields} type={"user"}/>
      </div>
    </div>
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Dokumente</p>
      </div>
      <div>
        <BigButton function={() => changePage("/admin/add/user-doc")} text="Neues Dokument."/>
        <ShowElements url={show_doc_url} linede={true} del_url={del_doc_url} fields={show_doc_fields} type={"user-doc"}/>
      </div>
    </div>
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Ordner</p>
      </div>
      <div>
        <BigButton function={() => changePage("/admin/add/user-dir")} text="Neuer Ordner."/>
        <ShowElements url={show_dir_url} linede={true} del_url={del_dir_url} fields={show_dir_fields} type={"user-dir"}/>
      </div>
    </div>
    </>
  )
}

async function getShowProjectData(){
  let data = await fetch("/api/v1/controls/getProjects")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"];})
    .catch(err => {console.log(err); return []})
  for(let project of data){
    let marktplatz_img = await getImage("/api/v1/controls/getPImage/" + project["imgmarktplatz"]);
    project["marktplatz"] = [marktplatz_img];
    let big_img = []
    for(let imgid of project["img_big"]){
      let thisImg = await getImage("/api/v1/controls/getPImage/" + imgid);
      big_img.push(thisImg);
    }
    project["gross"] = big_img;
    let small_img = []
    for(let imgid of project["img_small"]){
      let thisImg = await getImage("/api/v1/controls/getPImage/" + imgid);
      small_img.push(thisImg);
    }
    project["klein"] = small_img;
  }
  return data;
}

async function getShowProjectImg(){
  let data = await fetch("/api/v1/controls/getPImage")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"];})
    .catch(err => {console.log(err); return []})
  for(let img of data){
    img["img"] = await getImage("/api/v1/controls/getPImage/" + img["id"]);
  }
  return data;
}

function AProjektseiten(params){
  const [projectData, setProjectData] = React.useState([]);
  const [images, setImages] = React.useState([]);
  useEffect(() => {
    async function fetchData() {
      const result = await getShowProjectData();
      setProjectData(result);
      const imagesResult = await getShowProjectImg();
      setImages(imagesResult);
    }
    fetchData();
  }, []);

  function deleteProject(url, idx){
    fetch(url)
      .then(checkStatus)
      .then(_ => {
        const newData = [...projectData]
        newData.splice(idx, 1);
        setProjectData(newData);
      })
      .catch(err => {console.log(err)});
  }

  function deleteElem(key, id, dataidx, tableidx){
    fetch("/api/v1/controls/delPTable/" + id + "-" + tableidx)
      .then(checkStatus)
      .then(_ => {
        const newData = [...projectData];
        newData[dataidx][key].splice(parseInt(dataidx), 1);
        setProjectData(newData);
      })
      .catch(err => console.log(err));
  }

  function activateProject(id, dataidx, newStatus){
    let body = new FormData();
    body.append("id", id);
    body.append("status", newStatus);
    fetch("/api/v1/controls/activateProject", {method: "POST", body: body})
      .then(checkStatus)
      .then(_ => {
        const newData = [...projectData];
        newData[dataidx]["active"] = newStatus ? 1 : 0;
        setProjectData(newData);
      })
      .catch(err => console.log(err));
  }

  const show_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Aktiv", "key": "active"},
    {"title": "Haupttitel", "key": "title"},
    {"title": "Titel im kleinen Menu", "key": "titlemenu"},
    {"title": "Kurztext/Slogan", "key": "summary"},
    {"title": "URL", "key": "url"},
    {"title": "Unterlink auf Website", "key": "link"},
    {"title": "Verkauf", "key": "verkauf"},
    {"title": "Text", "key": "text"},
    {"title": "Downloads", "key": "downloads",
      "list": {"key": "name"},
    },
    {"title": "Angebot", "key": "offer"},
    {"title": "Claim", "key": "claim"},
    {"title": "Link bei Impressionen", "key": "imglink"},
    {"title": "Text Link bei Impressionen", "key": "imglinktext"},
    {"title": "Link iframe", "key": "iframelink"},
    {"title": "Text unter Tabelle", "key": "tabledescription"},
    {"changeButton": {}},
    {"title": "Tabelle(n)", "key": "projecttable",
      "table": {}
    },
    {"title": "Bild auf Marktplatz-Seite", "key": "marktplatz",
      "img": {}
    },
    {"title": "Bilder Slider oben", "key": "gross",
      "img": {}
    },
    {"title": "Bilder Impressionen", "key": "klein",
      "img": {}
    }
  ]

  let projectEntries = []
  for(let i = 0; i < projectData.length; i++){
    let project = projectData[i]
    let elements = []
    if(project["active"]){
      elements.push(
        <BigButton function={() => activateProject(project["id"], i, false)} text="Deaktivieren."/>
      );
    } else {
      elements.push(
        <BigButton function={() => activateProject(project["id"], i, true)} text="Aktivieren."/>
      );
    }
    for (let fields of show_fields){
      if("table" in fields){
        let tables = [];
        let nextIdx = 0;
        if(project[fields["key"]]){
          for (let thisTable of project[fields["key"]]){
            let currentIdx = nextIdx
            tables.push(
              <>
                <ObjectTable table={thisTable}/>
                <BigButton function={() => changePage("/admin/edit/project-table/" + project["id"] + "-" + currentIdx)} text="Tabelle bearbeiten."/>
                <BigButton function={() => deleteElem(fields["key"], project["id"], i, currentIdx)} text="Tabelle löschen."/>
              </>
            )
            nextIdx += 1;
          }
        }
        elements.push(
          <>
          <div>
            <p>{fields["title"]}</p>
          </div>
          <BigButton function={() => changePage("/admin/edit/project-table/" + project["id"] + "-" + nextIdx)} text="Neue Tabelle."/>
          {tables}
          </>
        )
      } else if ("list" in fields){
        let listElems = [];
        for (let thisElem of project[fields["key"]]){
          listElems.push(
            <div>
              <p>{thisElem[fields["list"]["key"]]}</p>
            </div>
          )
        }
        elements.push(
          <div className="adm-project-elem">
          <div className="adm-project-left-elem">
            <p>{fields["title"]}</p>
          </div>
          <div>
            {listElems}
          </div>
          </div>
        )
      } else if ("img" in fields){
        let imglist = []
        for(let image of project[fields["key"]]){
          imglist.push(
            <img
                className="ccard-info-img"
                src={image}
                alt="Failed to load"
            />
          )
        }
        elements.push(
          <div className="adm-project-elem">
          <div className="adm-project-left-elem">
            <p>{fields["title"]}</p>
          </div>
          <div>
            {imglist}
          </div>
          </div>
        )
      } else if("changeButton" in fields){
        elements.push(<BigButton function={() => changePage("/admin/edit/project/" + project["id"])} text="Hauptfelder Projekt bearbeiten."/>)
      } else {
        elements.push(
          <div className="adm-project-elem">
          <div className="adm-project-left-elem">
            <p>{fields["title"]}</p>
          </div>
          <div>
            <p>{project[fields["key"]]}</p>
          </div>
          </div>
        )
      }
    }
    projectEntries.push(
      <>
      <div className="adm-content-sub">
        <div className="adm-subtitles"> 
          <p>{project["title"]}</p>
        </div>
        <div>
          {elements}
        </div>
        <BigButton function={() => deleteProject("api/v1/controls/delProject/" + project["id"], i)} text="Projekt löschen."/>
      </div>
      </>
    )
  }

  const del_img_url = "/api/v1/controls/delPImage";
  const show_img_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Name", "key": "name"},
    {"title": "Bild", "key": "img", "img": {}}
  ];

  const show_doc_url = "/api/v1/controls/getPDoc";
  const del_doc_url = "/api/v1/controls/delPDoc";
  const show_doc_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Name", "key": "name"}
  ];

  return(
    <>
    <div className="adm-titles"><p>PROJEKTSEITEN</p></div>
      <BigButton function={() => changePage("/admin/add/project")} text="Neues Projekt."/>
      {projectEntries}
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Bilder</p>
      </div>
      <div>
        <BigButton function={() => changePage("/admin/add/project-image")} text="Neue Bilder."/>
        <ShowElements url={""} linede={true} del_url={del_img_url} fields={show_img_fields} type={"project-image"} data_given={{"data": images}}/>
      </div>
    </div>
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Dokumente</p>
      </div>
      <div>
        <BigButton function={() => changePage("/admin/add/project-doc")} text="Neues Dokument."/>
        <ShowElements url={show_doc_url} linede={true} del_url={del_doc_url} fields={show_doc_fields} type={"project-doc"}/>
      </div>
    </div>
    </>
  )
}

function ADownloads(params){
  const show_dl_dir_url = "/api/v1/controls/getDWDirstruct/1";
  const del_dl_dir_url = "/api/v1/controls/delDWDir";
  const show_dl_dir_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Ordner", "key": "title"},
    {"title": "Dokumente","key": "documents", 
      "list": {"key": "name"}
    },
    {"title": "Position", "key": "position"}
  ];
  const show_doc_url = "/api/v1/controls/getDWDoc";
  const del_doc_url = "/api/v1/controls/delDWDoc";
  const show_doc_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Name", "key": "name"}
  ];
  const show_wi_dir_url = "/api/v1/controls/getDWDirstruct/2";
  const del_wi_dir_url = "/api/v1/controls/delDWDir";
  const show_wi_dir_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Ordner", "key": "title"},
    {"title": "Dokumente","key": "documents", 
      "list": {"key": "name"}
    },
    {"title": "Position", "key": "position"}
  ];
  return(
    <>
    <div className="adm-titles"><p>DOWNLOADS/WISSEN</p></div>
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Dokumente</p>
      </div>
      <div>
        <BigButton function={() => changePage("/admin/add/dw-doc")} text="Neues Dokument."/>
        <ShowElements url={show_doc_url} linede={true} del_url={del_doc_url} fields={show_doc_fields} type={"dw-doc"}/>
      </div>
    </div>
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Ordner Downloads</p>
      </div>
      <div>
        <BigButton function={() => changePage("/admin/add/dw-dir")} text="Neuer Ordner."/>
        <ShowElements url={show_dl_dir_url} linede={true} del_url={del_dl_dir_url} fields={show_dl_dir_fields} type={"dw-dir"}/>
      </div>
    </div>
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Ordner Wissen</p>
      </div>
      <div>
        <BigButton function={() => changePage("/admin/add/dw-dir")} text="Neuer Ordner."/>
        <ShowElements url={show_wi_dir_url} linede={true} del_url={del_wi_dir_url} fields={show_wi_dir_fields} type={"dw-dir"}/>
      </div>
    </div>
    </>
  )
}

function EditHonorarTable(params) {
  const [data, setData] = React.useState({});
  const [errorText, setErrorText] = React.useState("");
  useEffect(() => {
    async function fetchData() {
      const result = await fetchShowHonorarData(params.id);
      setData(result);
    }
    fetchData();
  }, [params.id])

  const classes = loginFormStyle()
  let initMin = {
    "min": 0,
  } 
  if("calculations" in data){
    if (params.id === "2"){
      initMin = {"min": data["calculations"]["grund_honorar"]}
    } else {
      initMin = {"min": data["calculations"]["min"]}
    }
  }

  return(
    <>
    <div>
      <p>Eintrag Honorarrechner bearbeiten.</p>
    </div>
    <ShowHonorarTable id={params.id} data={data["calculations"]}/>
    <div>
      <p>!!! Wichtig: Einträge müssen mit aufsteigendem Wert sortiert sein !!!</p>
    </div>
    {params.id !== "2" &&
    <>
      <div><p>Eintrag hinzufügen</p></div>
      <Formik 
        enableReinitialize
        initialValues={{max: "", newval: "", index: ""}}
        onSubmit={(values, {setSubmitting}) => {
          let newData = {...data};
          if(values["index"] === ""){
            newData["calculations"]["honorar"].push({"max": parseInt(values.max), "honorar": values.newval});
            newData["calculations"]["max"] = parseInt(values.max)
          } else {
            newData["calculations"]["honorar"].splice(parseInt(values["index"]) - 1, 0, {"max": parseInt(values.max), "honorar": values.newval})
          }
          setData(newData);
          setSubmitting(false);
        }}
      >
        <Form className={classes.root}>
          <Field component={TextField} variant="outlined" name="max"
              label="Bis" fullWidth={true} helperText={"Von ist automatisch der vorherige Eintrag + 1."}/>
          <Field component={TextField} variant="outlined" name="newval"
              label="Angezeigtes Resultat" fullWidth={true}/>
          <Field component={TextField} variant="outlined" name="index"
              label="Einfügen in Index" fullWidth={true} helperText={"Wenn leer, wird der Eintrag am Ende eingefügt"}/>
          <div className="button-right">
            <button type="submit">Eintrag hinzufügen.</button>
          </div>
        </Form>
      </Formik>
      <div><p>Eintrag löschen</p></div>
      <Formik 
        enableReinitialize
        initialValues={{"idx": ""}}
        onSubmit={(values, {setSubmitting}) => {
          let newData = {...data};
          newData["calculations"]["honorar"].splice((parseInt(values.idx) - 1), 1);
          if(parseInt(values.idx) === newData["calculations"]["honorar"].length){
            newData["calculations"]["max"] = newData["calculations"]["honorar"][parseInt(values.idx) - 2]["max"]
          }
          setData(newData);
          setSubmitting(false);
        }}
      >
        <Form className={classes.root}>

          <Field component={TextField} variant="outlined" name="idx"
              label="Index des Eintrags" fullWidth={true} helperText={"Der oberste Eintrag in der Liste hat Index 1."}/>
          <div className="button-right">
            <button type="submit">Eintrag löschen.</button>
          </div>
        </Form>
      </Formik>
      <div><p>Minimum bearbeiten</p></div>
      <Formik 
        enableReinitialize
        initialValues={initMin}
        onSubmit={(values, {setSubmitting}) => {
          let newData = {...data};
          newData["calculations"]["min"] = parseInt(values.min)
          setData(newData);
          setSubmitting(false);
        }}
      >
        <Form className={classes.root}>

          <Field component={TextField} variant="outlined" name="min"
              label="Neues globales Minimum" fullWidth={true}/>
          <div className="button-right">
            <button type="submit">Minimum bearbeiten.</button>
          </div>
        </Form>
      </Formik>
    </>
    }
    {params.id === "2" &&
    <>
      <div><p>Eintrag Wohnungen hinzufügen</p></div>
      <Formik 
        enableReinitialize
        initialValues={{max: "", newval: "", index: ""}}
        onSubmit={(values, {setSubmitting}) => {
          let newData = {...data};
          if(values["index"] === ""){
            newData["calculations"]["tarife_wohnung"].push({"max": parseInt(values.max), "honorar": parseInt(values.newval)});
          } else {
            newData["calculations"]["tarife_wohnung"].splice(parseInt(values["index"]) - 1, 0, {"max": parseInt(values.max), "honorar": parseInt(values.newval)})
          }
          setData(newData);
          setSubmitting(false);
        }}
      >
        <Form className={classes.root}>
          <Field component={TextField} variant="outlined" name="max"
              label="Bis" fullWidth={true} helperText={"Von ist automatisch der vorherige Eintrag + 1."}/>
          <Field component={TextField} variant="outlined" name="newval"
              label="Tarif pro Einheit" fullWidth={true}/>
          <Field component={TextField} variant="outlined" name="index"
              label="Einfügen in Index" fullWidth={true} helperText={"Wenn leer, wird der Eintrag am Ende eingefügt"}/>
          <div className="button-right">
            <button type="submit">Eintrag hinzufügen.</button>
          </div>
        </Form>
      </Formik>
      <div><p>Eintrag Wohnungen löschen</p></div>
      <Formik 
        enableReinitialize
        initialValues={{"idx": ""}}
        onSubmit={(values, {setSubmitting}) => {
          let newData = {...data};
          newData["calculations"]["tarife_wohnung"].splice((parseInt(values.idx) - 1), 1);
          setData(newData);
          setSubmitting(false);
        }}
      >
        <Form className={classes.root}>

          <Field component={TextField} variant="outlined" name="idx"
              label="Index des Eintrags" fullWidth={true} helperText={"Der oberste Eintrag in der Liste hat Index 1."}/>
          <div className="button-right">
            <button type="submit">Eintrag löschen.</button>
          </div>
        </Form>
      </Formik>
      <div><p>Eintrag Nebenobjekt hinzufügen</p></div>
      <Formik 
        enableReinitialize
        initialValues={{max: "", newval: "", index: ""}}
        onSubmit={(values, {setSubmitting}) => {
          let newData = {...data};
          if(values["index"] === ""){
            newData["calculations"]["tarife_nebenobjekt"].push({"max": parseInt(values.max), "honorar": parseInt(values.newval)});
          } else {
            newData["calculations"]["tarife_nebenobjekt"].splice(parseInt(values["index"]) - 1, 0, {"max": parseInt(values.max), "honorar": parseInt(values.newval)})
          }
          setData(newData);
          setSubmitting(false);
        }}
      >
        <Form className={classes.root}>
          <Field component={TextField} variant="outlined" name="max"
              label="Bis" fullWidth={true} helperText={"Von ist automatisch der vorherige Eintrag + 1."}/>
          <Field component={TextField} variant="outlined" name="newval"
              label="Tarif pro Einheit" fullWidth={true}/>
          <Field component={TextField} variant="outlined" name="index"
              label="Einfügen in Index" fullWidth={true} helperText={"Wenn leer, wird der Eintrag am Ende eingefügt"}/>
          <div className="button-right">
            <button type="submit">Eintrag hinzufügen.</button>
          </div>
        </Form>
      </Formik>
      <div><p>Eintrag Nebenobjekt löschen</p></div>
      <Formik 
        enableReinitialize
        initialValues={{"idx": ""}}
        onSubmit={(values, {setSubmitting}) => {
          let newData = {...data};
          newData["calculations"]["tarife_nebenobjekt"].splice((parseInt(values.idx) - 1), 1);
          setData(newData);
          setSubmitting(false);
        }}
      >
        <Form className={classes.root}>

          <Field component={TextField} variant="outlined" name="idx"
              label="Index des Eintrags" fullWidth={true} helperText={"Der oberste Eintrag in der Liste hat Index 1."}/>
          <div className="button-right">
            <button type="submit">Eintrag löschen.</button>
          </div>
        </Form>
      </Formik>
      <div><p>Grundhonorar bearbeiten</p></div>
      <Formik 
        enableReinitialize
        initialValues={initMin}
        onSubmit={(values, {setSubmitting}) => {
          let newData = {...data};
          newData["calculations"]["grund_honorar"] = parseInt(values.min)
          setData(newData);
          setSubmitting(false);
        }}
      >
        <Form className={classes.root}>

          <Field component={TextField} variant="outlined" name="min"
              label="Neues Grundhonorar" fullWidth={true}/>
          <div className="button-right">
            <button type="submit">Grundhonorar bearbeiten.</button>
          </div>
        </Form>
      </Formik>
    </>
    }
    <Formik
      enableReinitialize
      initialValues={{
        "textbefore": data["textbefore"],
        "id": data["id"],
        "textafter": data["textafter"],
        "calculations": data["calculations"]
      }}
      onSubmit ={(values, {setSubmitting}) => {
        let params = new FormData();
        params.append("textbefore", values.textbefore);
        params.append("textafter", values.textafter);
        params.append("id", values.id);
        params.append("calc", JSON.stringify(values.calculations));
        fetch("/api/v1/controls/updateHonorar", {method: "POST", body: params})
          .then(response => {
            if(response.status === 200 || response.status === 400){
              return response;
            } else {
              throw Error(response.statusText);
            }
          })
          .then(resp => resp.json())
          .then(data => {
            if("error" in data){
              setErrorText(data["error"])
            } else {
              setErrorText("Erfolgreich.");
            }
            setSubmitting(false);
          })
          .catch(error => {console.log(error); setErrorText(error.toString()); setSubmitting(false)})
      }}
    >
      <Form className={classes.root}>
        <Field component={TextField} variant="outlined" name="textbefore"
          label="Text vor Resultat" fullWidth={true}
        />
        <Field component={TextField} variant="outlined" name="textafter"
          label="Text nach Resultat" fullWidth={true}
        />
        <div><p>{errorText}</p></div>
        <div className="button-right">
          <button type="submit">Alle Änderungen speichern.</button>
        </div>
      </Form>
    </Formik>
    </>
  )
}

function ShowHonorarTable(params) {
  if (!(params.data)){
    return(<div></div>)
  }
  if (params.id === 2 || params.id === "2"){
    let identifiers = [
      {"field": "tarife_wohnung", "name": "Wohnungen"},
      {"field": "tarife_nebenobjekt", "name": "Nebenobjekte"}
    ]
    let thisTarifs = []
    for(let identifier of identifiers){
      let von = [
        (
          <div><p>Von</p></div>
        )
      ]
      let bis = [
        (
          <div><p>Bis</p></div>
        )
      ]
      let tarif = [
        (
          <div><p>Tarif pro Einheit</p></div>
        )
      ]
      let prevVal = -1
      for (let elem of params.data[identifier["field"]]){
        von.push(
          <div><p>{prevVal + 1}</p></div>
        )
        bis.push(
          <div><p>{elem["max"]}</p></div>
        )
        prevVal = elem["max"]
        tarif.push(
          <div><p>{elem["honorar"]}</p></div>
        )
      }
      thisTarifs.push(
        <>
        <div>
          <p>{identifier["name"]}</p>
        </div>
        <div className="adm-hon-table">
          <div className="adm-hon-column">
            {von}
          </div>
          <div className="adm-hon-column">
            {bis}
          </div>
          <div className="adm-hon-column">
            {tarif}
          </div>
        </div>
        </>
      )
    }
    return(
      <>
      <div>
        <p> Grundhonorar: {params.data["grund_honorar"]}</p>
      </div>
      {thisTarifs}
      </>
    )
  } else {
    let von = [
      (
        <div><p>Von</p></div>
      )
    ]
    let bis = [
      (
        <div><p>Bis</p></div>
      )
    ]
    let tarif = [
      (
        <div><p>Angezeigtes Resultat</p></div>
      )
    ]
    let prevVal = params.data["min"] - 1
    for (let elem of params.data["honorar"]){
      von.push(
        <div><p>{prevVal + 1}</p></div>
      )
      bis.push(
        <div><p>{elem["max"]}</p></div>
      )
      prevVal = elem["max"]
      tarif.push(
        <div><p>{elem["honorar"]}</p></div>
      )
    }
    return(
      <>
      <div className="adm-hon-table">
        <div className="adm-hon-column">
          {von}
        </div>
        <div className="adm-hon-column">
          {bis}
        </div>
        <div className="adm-hon-column">
          {tarif}
        </div>
      </div>
      </>
    )
  }
}

async function fetchShowHonorarData(index){
  if(index === -1){
    let data = await fetch("/api/v1/controls/getHonorar")
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {return data["data"]})
      .catch(err => {console.log(err); return []})
    return data;
  } else {
    let data = await fetch("/api/v1/controls/getHonorar/" + index)
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"][0]})
    .catch(err => {console.log(err); return {}})
    return data;
  }
}

function AHonorarrechner(params){
  const [honorarData, setHonorarData] = React.useState([]);
  useEffect(() => {
    async function fetchData() {
      const result = await fetchShowHonorarData(-1);
      setHonorarData(result);
    }
    fetchData();
  }, []);

  let honorarEntries = []
  for(let i = 0; i < honorarData.length; i++) {
    let thisHonorar = honorarData[i];
    let elements = [];
    elements.push(
      <div className="adm-project-elem">
        <div className="adm-project-left-elem">
          <p>ID</p>
        </div>
        <div>
          <p>{thisHonorar["id"]}</p>
        </div>
      </div>
    );
    elements.push(
      <div className="adm-project-elem">
        <div className="adm-project-left-elem">
          <p>Text vor Resultat</p>
        </div>
        <div>
          <p>{thisHonorar["textbefore"]}</p>
        </div>
      </div>
    );
    elements.push(
      <div className="adm-project-elem">
        <div className="adm-project-left-elem">
          <p>Text nach Resultat</p>
        </div>
        <div>
          <p>{thisHonorar["textafter"]}</p>
        </div>
      </div>
    );
    elements.push(
      <div className="adm-project-elem">
        <div className="adm-project-left-elem">
          <p>Berechnung</p>
        </div>
        <div>
          <ShowHonorarTable data={thisHonorar["calculations"]} id={thisHonorar["id"]}/>
        </div>
      </div>
    )


    honorarEntries.push(
      <>
      <div className="adm-content-sub">
        <div className="adm-subtitles"> 
          <p>{thisHonorar["name"]}</p>
        </div>
        <div>
          {elements}
        </div>
      </div>
      <BigButton function={() => changePage("/admin/edit/honorar/" + thisHonorar["id"])} text="Bearbeiten."/>
      </>
    )
  }

  return(
    <>
    <div className="adm-titles"><p>HONORARRECHNER</p></div>
    {honorarEntries}
    </>
  )
}

async function getReferenceData(){
  let data = await fetch("/api/v1/controls/getReference")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"];})
    .catch(err => {console.log(err); return []})
  for (let reference of data){
    reference["img"] = await getImage("/api/v1/controls/getUImage/" + reference["imgid"]);
  }
  return data;
}

function AReferenzen(params){
  const [data, setData] = React.useState([]);
  useEffect(() => {
    async function fetchData() {
      const result = await getReferenceData();
      setData(result);
    }
    fetchData();
  }, []);
  const del_ref_url = "/api/v1/controls/delReference";
  const show_ref_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Titel oben", "key": "maintitle"},
    {"title": "Titel unten", "key": "subtitle"},
    {"title": "Typ (oranges Feld)", "key": "type"},
    {"title": "Text", "key": "text"},
    {"title": "Hovertext", "key": "hovertext"},
    {"title": "Bild", "key": "img", "img": {}}
  ];
  return(
    <>
    <div className="adm-titles"><p>REFERENZEN</p></div>
    <div className="adm-content-sub">
      <div>
        <BigButton function={() => changePage("/admin/add/reference")} text="Neue Referenz."/>
        <ShowElements url={""} linede={true} del_url={del_ref_url} fields={show_ref_fields} type={"reference"} data_given={{"data": data}}/>
      </div>
    </div>
    </>
  )
}

async function getHistoryData(){
  let data = await fetch("/api/v1/controls/getHistory")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"];})
    .catch(err => {console.log(err); return []})
  for (let reference of data){
    reference["img"] = await getImage("/api/v1/controls/getUImage/" + reference["imgid"]);
  }
  return data;
}

function AGeschichte(params){
  const [data, setData] = React.useState([]);
  useEffect(() => {
    async function fetchData() {
      const result = await getHistoryData();
      setData(result);
    }
    fetchData();
  }, []);
  const del_his_url = "/api/v1/controls/delHistory";
  const show_his_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Titel", "key": "title"},
    {"title": "Text", "key": "text"},
    {"title": "Bild", "key": "img", "img": {}}
  ];
  return(
    <>
    <div className="adm-titles"><p>GESCHICHTE</p></div>
    <div className="adm-content-sub">
      <div>
        <BigButton function={() => changePage("/admin/add/history")} text="Neue Geschichte."/>
        <ShowElements url={""} linede={true} del_url={del_his_url} fields={show_his_fields} type={"history"} data_given={{"data": data}}/>
      </div>
    </div>
    </>
  )
}

async function getPeopleData(){
  let data = await fetch("/api/v1/controls/getPeople")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"];})
    .catch(err => {console.log(err); return []})
  for (let reference of data){
    reference["img"] = await getImage("/api/v1/controls/getUImage/" + reference["imgid"]);
  }
  return data;
}

function APeople(params){
  const [data, setData] = React.useState([]);
  useEffect(() => {
    async function fetchData() {
      const result = await getPeopleData();
      setData(result);
    }
    fetchData();
  }, []);
  const del_people_url = "/api/v1/controls/delPeople";
  const show_people_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Name", "key": "name"},
    {"title": "E-Mail", "key": "mail"},
    {"title": "Tel.-Nr.", "key": "tel"},
    {"title": "Position", "key": "position"},
    {"title": "Unternehmung", "key": "company"},
    {"title": "Website", "key": "website"},
    {"title": "Hauptkontakt", "key": "main"},
    {"title": "Mitarbeiter", "key": "us"},
    {"title": "Treuhandkontakt", "key": "treuhand"},
    {"title": "Partner", "key": "partner"},    
    {"title": "Bild", "key": "img", "img": {}}
  ];
  return(
    <>
    <div className="adm-titles"><p>PERSONEN</p></div>
    <div className="adm-content-sub">
      <div>
        <BigButton function={() => changePage("/admin/add/people")} text="Neue Person."/>
        <ShowElements url={""} linede={true} del_url={del_people_url} fields={show_people_fields} type={"people"} data_given={{"data": data}}/>
      </div>
    </div>
    </>
  )
}

async function getSlideData(){
  let data = await fetch("/api/v1/controls/getSlide")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"];})
    .catch(err => {console.log(err); return []})
  for (let reference of data){
    reference["img"] = await getImage("/api/v1/controls/getUImage/" + reference["imgid"]);
  }
  return data;
}

async function getSettingsData(){
  let data = await fetch("/api/v1/controls/getSettings")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"];})
    .catch(err => {console.log(err); return []})  
  data[1]["doc"] = await fetch("/api/v1/controls/getDWDoc/" + data[1]["content"]["docid"])
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {return data["data"][0];})
    .catch(err => {console.log(err); return {}})
  return data;
}

function AHome(params){
  const [data, setData] = React.useState([]);
  const [settings, setSettings] = React.useState([]);
  useEffect(() => {
    async function fetchData() {
      const result = await getSlideData();
      setData(result);
      const newResult = await getSettingsData();
      setSettings(newResult);
    }
    fetchData();
  }, []);

  function activateSubPage(key, value){
    let newSettings = [...settings]
    newSettings[0]["content"][key] = value;
    let params = new FormData();
    params.append("name", newSettings[0]["name"])
    params.append("content", JSON.stringify(newSettings[0]["content"]))
    params.append("id", "1")
    fetch("/api/v1/controls/updateSettings", {method: "POST", body: params})
      .then(checkStatus)
      .then(resp => resp.json())
      .then(_ => {
        setSettings(newSettings);
      })
      .catch(err => {console.log(err);})
  }

  let name = {"partner": "Partner", "history": "Geschichte", "reference": "Referenzen", "wissen": "Wissen"}

  let pagesettings = []
  let document = ""
  if(settings.length > 0){
    for (const [key, value] of Object.entries(settings[0]["content"])){
      let actualVal = (value === 1)? "aktiv":"inaktiv"
      pagesettings.push(
        <div className="adm-project-elem">
          <div className="adm-project-left-elem">
            <p>{name[key]}</p>
          </div>
          <div>
            <p>{actualVal}</p>
          </div>
          {(value === 1) &&
            <BigButton function={() => activateSubPage(key, 0)} text="Deaktivieren"/>
          }
          {!value &&
            <BigButton function={() => activateSubPage(key, 1)} text="Aktivieren"/>
          }
        </div>
      )
    }
    document = settings[1]["doc"]["name"] + "(ID " + settings[1]["doc"]["id"] + ")"
  }

  const del_slides_url = "/api/v1/controls/delSlide";
  const show_slides_fields = [
    {"title": "ID", "key": "id"},
    {"title": "Link", "key": "link"},
    {"title": "Text", "key": "text"},
    {"title": "Bild", "key": "img", "img": {}}
  ];
  return(
    <>
    <div className="adm-titles"><p>HOME/EINSTELLUNGEN</p></div>
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Slides</p>
      </div>
      <div>
        <BigButton function={() => changePage("/admin/add/slides")} text="Neue Slide."/>
        <ShowElements url={""} linede={true} del_url={del_slides_url} fields={show_slides_fields} type={"slides"} data_given={{"data": data}}/>
      </div>
    </div>
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Aktive Seiten</p>
      </div>
      {pagesettings}
    </div>
    <div className="adm-content-sub">
      <div className="adm-subtitles"> 
        <p>Dokumente</p>
      </div>
      <div>
        <p>Link auf Seite Über Uns zu Unternehmensbroschüre: <br></br>{document}</p>
        <p>Wenn Du ein neues Dokument hinzufügen willst, kannst du das bei Wissen/Downloads machen</p>
        <BigButton function={() => changePage("/admin/edit/uu-doc/2")} text="Dokument ändern."/>
      </div>
    </div>
    </>
  )
}

async function getDocumentsForTable(){
  let data = await fetch("/api/v1/controls/getPDoc")
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {
        let docs = [];
        for (let doc of data["data"]){
          let this_text = doc["name"] + " (ID " + doc["id"] + ")"
          docs.push({
            "value": doc["id"].toString(), "label": this_text
          })
        }
        return docs;
      })
      .catch(err => {console.log(err); return []})
    return data;
}

async function getCurrentDataForTable(id, idx){
  let data = await fetch("/api/v1/controls/getProjects/" + id)
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {
      let tables = data["data"][0]["projecttable"];
      if(tables && tables.length > idx){
        return tables[idx];
      } else {
        return {};
      }
    })
    .catch(err => {console.log(err); return {}})
  return data;
}

function NewProjectTable(params){
  const [nrColumns, setNrColumns] = React.useState(0);
  const [titleRow, setTitleRow] = React.useState([]);
  const [titleInitialVal, setTitleInitialVal] = React.useState({})
  const [preview, setPreview] = React.useState({
    "description": "",
    "maxSize": "",
    "title": [],
    "elems": []
  })
  const [newEntry, setNewEntry] = React.useState([]);
  const [rowInitialVal, setRowInitialVal] = React.useState({});
  const [documents, setDocuments] = React.useState([])
  const [errorText, setErrorText] = React.useState("");
  const [edit, setEdit] = React.useState(false);

  useEffect(() => {
    async function fetchData() {
      const result = await getDocumentsForTable();
      setDocuments(result);
      const preData = await getCurrentDataForTable(params.id, params.idx);
      if("description" in preData) {
        setPreview(preData);
        setEdit(true);
        let theEntries = []
        let theInvals = {};
        for (let i = 0; i < preData["title"].length; i++){
          let title = preData["title"][i]["text"];
          let type = preData["title"][i]["type"];
          if(type === "text"){
            theEntries.push(
              <Field component={TextField} variant="outlined" name={title + "-" + type}
                label={"Text:" + title} fullWidth={true} helperText="Wenn der Eintrag mit m^2 endet, wird dieses schön dargestellt."
              />
            )
          } else if (type === "mail") {
            theEntries.push(
              <Field component={TextField} variant="outlined" name={title + "-" + type}
                label={"Mail-Adresse:" + title} fullWidth={true} 
              />
            )
          } else if (type === "document"){  
            let options = []
            for(let document of result){
              options.push(
                <MenuItem value={document.value}>{document.label}</MenuItem>
              )
            }
            theEntries.push(
              <Field component={TextField} variant="outlined" select={true}
                label={"Dokument:" + title} name={title + "-" + type}
              >
                <MenuItem value="">Dokument auswählen</MenuItem>
                {options}
              </Field>
            )
          }
          theInvals[title + "-" + type] = "";
        }
        theInvals["elem_title"] = "";
        theInvals["index"] = "";
        setNewEntry(theEntries);
        setRowInitialVal(theInvals);
        titleInitialVal["main_title"] = preData["description"]
        titleInitialVal["min_pixel"] = preData["maxSize"]
      }
    }
    fetchData();
  }, [params, titleInitialVal]);

  function changeColumns(nr){
    setNrColumns(nrColumns);
    const newRows = [...titleRow];
    if(nr < titleRow.length) {
      let betterInvals = {};
      let theseRows = []
      for(let i = 0; i < nr; i++){
        theseRows.push(titleRow[i]);
        betterInvals["title-" + i.toString()] = titleInitialVal["title-" + i.toString()]
        betterInvals["type-" + i.toString()] = titleInitialVal["type-" + i.toString()]
        betterInvals["big-" + i.toString()] = titleInitialVal["big-" + i.toString()]
        betterInvals["small-" + i.toString()] = titleInitialVal["small-" + i.toString()]
      }
      setTitleRow(theseRows);
      setTitleInitialVal(betterInvals);
    } else {
      for(let i = newRows.length; i < nr; i++){
        newRows.push(
          <>
          <div><p>Feld {i + 1}</p></div>
          <Field component={TextField} variant="outlined" name={"title-" + i.toString()}
            label={"Titel"} fullWidth={true}
          />
          <Field component={TextField} variant="outlined" select={true}
            label={"Typ"} name={"type-" + i.toString()}
          >
            <MenuItem value="">Typ auswählen</MenuItem>
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="document">Dokument</MenuItem>
            <MenuItem value="mail">Mailadresse</MenuItem>
          </Field>
          <Field component={TextField} variant="outlined" select={true}
            label="Sichtbar wenn gross" name={"big-" + i.toString()}
          >
            <MenuItem value="">Auswählen</MenuItem>
            <MenuItem value="1">Ja</MenuItem>
            <MenuItem value="2">Nein</MenuItem>
          </Field>
          <Field component={TextField} variant="outlined" select={true}
            label="Sichtbar wenn klein" name={"small-" + i.toString()}
          >
            <MenuItem value="">Auswählen</MenuItem>
            <MenuItem value="1">Ja</MenuItem>
            <MenuItem value="2">Nein</MenuItem>
          </Field>
          </>
        )
        titleInitialVal["title-" + i.toString()] = ""; 
        titleInitialVal["type-" + i.toString()] = ""; 
        titleInitialVal["big-" + i.toString()] = ""; 
        titleInitialVal["small-" + i.toString()] = ""; 
      }
      setTitleRow(newRows);
    }
  }

  function saveList(){
    let thisParams = new FormData();
    thisParams.append("table", JSON.stringify(preview));
    thisParams.append("id", params.id);
    thisParams.append("idx", params.idx);
    fetch("/api/v1/controls/setPTable", {method: "POST", body: thisParams})
      .then(response => {
        if(response.status === 200 || response.status === 400){
          return response;
        } else {
          throw Error(response.statusText);
        }
      })
      .then(resp => resp.json())
      .then(data => {
        if("error" in data){
          setErrorText(data["error"])
        } else {
          setErrorText("Erfolgreich.");
        }
      })
      .catch(error => {console.log(error); setErrorText(error.toString())})
  }

  const classes = loginFormStyle();
  return (
    <>
    <div>
      {edit &&
        <div><p>Tabelle bearbeiten</p></div>
      }
      {!edit &&
      <>
        <div><p>Neue Tabelle erstellen</p></div>
        <div>
          <Formik 
            enableReinitialize
            initialValues={{"columns": ""}}
            onSubmit={(values, {setSubmitting}) => {
              changeColumns(parseInt(values.columns));
              setSubmitting(false);
            }}
          >
            <Form className={classes.root}>
              <Field component={TextField} variant="outlined" name="columns"
                label="Anzahl Spalten" fullWidth={true}
              />
              <div className="button-right">
                <button type="submit">Anzahl Spalten bestätigen.</button>
              </div>
            </Form>
          </Formik>
        </div>
      </>
      }
      <div>
        <Formik 
          enableReinitialize
          initialValues={titleInitialVal}
          onSubmit={(values, {setSubmitting}) => {
            if(edit){
              let newPreview = {...preview};
              newPreview["description"] = values.main_title;
              newPreview["maxSize"] = values.min_pixel;
              setPreview(newPreview);
              setSubmitting(false);
              return
            }
            let newPreview = {...preview};
            newPreview["description"] = values.main_title;
            newPreview["maxSize"] = values.min_pixel;
            let theTitles = []
            let theEntries = []
            let theInvals = {};
            for (let i = 0; i < titleRow.length; i++){
              let title = values["title-" + i.toString()];
              let type = values["type-" + i.toString()];
              theTitles.push({
                "text": title,
                "type": type,
                "show_big": (values["big-" + i.toString()] === "1"),
                "show_small": (values["small-" + i.toString()] === "1")
              });
              if(type === "text"){
                theEntries.push(
                  <Field component={TextField} variant="outlined" name={title + "-" + type}
                    label={"Text:" + title} fullWidth={true} helperText="Wenn der Eintrag mit m^2 endet, wird dieses schön dargestellt."
                  />
                )
              } else if (type === "mail") {
                theEntries.push(
                  <Field component={TextField} variant="outlined" name={title + "-" + type}
                    label={"Mail-Adresse:" + title} fullWidth={true} 
                  />
                )
              } else if (type === "document"){  
                let options = []
                for(let document of documents){
                  options.push(
                    <MenuItem value={document.value}>{document.label}</MenuItem>
                  )
                }
                theEntries.push(
                  <Field component={TextField} variant="outlined" select={true}
                    label={"Dokument:" + title} name={title + "-" + type}
                  >
                    <MenuItem value="">Dokument auswählen</MenuItem>
                    {options}
                  </Field>
                )
              }
              theInvals[title + "-" + type] = "";
            }
            theInvals["elem_title"] = "";
            theInvals["index"] = "";
            newPreview["title"] = theTitles;
            setPreview(newPreview);
            setNewEntry(theEntries);
            setRowInitialVal(theInvals);
            setEdit(true);
            setSubmitting(false);
           }}
        >
          <Form className={classes.root}>
            {(titleRow.length > 0 || edit) &&
            <>
              <Field component={TextField} variant="outlined" name="main_title"
                label={"Titel Tabelle"} fullWidth={true}/>
              <Field component={TextField} variant="outlined" name="min_pixel"
                label={"Minimale Breite in Pixel"} fullWidth={true} helperText="Wenn das Fenster weniger Pixel breit ist, wird auf die kleine Umsicht umgeschaltet."/>
            </>
            }
            {titleRow.length > 0 && !edit &&
            <>
              {titleRow}
            </>
            }
            {(titleRow.length > 0 || edit) &&
              <div className="button-right">
                <button type="submit">Einstellungen bestätigen.</button>
              </div>
            }
          </Form>
        </Formik>
      </div>
    </div>
    {preview["title"].length > 0 &&
    <>
      <div className="adm-titles">
      <p>PREVIEW</p>
      </div>
      <div className="SidePaddingBig">
        <ObjectTable table={preview}/>
      </div>
    </>
    }
    <div>
    {preview["title"].length > 0 &&
      <>
        <div><p>Eintrag hinzufügen</p></div>
        <Formik 
          enableReinitialize
          initialValues={rowInitialVal}
          onSubmit={(values, {setSubmitting}) => {
            let newPreview = {...preview};
            let thisEntry = []
            for(const [key, value] of Object.entries(values)){
              if(key.endsWith("text")){
                thisEntry.push({"text": value})
              } else if(key.endsWith("mail")){
                thisEntry.push({"mail": value})
              } else if(key.endsWith("document")){
                thisEntry.push({"document": value})
              }
            }
            if(values["index"] === ""){
              newPreview["elems"].push({"title": values["elem_title"], "fields": thisEntry});
            } else {
              newPreview["elems"].splice(parseInt(values["index"]) - 1, 0, {"title": values["elem_title"], "fields": thisEntry})
            }
            setPreview(newPreview);
            setSubmitting(false);
          }}
        >
          <Form className={classes.root}>
            <Field component={TextField} variant="outlined" name="elem_title"
                label="Titel Element" fullWidth={true} helperText={"Dieser Titel wird bei der kleinen Ansicht angezeigt"}/>
            {newEntry}
            <Field component={TextField} variant="outlined" name="index"
                label="Einfügen in Index" fullWidth={true} helperText={"Wenn leer, wird der Eintrag am Ende eingefügt"}/>
            <div className="button-right">
              <button type="submit">Eintrag hinzufügen.</button>
            </div>
          </Form>
        </Formik>
        <div><p>Eintrag löschen</p></div>
        <Formik 
          enableReinitialize
          initialValues={{"idx": ""}}
          onSubmit={(values, {setSubmitting}) => {
            let newPreview = {...preview};
            newPreview["elems"].splice((parseInt(values.idx) - 1), 1);
            setPreview(newPreview);
            setSubmitting(false);
          }}
        >
          <Form className={classes.root}>

            <Field component={TextField} variant="outlined" name="idx"
                label="Index des Eintrags" fullWidth={true} helperText={"Der oberste Eintrag in der Liste hat Index 1."}/>
            <div className="button-right">
              <button type="submit">Eintrag löschen.</button>
            </div>
          </Form>
        </Formik>
        <BigButton function={() => saveList()} text="Liste speichern."/>
        <div><p>{errorText}</p></div>
      </>
      }
    </div>
  </>
  )
}

async function getCurrentData(type, method, id){
  if (method === "add") {
    return []
  } else if (method === "edit"){
    if (type === "user"){
      let data = await fetch("/api/v1/controls/getUser/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisData = data["data"][0];
          return {
            "id": id,
            "email": thisData["email"],
            "password": "",
            "category": thisData["category"],
            "firstname": thisData["firstname"],
            "surname": thisData["surname"]
          }
        })
        .catch(err => {console.log(err); return {}})
      return data;
    } else if (type === "user-doc") {
      let data = await fetch("/api/v1/controls/getDoc/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisData = data["data"][0]
          return {
            "id": id,
            "name": thisData["name"],
            "directoryid": thisData["directoryid"]
          }
        })
        .catch(err => {console.log(err); return {}})
      return data;
    } else if (type === "user-dir") {
      let data = await fetch("/api/v1/controls/getDir/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisData = data["data"][0]
          return {
            "id": id,
            "name": thisData["title"]
          }
        })
        .catch(err => {console.log(err); return {}})
      data["users"] = await fetch("/api/v1/controls/getUser")
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisUsers = [];
          for(let usr of data["data"]){
            let thisDirs = JSON.parse(usr["directory"])
            if(thisDirs.includes(parseInt(id))){
              thisUsers.push(usr["id"].toString())
            }
          }
          return thisUsers;
        })
        .catch(err => {console.log(err); return []})
      data["documents"] = await fetch("/api/v1/controls/getDoc")
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisDocs = [];
          for(let docs of data["data"]){
            if(docs["directoryid"]!== null && id === docs["directoryid"].toString()){
              thisDocs.push(docs["id"].toString())
            }
          }
          return thisDocs;
        })
        .catch(err => {console.log(err); return []})
      return data;
    } else if (type === "dw-dir") {
      let data = await fetch("/api/v1/controls/getDWDir/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisData = data["data"][0]
          return {
            "id": id,
            "name": thisData["title"],
            "position": thisData["position"],
            "category": thisData["category"].toString()
          }
        })
        .catch(err => {console.log(err); return {}})
      data["documents"] = await fetch("/api/v1/controls/getDWDoc")
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisDocs = [];
          for(let docs of data["data"]){
            if(docs["directoryid"] !== null && id === docs["directoryid"].toString()){
              thisDocs.push(docs["id"].toString())
            }
          }
          return thisDocs;
        })
        .catch(err => {console.log(err); return []})
      return data;
    } else if (type === "dw-doc") {
      let data = await fetch("/api/v1/controls/getDWDoc/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisData = data["data"][0]
          return {
            "id": id,
            "name": thisData["name"],
            "directoryid": thisData["directoryid"]
          }
        })
        .catch(err => {console.log(err); return {}})
      return data;
    } else if (type === "project") {
      let data = await fetch("/api/v1/controls/getProjects/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisData = data["data"][0]
          thisData["project_url"] = thisData["url"];
          thisData["verkauf"] = (thisData["verkauf"] === 1)? "true": "false";
          let docIds = []
          for(let elem of thisData["downloads"]) {
            docIds.push(elem["id"].toString());
          }
          thisData["documents"] = docIds;
          return thisData;
        })
        .catch(err => {console.log(err); return [];})
      return data;
    } else if (type === "project-image") {
      let data = await fetch("/api/v1/controls/getPImageInfo/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisData = data["data"][0]
          return {
            "id": id,
            "name": thisData["name"],
            "projectid": thisData["projectid"]
          }
        })
        .catch(err => {console.log(err); return [];})
      return data;
    } else if (type === "project-doc") {
      let data = await fetch("/api/v1/controls/getPDocInfo/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          let thisData = data["data"][0]
          return {
            "id": id,
            "name": thisData["name"],
            "projectid": thisData["projectid"].map(id => id.toString())
          }
        })
        .catch(err => {console.log(err); return [];})
      return data;
    } else if (type === "reference") {
      let data = await fetch("/api/v1/controls/getReference/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          return data["data"][0];
        })
        .catch(err => {console.log(err); return {}})
      return data;
    } else if (type === "history") {
      let data = await fetch("/api/v1/controls/getHistory/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          return data["data"][0];
        })
        .catch(err => {console.log(err); return {}})
      return data;
    } else if (type === "people") {
      let data = await fetch("/api/v1/controls/getPeople/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          return data["data"][0];
        })
        .catch(err => {console.log(err); return {}})
      return data;
    } else if (type === "uu-doc") {
      let data = await fetch("/api/v1/controls/getSettings/2")
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          return {"docid": [data["data"][0]["content"]["docid"].toString()]};
        })
        .catch(err => {console.log(err); return {}})
      return data;
    } else if (type === "slides") {
      let data = await fetch("/api/v1/controls/getSlide/" + id)
        .then(checkStatus)
        .then(resp => resp.json())
        .then(data => {
          return data["data"][0];
        })
        .catch(err => {console.log(err); return {}})
      return data;
    }
  }
}

async function getAdditionalData(type){
  if(type === "user-doc"){
    let data = await fetch("/api/v1/controls/getDir")
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {
        let dirs = [];
        for(let dir of data["data"]){
          let this_text = dir["title"] + " (ID " + dir["id"] + ")"
          dirs.push({
              "value": dir["id"], "text": this_text
          })
        };
        return dirs;
      })
      .catch(err => {console.log(err); return []})
    return [data];
  } else if(type === "user-dir"){
    let data1 = await fetch("/api/v1/controls/getDoc")
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {
        let docs = [];
        for(let doc of data["data"]){
          let this_text = doc["name"] + " (ID " + doc["id"] + ")"
          console.log(doc["id"])
          docs.push({
            "value": doc["id"].toString(), "label": this_text
          })
        };
        return docs;
      })
      .catch(err => {console.log(err); return []})
    let data2 = await fetch("/api/v1/controls/getUser")
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {
        let users = [];
        for(let usr of data["data"]){
          let this_text = usr["email"] + " (ID " + usr["id"] + ")"
          users.push({
            "value": usr["id"].toString(), "label": this_text
          })
        };
        return users;
      })
      .catch(err => {console.log(err); return []})
    return [data1, data2];
  } else if(type === "dw-dir"){
    let data = await fetch("/api/v1/controls/getDWDoc")
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {
        let docs = [];
        for (let doc of data["data"]){
          let this_text = doc["name"] + " (ID " + doc["id"] + ")"
          docs.push({
            "value": doc["id"].toString(), "label": this_text
          })
        }
        return [docs];
      })
      .catch(err => {console.log(err); return []})
    return data;
  } else if(type === "dw-doc") {
    let data = await fetch("/api/v1/controls/getDWDir")
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {
        let dirs = [];
        for(let dir of data["data"]){
          let this_text = dir["title"] + " (ID " + dir["id"] + ")"
          if (dir["category"] === 1){
            this_text += " (Downloads)"
          } else if (dir["category"] === 2){
            this_text += " (Wissen)"
          }
          dirs.push({
              "value": dir["id"], "text": this_text
          })
        };
        return dirs;
      })
      .catch(err => {console.log(err); return []})
    return [data];
  } else if(type === "project"){
    let data = await fetch("/api/v1/controls/getPDoc")
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {
        let docs = [];
        for (let doc of data["data"]){
          let this_text = doc["name"] + " (ID " + doc["id"] + ")"
          docs.push({
            "value": doc["id"].toString(), "label": this_text
          })
        }
        return [docs];
      })
      .catch(err => {console.log(err); return []})
    return data;
  } else if(type === "project-doc"){
    let data = await fetch("/api/v1/controls/getProjectsSimple")
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {
        let docs = [];
        for (let doc of data["data"]){
          let this_text = doc["title"] + " (ID " + doc["id"] + ")"
          docs.push({
            "value": doc["id"].toString(), "label": this_text
          })
        }
        return [docs];
      })
      .catch(err => {console.log(err); return []})
    return data;
  } else if(type === "project-image"){
    let data = await fetch("/api/v1/controls/getProjectsSimple")
      .then(checkStatus)
      .then(resp => resp.json())
      .then(data => {
        let docs = [];
        for (let doc of data["data"]){
          let this_text = doc["title"] + " (ID " + doc["id"] + ")"
          docs.push({
            "value": doc["id"].toString()+"-1", "label": this_text + " Bilder Slider oben"
          });
          docs.push({
            "value": doc["id"].toString()+"-2", "label": this_text + " Bilder Impressionen"
          });
          docs.push({
            "value": doc["id"].toString()+"-3", "label": this_text + " Bild Marktplatz"
          })
        }
        return [docs];
      })
      .catch(err => {console.log(err); return []})
    return data;
  } else if(type === "uu-doc"){
    let data = await fetch("/api/v1/controls/getDWDoc")
    .then(checkStatus)
    .then(resp => resp.json())
    .then(data => {
      let docs = [];
      for (let doc of data["data"]){
        let this_text = doc["name"] + " (ID " + doc["id"] + ")"
        docs.push({
          "value": doc["id"].toString(), "text": this_text
        })
      }
      return [docs];
    })
    .catch(err => {console.log(err); return []})
  return data;
  } else {
    return [[]]
  }
}

function ASingleElem(params){
  const [additionalData, setAdditionalData] = React.useState([[], []]);
  const [currentData, setCurrentData] = React.useState({});
  useEffect(() => {
    async function fetchData() {
      const result = await getAdditionalData(params.type);
      setAdditionalData(result);
      if (params.method === "edit") {
        const currentresult = await getCurrentData(params.type, params.method, params.id);
        setCurrentData(currentresult);
      }
    }
    fetchData();
  }, [params]);

  const metaData = {
    "user": {
      "add_title": "Neuer User",
      "add_url": "/api/v1/controls/newUser",
      "edit_title": "User ändern (PW wird nur bearbeitet wenn neues gesetzt wird)",
      "edit_url": "/api/v1/controls/updateUser",
      "fields": [
        {"type": "textfield", "field_name": "email", "label": "E-Mail"},
        {"type": "textfield", "field_name": "password", "label": "Passwort"},
        {"type": "drop-down", "field_name": "category", "label": "Kategorie",
          "options": [{"value": "0", "text": "Admin"},
            {"value": "1", "text": "Eigentümerportal"},
            {"value": "2", "text": "Datenraum"}
          ],
        },
        {"type": "textfield", "field_name": "firstname", "label": "Vorname"},
        {"type": "textfield", "field_name": "surname", "label": "Nachname"}
      ],
      "specials": {},
    },
    "user-doc": {
      "add_title": "Neues Dokument (Eigentümerportal/Datenraum)",
      "add_url": "/api/v1/controls/newUserDoc",
      "edit_title": "Dokument ändern",
      "edit_url": "/api/v1/controls/updateUserDoc",
      "fields": [
        {"type": "textfield", "field_name": "name", "label": "Name"},
        {"type": "drop-down", "field_name": "directoryid", "label": "Ordner",
          "options": additionalData[0]
        },
        {"type": "file", "field_name": "file_description", "label": "Dokument"}
      ],
      "specials": {
        "file": {}
      }
    },
    "user-dir": {
      "add_title": "Neuer Ordner (Eigentümerportal/Datenraum)",
      "add_url": "/api/v1/controls/newUserDir",
      "edit_title": "Ordner ändern",
      "edit_url": "/api/v1/controls/updateUserDir",
      "fields": [
        {"type": "textfield", "field_name": "name", "label": "Name"},
        {"type": "select", "field_name": "documents", "label": "Dokumente (Können immer nur zu einem Ordner gehören)",
          "options": additionalData[0]
        },
        {"type": "select", "field_name": "users", "label": "User",
          "options": additionalData[1]
        }
      ],
      "specials": {},
    },
    "dw-dir": {
      "add_title": "Neuer Ordner (Downloads/Wissen)",
      "add_url": "/api/v1/controls/newDWDir",
      "edit_title": "Ordner ändern",
      "edit_url": "/api/v1/controls/updateDWDir",
      "fields": [
        {"type": "textfield", "field_name": "name", "label": "Name"},
        {"type": "select", "field_name": "documents", "label": "Dokumente (Können immer nur zu einem Ordner gehören)",
          "options": additionalData[0]
        },
        {"type": "drop-down", "field_name": "category", "label": "Kategorie",
          "options": [{"value": "1", "text": "Downloads"},
            {"value": "2", "text": "Wissen"}
          ]
        },
        {"type": "textfield", "field_name": "position", "label": "Position"}
      ],
      "specials": {}
    },
    "dw-doc": {
      "add_title": "Neues Dokument (Downloads/Wissen)",
      "add_url": "/api/v1/controls/newDWDoc",
      "edit_title": "Dokument ändern",
      "edit_url": "/api/v1/controls/updateDWDoc",
      "fields": [
        {"type": "textfield", "field_name": "name", "label": "Name"},
        {"type": "drop-down", "field_name": "directoryid", "label": "Ordner",
          "options": additionalData[0]
        },
        {"type": "file", "field_name": "file_description", "label": "Dokument"}
      ],
      "specials": {
        "file": {}
      }
    },
    "project": {
      "add_title": "Neue Projektseite erstellen",
      "add_url": "/api/v1/controls/newProject",
      "edit_title": "Projektseite ändern",
      "edit_url": "/api/v1/controls/updateProject",
      "fields": [
        //{"title": "ID", "key": "id"},
        {"type": "textfield", "field_name": "title", "label": "Haupttitel"},
        {"type": "textfield", "field_name": "titlemenu", "label": "Titel im kleinen Menu"},
        {"type": "textfield", "field_name": "summary", "label": "Kurztext/Slogan"},
        {"type": "textfield", "field_name": "project_url", "label": "URL (Format: xxx.ch)"},
        {"type": "textfield", "field_name": "link", "label": "Unterlink auf Website (Format: /xxx)"},
        {"type": "drop-down", "field_name": "verkauf", "label": "Verkauf",
          "options": [{"value": "true", "text": "Ja"},
            {"value": "false", "text": "Nein"}
          ]
        },
        {"type": "textfield", "field_name": "offer", "label": "Angebot"},
        {"type": "textfield", "field_name": "claim", "label": "Claim"},
        {"type": "textfield", "field_name": "imglink", "label": "Link bei Impressionen"},
        {"type": "textfield", "field_name": "imglinktext", "label": "Text Link bei Impressionen"},
        {"type": "textfield", "field_name": "iframelink", "label": "Link für iframe Impressionen"},
        {"type": "textfield_large", "field_name": "tabledescription", "label": "Text unter Tabelle (Für neuen Absatz: <br>)"},
        {"type": "textfield_large", "field_name": "text", "label": "Text (Für neuen Absatz: <br>)"},
        {"type": "select", "field_name": "documents", "label": "Dokumente (Können zu mehreren Projekten gehören)",
          "options": additionalData[0]
        }
      ],
      "specials": {
        "project": {}
      }
    },
    "project-image": {
      "add_title": "Neues Bild (Projekte)",
      "add_url": "/api/v1/controls/newPImage",
      "edit_title": "Bild ändern",
      "edit_url": "/api/v1/controls/updatePImage",
      "fields": [
        {"type": "textfield", "field_name": "name", "label": "Name"},
        {"type": "select", "field_name": "projectid", "label": "Projekte",
          "options": additionalData[0]
        },
        {"type": "file", "field_name": "file_description", "label": "Bild"}
      ],
      "specials": {
        "file": {}
      }
    },
    "project-doc": {
      "add_title": "Neues Dokument (Projekte)",
      "add_url": "/api/v1/controls/newPDoc",
      "edit_title": "Dokument ändern",
      "edit_url": "/api/v1/controls/updatePDoc",
      "fields": [
        {"type": "textfield", "field_name": "name", "label": "Name"},
        {"type": "select", "field_name": "projectid", "label": "Projekte (Abschnitt Download)",
          "options": additionalData[0]
        },
        {"type": "file", "field_name": "file_description", "label": "Dokument"}
      ],
      "specials": {
        "file": {}
      }
    },
    "reference": {
      "add_title": "Neue Referenz",
      "add_url": "/api/v1/controls/newReference",
      "edit_title": "Referenz ändern",
      "edit_url": "/api/v1/controls/updateReference",
      "fields": [
        {"type": "textfield", "field_name": "maintitle", "label": "Titel oben"},
        {"type": "textfield", "field_name": "subtitle", "label": "Titel unten"},
        {"type": "textfield", "field_name": "type", "label": "Typ (oranges Feld)"},
        {"type": "textfield", "field_name": "hovertext", "label": "Hovertext"},
        {"type": "textfield_large", "field_name": "text", "label": "Text"},
        {"type": "file", "field_name": "file_description", "label": "Bild"}
      ],
      "specials": {
        "file": {}
      }
    }, 
    "history": {
      "add_title": "Neue Geschichte",
      "add_url": "/api/v1/controls/newHistory",
      "edit_title": "Geschichte ändern",
      "edit_url": "/api/v1/controls/updateHistory",
      "fields" : [
        {"type": "textfield", "field_name": "title", "label": "Titel"},
        {"type": "textfield_large", "field_name" : "text", "label": "Text"},
        {"type": "file", "field_name": "file_description", "label": "Bild"}
      ],
      "specials": {
        "file": {}
      }
    },
    "people": {
      "add_title": "Neue Person",
      "add_url": "/api/v1/controls/newPeople",
      "edit_title": "Person ändern",
      "edit_url": "/api/v1/controls/updatePeople",
      "fields" : [
        {"type": "textfield", "field_name": "name", "label": "Name"},
        {"type": "textfield", "field_name" : "mail", "label": "E-Mail-Adresse"},
        {"type": "textfield", "field_name" : "tel", "label": "Telefon-Nr."},
        {"type": "textfield", "field_name" : "position", "label": "Position/Aufgabe/Tätigkeit"},
        {"type": "textfield", "field_name" : "company", "label": "Unternehmung"},
        {"type": "textfield", "field_name" : "website", "label": "Website (Format: https://xxx.xx)"},
        {"type": "drop-down", "field_name": "main", "label": "Hauptkontakt",
          "options": [{"value": "1", "text": "Ja"},
            {"value": "2", "text": "Nein"}
          ]
        },
        {"type": "drop-down", "field_name": "us", "label": "Mitarbeiter",
          "options": [{"value": "1", "text": "Ja"},
            {"value": "2", "text": "Nein"}
          ]
        },
        {"type": "drop-down", "field_name": "treuhand", "label": "Kontakt Treuhand",
          "options": [{"value": "1", "text": "Ja"},
            {"value": "2", "text": "Nein"}
          ]
        },
        {"type": "drop-down", "field_name": "partner", "label": "Partner",
          "options": [{"value": "1", "text": "Ja"},
            {"value": "2", "text": "Nein"}
          ]
        },
        {"type": "file", "field_name": "file_description", "label": "Bild"}
      ],
      "specials": {
        "file": {}
      }
    },
    "slides": {
      "add_title": "Neue Slide",
      "add_url": "/api/v1/controls/newSlide",
      "edit_title": "Slide ändern",
      "edit_url": "/api/v1/controls/updateSlide",
      "fields" : [
        {"type": "textfield", "field_name": "link", "label": "Link (Format: /...)"},
        {"type": "textfield", "field_name" : "text", "label": "Text"},
        {"type": "file", "field_name": "file_description", "label": "Bild"}
      ],
      "specials": {
        "file": {}
      }
    },
    "uu-doc": {
      "edit_title": "Dokument ändern",
      "edit_url": "/api/v1/controls/updateSettings",
      "fields" : [
        {"type": "drop-down", "field_name": "docid", "label": "Dokument",
          "options": additionalData[0]
        },
      ],
      "specials": {}
    }
  }
  if(params.type in metaData) {
    if(params.method === "add"){
      return(
        <AddNewElem url={metaData[params.type]["add_url"]} fields={metaData[params.type]["fields"]} 
          specials={metaData[params.type]["specials"]} title={metaData[params.type]["add_title"]}/>
      )
    } else if (params.method === "edit"){
      return(
        <AddNewElem url={metaData[params.type]["edit_url"]} fields={metaData[params.type]["fields"]} 
          specials={metaData[params.type]["specials"]} title={metaData[params.type]["edit_title"]}
          currentData={currentData} id={params.id}/>
      )
    }
  } else if(params.type === "project-table") {
    let values = params.id.split("-");
    return(
      <div className="adm-change">
        <NewProjectTable id={values[0]} idx={values[1]}/>
      </div>
    )
  } else if(params.type === "honorar") {
    return(
      <div className="adm-change">
        <EditHonorarTable id={params.id}/>
      </div>
    )
  } else {
    return(
      <div><p>Invalid url: {params.type}</p></div>
    )
  }
}

function Admin(params) {
  const [loggedIn, setLoggedIn] = React.useState([false, true]);
  const [selected, setSelected] = React.useState(0)
  useEffect(() => {
    async function fetchData() {
      const result = await IsUserLoggedIn(0);
      setLoggedIn([true, result]);
    }
    fetchData();
  }, []);

  function changeTopic(topic){
    setSelected(topic);
  }

  const {method, type, id} = useParams();

  if(loggedIn[0] && !loggedIn[1]){
    return(
      <>
       <AdminLogin/>
      </>
    )
  } else if (method !== undefined){
    return(
      <div>
        <div className="adm-top">
          <div className="adm-top-inner">
            <div className="nav-user-box">
              <div className="Logo-Header Navlogo" onClick={() => changePage("/")}>
                <img
                    src={navlogo}
                    width="190"
                    height="40"
                    className="d-inline-block align-top"
                    alt="DEFLORIN AG"
                />
              </div>
              <div className="nav-user-right-box">
                <div className="nav-user-elem">
                  <div onClick={() => changePage("/admin")}>
                    <FontAwesomeText text="Zurück zur Admin-Übersicht" icon={faUndo} left={true} menu={true}/>
                  </div>
                </div>
              </div>
              <div className="nav-user-right-box">
                <div className="nav-user-elem">
                  <div onClick={() => Logout()}>
                    <FontAwesomeText text="Logout" icon={faSignOutAlt} left={true} menu={true}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="adm-main">
          {method === "edit" && type !== undefined && id !== undefined &&
            <ASingleElem method="edit" type={type} id={id}/>
          }
          {method === "add" && type !== undefined &&
            <ASingleElem method="add" type={type}/>
          }
        </div>
      </div>
    )
  } else {
    return(
      <div>
        <div className="adm-top">
          <div className="adm-top-inner">
            <div className="nav-user-box">
              <div className="Logo-Header Navlogo" onClick={() => changePage("/")}>
                <img
                    src={navlogo}
                    width="190"
                    height="40"
                    className="d-inline-block align-top"
                    alt="DEFLORIN AG"
                />
              </div>
              <div className="nav-user-right-box">
                <div className="nav-user-elem">
                  <div onClick={() => Logout()}>
                    <FontAwesomeText text="Logout" icon={faSignOutAlt} left={true} menu={true}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="adm-main">
          <div className="adm-menu">
            {selected !== 1 &&
              <div className="adm-menu-item" onClick={() => changeTopic(1)}>
                <p>Portal</p>
              </div>
            }
            {selected === 1 &&
              <div className="adm-menu-item-selected" onClick={() => changeTopic(1)}>
                <p>Portal</p>
              </div>
            }
            {selected !== 2 &&
              <div className="adm-menu-item" onClick={() => changeTopic(2)}>
                <p>Projektseiten</p>
              </div>
            }
            {selected === 2 &&
              <div className="adm-menu-item-selected" onClick={() => changeTopic(2)}>
                <p>Projektseiten</p>
              </div>
            }
            {selected !== 3 &&
              <div className="adm-menu-item" onClick={() => changeTopic(3)}>
                <p>Downloads/Wissen</p>
              </div>
            }
            {selected === 3 &&
              <div className="adm-menu-item-selected" onClick={() => changeTopic(3)}>
                <p>Downloads/Wissen</p>
              </div>
            }
            {selected !== 4 &&
              <div className="adm-menu-item" onClick={() => changeTopic(4)}>
                <p>Honorarrechner</p>
              </div>
            }
            {selected === 4 &&
              <div className="adm-menu-item-selected" onClick={() => changeTopic(4)}>
                <p>Honorarrechner</p>
              </div>
            }
            {selected !== 5 &&
              <div className="adm-menu-item" onClick={() => changeTopic(5)}>
                <p>Referenzen</p>
              </div>
            }
            {selected === 5 &&
              <div className="adm-menu-item-selected" onClick={() => changeTopic(5)}>
                <p>Referenzen</p>
              </div>
            }
            {selected !== 6 &&
              <div className="adm-menu-item" onClick={() => changeTopic(6)}>
                <p>Geschichte</p>
              </div>
            }
            {selected === 6 &&
              <div className="adm-menu-item-selected" onClick={() => changeTopic(6)}>
                <p>Geschichte</p>
              </div>
            }
            {selected !== 7 &&
              <div className="adm-menu-item" onClick={() => changeTopic(7)}>
                <p>Personen</p>
              </div>
            }
            {selected === 7 &&
              <div className="adm-menu-item-selected" onClick={() => changeTopic(7)}>
                <p>Personen</p>
              </div>
            }
            {selected !== 8 &&
              <div className="adm-menu-item" onClick={() => changeTopic(8)}>
                <p>Home/Einstellungen</p>
              </div>
            }
            {selected === 8 &&
              <div className="adm-menu-item-selected" onClick={() => changeTopic(8)}>
                <p>Home/Einstellungen</p>
              </div>
            }
          </div>
          <div className="adm-content">
            {selected === 0 &&
              <p>Wähle links den Bereich, welchen Du bearbeiten möchtest.</p>
            }
            {selected === 1 &&
              <APortal/>
            }
            {selected === 2 &&
              <AProjektseiten/>
            }
            {selected === 3 &&
              <ADownloads/>
            }
            {selected === 4 &&
              <AHonorarrechner/>
            }
            {selected === 5 &&
              <AReferenzen/>
            }
            {selected === 6 &&
              <AGeschichte/>
            }
            {selected === 7 &&
              <APeople/>
            }
            {selected === 8 &&
              <AHome/>
            }
          </div>
        </div>
      </div>
    )
  }
}

/* --------------------------------- ADMIN --------------------------------- */